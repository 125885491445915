import useSWR from "swr";
import { AbstractProvider, ethers } from "ethers"
import { useWriteContract } from "wagmi"
import { useTranslation } from "react-i18next"
import { liquidityRewardsContract, getLiquidityRewardsContract, rebateHandlerContract } from "../utils/contracts"
import { useActiveProvider } from "."
import { RewardsType } from "../utils/types"
import { getAddress } from "viem";


const liquidityRewardsFetcher =
  (provider: AbstractProvider | null) =>
  async ([_, chainId, account]: [
    _: string,
    chainId: number,
    account: string
  ]): Promise<RewardsType | undefined> => {
    if (!provider || !account) {
      return
    }

    const rewardsContract = getLiquidityRewardsContract(chainId, provider);

    let amountStaked = 0n;
    let earned = 0n;
    let rewardRate = 0n;
    let totalSupply = 0n;

    if (account !== ethers.ZeroAddress && account !== "") {
      [amountStaked, earned, rewardRate, totalSupply] = await Promise.all([
        rewardsContract.balanceOf(account),
        rewardsContract.earned(account),
        rewardsContract.rewardRate(),
        rewardsContract.totalSupply(),
      ])
    } else {
      [rewardRate, totalSupply] = await Promise.all([
        rewardsContract.rewardRate(),
        rewardsContract.totalSupply(),
      ])
    }

    return { amountStaked, earned, rewardRate, totalSupply }
  }

export const useLiquidityRewards = () => {
  const { chainId, userAccount, multiCallProvider } = useActiveProvider();

  return useSWR<RewardsType | undefined>(
    !userAccount || !multiCallProvider ? null : ["userRewardLiqFetcher", chainId, userAccount],
    liquidityRewardsFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

export const arbPriceFetcher = () =>
  async ([_, apiTokenId]: [
    _: string,
    apiTokenId: string,
  ]): Promise<number> => {

    let ctxPrice = 1.4;
    const url = new URL("https://api.coingecko.com/api/v3/simple/price");
    url.searchParams.append("ids", apiTokenId);
    url.searchParams.append("vs_currencies", "USD");

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      ctxPrice = result[apiTokenId]["usd"];
    } catch (error) {
      console.error("Error:", error);
    }

    return ctxPrice;
}

export const useTokenPrice = (apiTokenId: string) => {
  return useSWR<number>(
    ["useTokenPrice", apiTokenId],
    arbPriceFetcher(),
    { revalidateOnFocus: true },
  )
}

// Transactions
export const useRewardsTransactions = (
  chainId: number,
  onSettled: (isSuccess: boolean, successMsg: string, error: any) => void,
) => {
  const { t } = useTranslation()
  const { writeContract } = useWriteContract();

  return ({
    onStakeShares: (amount: bigint) => {
      writeContract(
        {
          address: getAddress(liquidityRewardsContract[chainId].address),
          abi: liquidityRewardsContract.abi,
          functionName: "stake",
          args: [amount],
        },
        {
          onSuccess(data){
            onSettled(true, t("notification.shares-staked"), data)
          },
          onError(error){
            onSettled(false, "", error)
          },
        }
      )
    },
    onWithdrawShares: (amount: bigint) => { 
      writeContract(
        {
          address: getAddress(liquidityRewardsContract[chainId].address),
          abi: liquidityRewardsContract.abi,
          functionName: "withdraw",
          args: [amount],
        },
        {
          onSuccess(data){
            onSettled(true, t("notification.shares-withdrawn"), data)
          },
          onError(error){
            onSettled(false, "", error)
          },
        }
      )
    },
    onClaimRewards: () => {
       writeContract(
        {
          address: getAddress(liquidityRewardsContract[chainId].address),
          abi: liquidityRewardsContract.abi,
          functionName: "getReward",
          args: [],
        },
        {
          onSuccess(data){
            onSettled(true, t("notification.reward-claimed"), data)
          },
          onError(error){
            onSettled(false, "", error)
          },
        }
      )
    }
  })
}


// Claim rebate rewards
export const useRebateClaimRewards = (
  chainId: number,
  proof: Array<string>,
  amount: bigint,
  writeEnable: boolean,
  onSettled: (isSuccess: boolean, successMsg: string, error: any) => void,
) => {
  const { t } = useTranslation()
  const { writeContract } = useWriteContract();

  return {
    onRebateClaim: (proof: Array<string>, amount: bigint) => {
      writeContract(
        {
          address: getAddress(rebateHandlerContract[chainId].address),
          abi: rebateHandlerContract.abi,
          functionName: "claimReward",
          args: [proof, amount],
        },
        {
          onSuccess(data){
            onSettled(true, t("notification.amount-claimed"), data)
          },
          onError(error){
            onSettled(false, "", error)
          },
        }
      )
    }
  }
};
