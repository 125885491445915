import React, { useCallback, useMemo, useState } from "react"
import { Button, InputGroup, Form, Modal, Spinner } from "react-bootstrap/esm"
import { ethers } from "ethers"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"
import "../../../styles/modals.scss"
import {
  useActiveProvider,
  useOpenPosition,
  useProductSnapshot,
  useTokensBalance,
  useUserProductSnapshot,
  useProtocolSnapshot,
  useCollateral,
  useUSDCOraclePrice,
} from "../../hooks"
import { NumericFormat } from "react-number-format"
import { FaArrowRight } from "react-icons/fa"
import { MarketInfo } from "../markets"
import { LeverageInput } from "../../../components/Trade/TradeForm/components/LeverageInput"
import { ApproveCollateralButton } from "../ActionsButtons"
import { Fees } from "../Fees"
import { saveTransactionToDB } from "../../db/transactions"
import { InvokerAction } from "../../helpers"
import {
  calculateLeverage,
  getLiquidationPrice,
  getCollateralTokenByProduct,
  getMarketByProduct,
  hasMaxDecimalsAllowed,
  isLongPosition,
  isNonNegativeNumber,
  nextPosition,
  BigMath,
} from "../../utils/utils"
import { ApproveType, ERROR_USER_REJECTED_TRAN, TOKENS_SYMBOLS } from "../../utils/constants"
import { OpenPositionType } from "../../utils/types"
import { CustomTooltip, LeverageSlider, TokenIcon, errorNotification, notifyUser } from "../../../components/common"


type props = {
  showModal: boolean;
  ownerAddress: string;
  productAddress: string
  onHide: () => void;
}

export const UpdatePosition = ({
  showModal,
  ownerAddress,
  productAddress,
  onHide,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { chainId } = useActiveProvider();
  const { data: protocol } = useProtocolSnapshot();
  const { product, mutateProductsSnapshots } = useProductSnapshot(productAddress);
  const { userProduct, mutateUserProductSnapshot } = useUserProductSnapshot(ownerAddress, productAddress);
  const { data: collateralSnapshot, mutate: mutateCollateral } = useCollateral(productAddress);
  const { data: priceUSDC } = useUSDCOraclePrice();
  const isLong = isLongPosition(chainId, productAddress);
  const currentProduct = getMarketByProduct(chainId, productAddress);
  const collateralToken = getCollateralTokenByProduct(chainId, productAddress);
  const [collateralError, setCollateralError] = useState("");
  const { data: tokensBalance } = useTokensBalance();
  const [collateralDiff, setCollateralDiff] = useState(0);

  const minCollateral = useMemo(() => {
    let minCollateral = 10;
    if (protocol) {
      minCollateral = parseFloat(ethers.formatEther(protocol.minCollateral));
    }

    return minCollateral;
  }, [protocol])

  const { totalTaker, totalMaker } = useMemo(() => {
    let totalTaker = "";
    let totalMaker = "";
    if (product) {
      const globalNextPosition = nextPosition(product.pre, product.position)
      totalTaker = globalNextPosition.taker.toString()
      totalMaker = globalNextPosition.maker.toString()
    }
    
    return {
      totalTaker,
      totalMaker
    }
  }, [product])

  const {
    isTaker,
    collateralInitialValue,
    collateralInitialValueUSD,
    positionInitialValue,
    positionInitialValueRaw,
    leverageInitialValue,
    initialLiqPrice,
  } = useMemo(() => {
    let isTaker = true;
    let collateralInitialValue = "0";
    let collateralInitialValueUSD = "0";
    let positionInitialValue = "0";
    let positionInitialValueRaw = 0n;
    let leverageInitialValue = 1;
    let initialLiqPrice = "0";

    if (userProduct) {
      isTaker = userProduct.positionDirection === OpenPositionType.Taker;
      collateralInitialValue = parseFloat(ethers.formatEther(userProduct.collateral)).toFixed(6);
      positionInitialValue = parseFloat(ethers.formatEther(userProduct.positionSize)).toFixed(4);
      positionInitialValueRaw = userProduct.positionSize;

      if (priceUSDC) {
        const p = parseFloat(ethers.formatUnits(priceUSDC, 8));
        collateralInitialValueUSD = (p * parseFloat(ethers.formatEther(userProduct.collateral))).toFixed(4);
      }

      if (parseFloat(collateralInitialValue) + collateralDiff < minCollateral) {
        setCollateralError(t("error.min-collateral", { value: "10 USDC.e" }))
      }
    }

    if (userProduct && product) {
      const indexPrice = product ? product.latestVersion.price : 0n;
      const parsedCollateralAmount = userProduct.collateral;
      if (parsedCollateralAmount !== 0n) {
        const newLeverage = calculateLeverage(indexPrice, userProduct.positionSize, parsedCollateralAmount);
        const formattedLeverage = parseFloat(newLeverage).toFixed(1);
        leverageInitialValue = parseFloat(formattedLeverage);
      }

      initialLiqPrice = getLiquidationPrice(product, collateralInitialValue, positionInitialValue, isLong, currentProduct.displayDecimals);
    }

    return {
      isTaker,
      collateralInitialValue,
      collateralInitialValueUSD,
      positionInitialValue,
      positionInitialValueRaw,
      leverageInitialValue,
      initialLiqPrice
    }
  },
    // eslint-disable-next-line
    [product, userProduct, isLong, minCollateral]
  );

  const [collateralAmount, setCollateralAmount] = useState(collateralInitialValue);
  const [collateralAmountUsd, setCollateralAmountUsd] = useState(collateralInitialValueUSD);
  const [positionAmount, setPositionAmount] = useState(positionInitialValue);
  const [positionAmountRaw, setPositionAmountRaw] = useState(positionInitialValueRaw);
  const [currentLeverage, setCurrentLeverage] = useState(leverageInitialValue);
  const [newLeverage, setNewLeverage] = useState(leverageInitialValue);
  const [inputLeverage, setInputLeverage] = useState(leverageInitialValue.toString());
  const [newLiqPrice, setNewLiqPrice] = useState(initialLiqPrice);
  const [isWriting, setIsWriting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [positionError, setPositionError] = useState("");

  const positionDelta = useMemo(() => {
    const delta = positionAmountRaw - positionInitialValueRaw;
    return delta;
  },
    [positionInitialValueRaw, positionAmountRaw]
  );

  const onTransactionSettled = (isSuccess: boolean, error: any, tranHash: string) => {
    setIsWriting(false);
    
    if (isSuccess) {
      const market = getMarketByProduct(chainId, productAddress);
      let action = isTaker ? InvokerAction.OPEN_TAKE : InvokerAction.OPEN_MAKE;
      if (collateralDiff < 0) {
        action = isTaker ? InvokerAction.CLOSE_TAKE : InvokerAction.CLOSE_MAKE;
      }
      const tokenPrice = product ? product.latestVersion.price : 0n;
      const positionDeltaNumber = parseFloat(ethers.formatEther(positionDelta));
      const exposure = (positionDeltaNumber * parseFloat(ethers.formatEther(tokenPrice))).toFixed(4);
      saveTransactionToDB(
        chainId,
        tranHash,
        ownerAddress,
        market.key,
        productAddress,
        Math.abs(collateralDiff),
        parseFloat(ethers.formatEther(positionDelta)),
        Math.abs(parseFloat(exposure)),
        action,
      );
      
      notifyUser(t("notification.position-opened-2"))
      mutateUserProductSnapshot()
      mutateProductsSnapshots()
      onHide();      
    } else {
      if (error.name !== ERROR_USER_REJECTED_TRAN) {
        errorNotification(t("error.transaction"))
      }  
    }
  };

  const { onOpenPosition } =  useOpenPosition(chainId, ownerAddress ? ownerAddress.toString() : "", isTaker, onTransactionSettled)

  const calcLeverage = useCallback(
    (newPositionAmount: string, newCollateralAmount: string) => {
      if (!isNonNegativeNumber(newPositionAmount) || !isNonNegativeNumber(newCollateralAmount)) {
        setInputLeverage("0");        
        return
      }

      const indexPrice = product ? product.latestVersion.price : 0n;
      const parsedCollateralAmount = ethers.parseEther(newCollateralAmount);
      if (parsedCollateralAmount === 0n) {
        setInputLeverage("1");
        return
      }
      const parsedPositionAmount = ethers.parseEther(newPositionAmount);
      const newLeverage = calculateLeverage(indexPrice, parsedPositionAmount, parsedCollateralAmount);
      const formattedLeverage = parseFloat(newLeverage).toFixed(1);
      setInputLeverage(formattedLeverage);
      setNewLeverage(parseFloat(formattedLeverage));

      const liqPrice = getLiquidationPrice(product, newCollateralAmount, newPositionAmount, isLong);
      setNewLiqPrice(liqPrice);
    },
    // eslint-disable-next-line
    [product]
  )

  const calculatePosition = useCallback(
    (newCollateralAmount: string, newLeverage: string) => {
      if (!isNonNegativeNumber(newCollateralAmount) || !isNonNegativeNumber(newLeverage)) return;
      
      const indexPrice = product ? product.latestVersion.price : 0n;

      if (indexPrice === 0n) return;

      const parsedCollateralAmount = ethers.parseEther(newCollateralAmount);
      const parsedLeverage = ethers.parseEther(newLeverage);
      const newPosition = BigMath.abs(parsedLeverage * parsedCollateralAmount / indexPrice);
      setPositionAmountRaw(newPosition);
      const newFormattedPosition = parseFloat(ethers.formatEther(newPosition)).toFixed(12);
      setPositionAmount(newFormattedPosition);

      const liqPrice = getLiquidationPrice(product, newCollateralAmount, newFormattedPosition, isLong, currentProduct.displayDecimals);
      setNewLiqPrice(liqPrice);
      checkLimitErrors(newPosition);
    },
    // eslint-disable-next-line
    [product, isLong]
  );

  const { collateralBalance } = useMemo(() => {
    let collateralBalance = 0;
    if (tokensBalance) {
      collateralBalance = parseFloat(ethers.formatUnits(tokensBalance.usdcBalance, "mwei"));
    }

    return { collateralBalance }
  }, [tokensBalance])  

  const { usdcAllowance } = useMemo(() => {
    let usdcAllowance = 0;
    if (collateralSnapshot) {
      usdcAllowance = parseFloat(ethers.formatUnits(collateralSnapshot.usdcAllowance, "mwei"))
    }

    return { usdcAllowance }
  }, [collateralSnapshot])

  const checkLimitErrors = (positionSize: bigint) => {
    setPositionError("");
    if (BigInt(totalTaker) + positionSize > BigInt(totalMaker)) {
      // Only disable opening new taker positions, closing is still okay in a socialization scenario
      if (positionAmountRaw > 0n) {
        setPositionError(t("error.no-liquidity-3"))
      }
    }
  }

  const onLeverageChange = (value: number, index: number) => {
    setCurrentLeverage(value);
    setInputLeverage(value.toString());
    calculatePosition(collateralAmount, value.toString());
  };

  const onLeverageInputChange = (value: number, strValue: string) => {
    setInputLeverage(strValue);
    if (value !== -1) {
      setCurrentLeverage(value);
      calculatePosition(collateralAmount, value.toString());
    } else {
      checkLimitErrors(positionAmountRaw);
    }
  };

  const onCollateralChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCollateralError("")
    if (value !== "" && isNonNegativeNumber(value)) {
      const val = parseFloat(value);
      if (hasMaxDecimalsAllowed(value, 6)) { 
        setCollateralAmount(value);
        calculatePosition(value, newLeverage.toString());
        setCollateralDiff(val - parseFloat(collateralInitialValue));
      }
      
      if (val < minCollateral) {
        setCollateralError(t("error.min-collateral", { value: "10 USDC.e" }))
      }
      if (priceUSDC) {
        const p = parseFloat(ethers.formatUnits(priceUSDC, 8));
        setCollateralAmountUsd((p * val).toFixed(4));
      }
    } else {
      setCollateralAmount("");
      setCollateralAmountUsd("0");
      checkLimitErrors(positionAmountRaw);
    }
  };

  const onSetMaxBalanceClick = (value: number) => {
    const newValue = value + parseFloat(collateralInitialValue);
    calcLeverage(positionAmount, newValue.toString());
    setCollateralAmount(newValue.toString());
  };

  const onConfirmButtonClick = () => {
    setIsWriting(true);
    onOpenPosition(productAddress, collateralDiff, positionDelta)
  };

  const isDataValid = (): boolean => {
    const valid =
      isNonNegativeNumber(collateralAmount) &&
      isNonNegativeNumber(positionAmount) &&
      collateralError === "" &&
      positionError === "" &&
      currentLeverage > 0 &&
      currentLeverage <= 20;

    return valid;
  };

  const RenderConfirmPosition = () => (
    <Modal.Body className="confirm-position">
      <div className="markets-info">
        <TokenIcon name={currentProduct.symbol} size="normal" />
        <div className="markets-desc">
          <h6 className="title">
            {currentProduct.description}
          </h6>
          <span className="caption">
            {isLong ? t("long").toUpperCase() : t("short").toUpperCase()} - {collateralToken.symbol.toUpperCase()}
          </span>
        </div>
      </div>
      <div className="change-info">
        <div className="info-item">
          <span className="title">{t("collateral")}:</span>
          <div className="value-change">
            <NumericFormat
              className="number gray"
              value={collateralInitialValue}
              displayType="text"
              thousandSeparator=","
              decimalScale={2}
            />
            <FaArrowRight size={12} />
            <NumericFormat
              className="number"
              value={collateralAmount}
              displayType="text"
              thousandSeparator=","
              decimalScale={2}
            />
          </div>
        </div>
        <div className="info-item">
          <span className="title">{t("position-amount")}:</span>
          <div className="value-change">
            <NumericFormat
              className="number gray"
              value={positionInitialValue}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
            />
            <FaArrowRight size={12} />
            <NumericFormat
              className="number"
              value={positionAmount}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
            />
          </div>
        </div>
        <div className="info-item">
          <span className="title">{t("leverage")}:</span>
          <div className="value-change">
            <span className="number gray">{leverageInitialValue}x</span>
            <FaArrowRight size={12} />
            <span className="number">{currentLeverage}x</span>
          </div>
        </div>
        <div className="info-item">
          <span className="title">{t("liquidation-price")}:</span>
          <div className="value-change">    
            <NumericFormat
              className="number"
              value={initialLiqPrice}
              displayType="text"
              thousandSeparator=","
              decimalScale={currentProduct.displayDecimals}
            />
            <FaArrowRight size={12} />
            <NumericFormat
              className="number"
              value={newLiqPrice}
              displayType="text"
              thousandSeparator=","
              decimalScale={currentProduct.displayDecimals}
            />
          </div>
        </div>
      </div>
    </Modal.Body>
  );

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      className={showConfirm ? "modal-edit-position confirm" : "modal-edit-position"}
    >
      <Modal.Header closeButton>
        <h5 className="bold">{t("change-position")}</h5>
      </Modal.Header>
      {!showConfirm ? (
        <Modal.Body>
          <div className="edit-left">
            <MarketInfo
              market={currentProduct}
              positionType={isLong ? "long" : "short"}
              collateral={collateralToken}
            />
            <div className="inputs-container">
              <Form.Group className="asset-input-group">
                <div className="asset-input-top">
                  <h6>{t("change-collateral")}</h6>
                  <NumericFormat
                    className="balance-usd"
                    value={collateralAmountUsd}
                    displayType="text"
                    thousandSeparator=","
                    decimalScale={4}
                    suffix=" USD"
                  />
                </div>
                <InputGroup className="input-collateral">
                  <Form.Control
                    id="update-position-input"
                    placeholder=""
                    className="balance-input"
                    type={isMobile ? "number" : "text"}
                    value={collateralAmount}
                    onChange={onCollateralChange}
                  />
                  <div className="asset-info">
                    <div className="index-info">
                      <TokenIcon name={collateralToken.key} size="small" />
                      <h6>{collateralToken.symbol}</h6>
                    </div>
                    <div className="asset-balance">
                      <span className="title">
                        {t("balance")}:
                      </span>
                      <NumericFormat
                        className="balance"
                        value={collateralBalance.toFixed(4)}
                        displayType="text"
                        thousandSeparator=","
                        decimalScale={4}
                      />
                      <Button
                        variant="secondary"
                        className="small"
                        onClick={() => onSetMaxBalanceClick(collateralBalance)}
                      >
                        {t("max")}
                      </Button>
                    </div>
                  </div>
                </InputGroup>
                {collateralError !== "" && (
                  <span className="error">
                    {collateralError}
                  </span>
                )}
                {positionError !== "" && (
                  <span className="error">
                    {positionError}
                  </span>
                )}
              </Form.Group>       
              <Form.Group className="leverage-group">
                <div className="leverage-header">
                  <h6>{t("change-leverage")}</h6>
                  <LeverageInput
                    controlId="update-position-lev-input"
                    value={inputLeverage}
                    max={20}
                    onChange={onLeverageInputChange}
                  />
                </div>
                <LeverageSlider
                  defaultValue={1}
                  value={currentLeverage}
                  maxValue={20}
                  onChange={onLeverageChange}
                />
                <div className="leverage-values">
                  <span className="small">1x</span>
                  <span className="small">20x</span>
                </div>
              </Form.Group> 
              <div className="liquidation">
                <CustomTooltip
                  id="upliq-price"
                  msg={t("tooltip.liquidation-price")}
                >
                  <h6 className="bold">{t("liquidation-price")}:</h6>
                </CustomTooltip>
                <CustomTooltip
                  id="upliq-price2"
                  msg={t("tooltip.liquidation-price")}
                >
                  <NumericFormat
                    className="balance-usd bold"
                    value={newLiqPrice}
                    displayType="text"
                    thousandSeparator=","
                    suffix=" USD"
                    decimalScale={currentProduct.displayDecimals}
                  />
                </CustomTooltip>  
              </div>
              <Fees
                productAddress={productAddress}
                positionDelta={positionDelta}
                isTaker={isTaker}
                isLiquidity={false}
              />
            </div>        
          </div>
          <div className="edit-right">
            <div className="position-changes">
              <h6>{t("position-changes")}</h6>
              <div className="summary-box changes">
                <div className="summary-item">
                  <div className="summary-item-2">
                    <TokenIcon name="usdc" size="xs" />
                    <h6 className="title">{t("collateral")}:</h6>
                  </div>  
                  <div className="value-change">
                    <div className="value-change-number">                  
                      <NumericFormat
                        className="number gray"
                        value={collateralInitialValue}
                        displayType="text"
                        thousandSeparator=","
                        decimalScale={4}
                      />
                    </div>  
                    <FaArrowRight size={12} />
                    <div className="value-change-number">
                      <NumericFormat
                        className="number"
                        value={collateralAmount}
                        displayType="text"
                        thousandSeparator=","
                        decimalScale={4}
                      />
                    </div>  
                  </div> 
                </div>
                <div className="summary-item">
                  <div className="summary-item-2">
                    <TokenIcon name={currentProduct.symbol} size="xs" />
                    <h6 className="title">{t("amount")}:</h6>
                  </div>  
                  <div className="value-change">
                    {currentProduct.key !== TOKENS_SYMBOLS.perpe && (
                      <>
                        <div className="value-change-number">
                          <NumericFormat
                            className="number gray"
                            value={positionInitialValue}
                            displayType="text"
                            thousandSeparator=","
                            decimalScale={4}
                          />
                        </div>
                        <FaArrowRight size={12} />
                      </>
                    )}    
                    <div className="value-change-number">
                      <NumericFormat
                        className={"number ".concat(positionError !== "" ? " error" : "")}
                        value={positionAmount}
                        displayType="text"
                        thousandSeparator=","
                        decimalScale={4}
                      />
                    </div>  
                  </div> 
                </div>
                <div className="summary-item">
                  <h6 className="title">{t("leverage")}:</h6>
                  <div className="value-change">
                    <span className="number gray">{leverageInitialValue}x</span>
                    <FaArrowRight size={12} />
                    <span className="number">{currentLeverage}x</span>
                  </div>
                </div>
                <div className="summary-item">
                  <h6 className="title">{t("liq-price")}:</h6>
                  <div className="value-change">
                    {currentProduct.key !== TOKENS_SYMBOLS.perpe && (
                      <>
                        <NumericFormat
                          className="number gray"
                          value={initialLiqPrice}
                          displayType="text"
                          thousandSeparator=","
                          prefix="$"
                          decimalScale={currentProduct.displayDecimals}
                        />
                        <FaArrowRight size={12} />
                      </>
                    )}
                    <NumericFormat
                      className="number"
                      value={newLiqPrice}
                      displayType="text"
                      thousandSeparator=","
                      prefix="$"
                      decimalScale={currentProduct.displayDecimals}
                    />
                  </div> 
                </div>
              </div>
            </div>
            <p>
              {t("info-msg.change-warning")}
            </p>
            {collateralAmount === "" || parseFloat(collateralAmount) <= usdcAllowance ? (
              <Button
                className="btn-edit-position bold"
                onClick={() => {
                  setShowConfirm(true);
                }}
                disabled={isWriting || !isDataValid()}
              >
                {t("change-position")}
              </Button>
            ) : (
              <ApproveCollateralButton
                approvalAction={ApproveType.DEPOSIT}
                currentAllowance={usdcAllowance}
                amountToApprove={
                  collateralAmount === "" ? 0 : parseFloat(collateralAmount)
                }
                onSuccess={() => {
                  mutateCollateral();  
                }}
              />  
            )} 
          </div>
        </Modal.Body>
      ) : (
        <>
          <RenderConfirmPosition />
          <Modal.Footer className="confirm-change">
            <Button
              className="btn-edit-position bold"
              onClick={() => setShowConfirm(false)}
              disabled={isWriting}                
            >
              {t("cancel")}
            </Button>    
            <Button
              className="btn-edit-position bold"
              onClick={onConfirmButtonClick}
              disabled={isWriting}                
            >
              <div className="btn-spinner">
                {isWriting && <Spinner animation="border" variant="secondary" className="small" />}
                {isWriting ? t("changing") : t("confirm-change")}
              </div>              
            </Button>
          </Modal.Footer>  
        </>    
      )}
    </Modal>
  ); 
};
