import { MultiInvokerAddresses, PerennialVaultType, sum } from '@perennial/sdk'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { Address } from 'viem'
import { encodeFunctionData } from 'viem/utils'
import { useWalletClient } from 'wagmi'

import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'
import { useTransactionToasts } from "../../components/common/notifications"
import { useVaultSnapshots2 } from '.'
import { useUSDC } from '../contracts'
import { useMarketOracles2 } from '../markets2'
import { useAddress, useChainId } from '../network'
import { useTranslation } from 'react-i18next'


export const useVaultTransactions = ({
  vaultType,
  vaultAddress,
}: {
  vaultType: PerennialVaultType
  vaultAddress: Address
}) => {
  const chainId = useChainId()
  const { address } = useAddress()
  const { data: walletClient } = useWalletClient()
  const { data: marketOracles } = useMarketOracles2()
  const { data: vaultSnapshots } = useVaultSnapshots2()
  const sdk = usePerennialSDKContext()
  const copy = useVaultTransactionCopy()
  const toasts = useTransactionToasts()

  const addRecentTransaction = useAddRecentTransaction()

  const usdcContract = useUSDC()
  const queryClient = useQueryClient()

  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['vaultSnapshots2', 'vaultPositionHistory', 'balances'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const onApproveUSDC = async (args: Parameters<typeof sdk.operator.write.approveUSDC>[0]) => {
    if (!address) throw new Error('No Address')
    const hash = await sdk.operator.write.approveUSDC(args)

    await toasts.waitForTransactionAlert(hash, { successMsg: copy.approveUSDC })
    await refresh()
    addRecentTransaction({
      hash,
      description: "USDC.e approved for vault",
    })
    const newAllowance = await usdcContract.read.allowance([address, MultiInvokerAddresses[chainId]])

    return { hash, newAllowance }
  }

  const onApproveOperator = async () => {
    if (!walletClient) return
    const hash = await sdk.operator.write.approveVaultFactory()

    const receipt = await toasts.waitForTransactionAlert(hash)
    await refresh()
    addRecentTransaction({
      hash,
      description: copy.approveShares,
    })
    return receipt
  }

  const onDeposit = async (amount: bigint) => {
    const hash = await sdk.vaults.write.deposit({ amount, vaultAddress, vaultSnapshots, marketOracles })
    const receipt = toasts.waitForTransactionAlert(hash, { successMsg: copy.depositCollateral })
    addRecentTransaction({
      hash: hash,
      description: "Collateral Deposited",
    })
    await refresh()
    // Refresh after a timeout to catch missed events
    setTimeout(() => refresh(), 15000)
    setTimeout(() => refresh(), 25000)
    return receipt
  }

  const onRedeem = async (amount: bigint, { assets = true, max = false }) => {
    const hash = await sdk.vaults.write.redeem({ amount, vaultAddress, vaultSnapshots, marketOracles, assets, max })
    const receipt = toasts.waitForTransactionAlert(hash, { successMsg: copy.redeemCollateral })
    addRecentTransaction({
      hash: hash,
      description: "Collateral Redeemed",
    })
    await refresh()
    // Refresh after a timeout to catch missed events
    setTimeout(() => refresh(), 15000)
    setTimeout(() => refresh(), 25000)
    return receipt
  }

  const onClaim = async () => {
    const hash = await sdk.vaults.write.claim({ vaultAddress, vaultSnapshots, marketOracles })
    const receipt = toasts.waitForTransactionAlert(hash, { successMsg: copy.claimCollateral })
    addRecentTransaction({
      hash: hash,
      description: "Collateral Claimed",
    })
    await refresh()
    // Refresh after a timeout to catch missed events
    setTimeout(() => refresh(), 15000)
    setTimeout(() => refresh(), 25000)
    return receipt
  }

  const onCommitAllVaas = async () => {
    const vaultSnapshot = vaultSnapshots?.vault[vaultType]
    if (!vaultSnapshot || !marketOracles || !walletClient) throw new Error('Unable to fetch required data')

    const multiInvoker = sdk.contracts.getMultiInvokerContract()
    const commitments = await sdk.vaults.read.vaultCommitments({
      preMarketSnapshots: vaultSnapshot.pre.marketSnapshots,
      marketOracles,
    })

    const hash = await walletClient.sendTransaction({
      to: multiInvoker.address,
      data: encodeFunctionData({
        abi: multiInvoker.abi,
        functionName: 'invoke',
        args: [commitments.map((c) => c.commitAction)],
      }),
      value: sum(commitments.map((c) => c.value)),
    })
    const receipt = toasts.waitForTransactionAlert(hash, { successMsg: copy.commitVaultVAAs })
    addRecentTransaction({
      hash,
      description: copy.commitVaultVAAs,
    })

    return receipt
  }

  return {
    onApproveUSDC,
    onApproveOperator,
    onDeposit,
    onRedeem,
    onClaim,
    onCommitAllVaas,
  }
}

const useVaultTransactionCopy = () => {
  const { t } = useTranslation()
  return {
    approveUSDC: t("notification.approved", { token: "USDC.e" }),
    approveDSU: t("notification.approved", { token: "DSU" }),
    approveShares: t("notification.staked-shares-approved"),
    depositCollateral: t("notification.collateral-deposited"),
    redeemCollateral: t("notification.collateral-redeemed"),
    claimCollateral: t("notification.collateral-claimed"),
    commitVaultVAAs: 'Commit Vault VAAs',
  }
}

