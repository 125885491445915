import React, { useContext, useState } from "react"
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { AiOutlineMenu } from "react-icons/ai"
import { BsBell } from "react-icons/bs"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { useMediaQuery } from "react-responsive"
import { useWalletClient } from "wagmi"
import { ReactComponent as CryptexLogo } from "../assets/logo.svg"

import { geoLocationContext } from "../v1/states"
import { useOnSupportedChain } from "../hooks/network"
import { CustomConnectButton } from "./CustomConnectButton"
import { LanguageDropdown, LanguageModal } from "./common/Localization"
import { LanguageMetadata, SupportedLanguage } from "../constants/localization"
import Flag from "react-flagkit"
import { PushNotifications } from "./push"
import { usePushContext } from "../contexts"


type props = {
  showNav: boolean;
}

const Header = ({ showNav }: props) => {
  const { t, i18n } = useTranslation()
  const pushAPI = usePushContext()
  const { data: walletClient } = useWalletClient()
  const { isUsingSupportedChain } = useOnSupportedChain()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const isTablet = useMediaQuery({ query: "(min-width: 601px) and (max-width: 850px)" })
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en) as SupportedLanguage
  const currentGeolocation = useContext(geoLocationContext)
  const [showLangModal, setShowLangModal] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)

  const onModalHide = () => {
    setShowLangModal(false)
  }

  const onNotificationsHide = () => { 
    setShowNotifications(false)
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand  href="#">
          <CryptexLogo className="logo" />
        </Navbar.Brand>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="menu-container">
            <div className="menu">
              <NavDropdown id="trade-dropdown" title={t("trade")}>
                <NavDropdown.Item href="/">
                  {t("trade-all-markets")}
                </NavDropdown.Item>
                <NavDropdown.Item href="/v2/">
                  {t("trade-tcap")}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown id="trade-dropdown" title={t("provide-liquidity")}>
                <NavDropdown.Item href="/liquidity">
                  {t("lp-all-markets")}
                </NavDropdown.Item>
                <NavDropdown.Item href="/v2/liquidity">
                  {t("lp-tcap")}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown id="earn-dropdown" title={t("earn")}>
                <NavDropdown.Item href="/rewards">
                  {t("rewards")}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://app.camelot.exchange/nitro/0x444D45d5d9612aBB596420fE81DA41a1aA98CeBa"
                  target="_blank"
                >
                  Camelot Nitro Incentives
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://traderjoexyz.com/arbitrum/pool/v21/0x84f5c2cfba754e76dd5ae4fb369cfc920425e12b/ETH/50"
                  target="_blank"
                >
                  Trader Joe
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://v1.cryptex.finance/governance"
                  target="_blank"
                >
                  {t("single-side-staking")}
                </NavDropdown.Item>
              </NavDropdown>
              {isMobile && (
                <>
                  <Navbar.Toggle
                    onClick={() => setShowNotifications(true)}
                    className="nav-btn-language"
                    style={{ borderBottom: "0.5px solid #9795b0 !important" }}
                  >
                    Notifications
                  </Navbar.Toggle>
                  <Navbar.Toggle onClick={() => setShowLangModal(true)} className="nav-btn-language">
                    <Flag country={LanguageMetadata[currentLanguage].country} size={26} className="flag" />
                    {LanguageMetadata[currentLanguage].name}
                  </Navbar.Toggle>
                </>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
        <Nav.Item className="navbar-right">
          {currentGeolocation.isRestricted() && !isMobile ? (
            <>
              <div className="connect">
                <button className="btn btn-primary" type="button" disabled>
                  {t("app-not-available")}
                </button>
              </div>
              {(isTablet || isMobile) && (
                <Navbar.Toggle
                  aria-controls="navbar-nav"
                  children={
                    <AiOutlineMenu size={18} fontWeight={700} />
                  }  
                />    
              )}
            </>
          ) : (
            <>
              {!currentGeolocation.isRestricted() && (  
                <div className="connect">
                  {isUsingSupportedChain ? (
                    <>
                      {!isMobile ? (
                        <ConnectButton chainStatus={isUsingSupportedChain ? "none" : "full"} />
                      ) : (
                        <ConnectButton chainStatus={isUsingSupportedChain ? "none" : "full"} accountStatus="address" />
                      )}    
                    </>    
                  ) : (
                    <CustomConnectButton />      
                  )}               
                </div>
              )}
              {isTablet && <LanguageDropdown />}  
              <Navbar.Toggle
                aria-controls="navbar-nav"
                children={
                  <AiOutlineMenu size={18} fontWeight={700} />
                }  
              />
            </>
          )}
          {!isTablet && !isMobile && <LanguageDropdown />}
          {!isMobile && (
            <Button
              variant="primary"
              onClick={() => setShowNotifications(!showNotifications)}
              disabled={!pushAPI.channelApi || !walletClient}
              style={{ marginLeft: "5px", borderRadius: "3px" }}
            >
              <BsBell size={22} />
            </Button>
          )}
        </Nav.Item>
      </Container>
      <LanguageModal show={isMobile && showLangModal} onHide={onModalHide} />
      <PushNotifications showModal={showNotifications && !!walletClient} onModalHide={onNotificationsHide} />
    </Navbar>
  )
};

export default Header
