import React, { useContext, useMemo } from "react"
import { ethers } from "ethers"
import ProgressBar from "react-bootstrap/ProgressBar"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"

import { useActiveProvider, useVaultSnapshot } from "../../hooks/"
import { productContext } from "../../states"


export const LiquidityCapacity = () => {
  const { t } = useTranslation()
  const { chainId } = useActiveProvider();
  const currentMarket = useContext(productContext);
  const { data: vaultData } = useVaultSnapshot(currentMarket.vaultContract[chainId].address, currentMarket.productContract[chainId].address);
  
  const { liquidity, capacity } = useMemo(() => {
    const liquidity =
      vaultData ? parseFloat(ethers.formatEther(vaultData.totalAssets)) : 0;

    const capacity =
      vaultData ? parseFloat(ethers.formatEther(vaultData.maxCollateral)) : 0;
    
    return {
      liquidity,
      capacity,
    }
    
  }, [vaultData]);
  
  return (
    <div className="capacity-container">
      <div className="capacity-info">
        <span>
          {t("current-liquidity")}
        </span>
        <div className="capacity-values">
          <NumericFormat
            className="number small"
            value={liquidity}
            displayType="text"
            thousandSeparator=","
            decimalScale={0}
          />
          <span className="separator">/</span>
          <NumericFormat
            className="number small"
            value={capacity}
            displayType="text"
            thousandSeparator=","
            decimalScale={0}
          />
        </div>
      </div>
      <ProgressBar
        variant="primary"
        now={liquidity}
        max={capacity}
      />
    </div>  
  );     
};
