import React, { useContext, useEffect, useState } from "react"
import { Button } from "react-bootstrap/esm"
import { useMediaQuery } from "react-responsive"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { AcceptReferral } from "../../../components/referrals"
import Exchange from "../../components/Exchange"
import { Positions } from "../../components/positions"
import TabsChart from "../../../components/TabsChart"
import { MobileTradingViewWidget } from "../../components/mobile/MobileTradingViewWidget"
import { PriceChartWrapper } from "../../components/charts/PriceChartWrapper"
import { PriceBox } from "../../components/common"
import {
  useActiveProvider,
  useUserProductSnapshots,
} from "../../hooks";
import { geoLocationContext } from "../../states"
import { OpenPositionType } from "../../utils/types"


const MobileMarkets = () => {
  const { t } = useTranslation()
  const queryParams = useParams();
  const referrerUuid = queryParams.uuid;
  const currentGeolocation = useContext(geoLocationContext);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const [tabState, setTabState] = React.useState("market");
  const { userAccount } = useActiveProvider();
  const { data: userSnapshots, isLoading } = useUserProductSnapshots(userAccount);
  const [openedPositions, setOpenedPositions] = useState(0);

  useEffect(() => {
    const load = async () => {
      if (userSnapshots) { 
        const addresses = Object.keys(userSnapshots);
        let oPositions = 0;
        for (let i = 0; i < addresses.length; i += 1) {
          const productAddress = addresses[i];
          const userSnapshot = userSnapshots[productAddress];

          const isPositionOpen =
            userSnapshot.collateral > 0n || userSnapshot.positionSize > 0n;
          
          if (isPositionOpen && userSnapshot.positionDirection === OpenPositionType.Taker) {
            oPositions += 1;
          }
        }

        setOpenedPositions(oPositions);
      }
    };
    load();
  },
    // eslint-disable-next-line
    [userSnapshots]
  );

  return (
    <div className="mobile-trade">
      {currentGeolocation.isRestricted() && isMobile && (
        <Button className="btn btn-primary btn-restricted" type="button" disabled>
          {t("app-not-available")}
        </Button>
      )}
      {referrerUuid && !currentGeolocation.isRestricted() && <AcceptReferral referrerUuid={referrerUuid} />}
      <PriceBox showMarketCap={true} />
      <TabsChart onTabChange={setTabState} />        
      <div className="text-left float-above-bg">
        {tabState === "market" ?  <MobileTradingViewWidget/> : <PriceChartWrapper />}
        {openedPositions < 2 && <Exchange isTaker={true} />}
        <Positions
          userSnapshots={userSnapshots}
          isLoadingPositions={isLoading}
          positionType={OpenPositionType.Taker}
        />
      </div>
    </div>
  );
}

export default MobileMarkets;