import { ethers } from "ethers";
import { CHAINS, MARKETS } from "./constants";
import {
  OpenPositionType,
  PrePositionStruct,
  PositionStruct,
  ProductSnapshot,
  UserPositionChanges,
} from "./types";
import { tcapVaultContract } from "./contracts";
import { TCAP_MARKET } from "./constants";
import { TokenType } from "./types";


export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const BigMath = {
  abs(x: bigint) {
    return x < 0n ? -x : x
  },
  sign(x: bigint) {
    if (x === 0n) return 0n
    return x < 0n ? -1n : 1n
  },
  pow(base: bigint, exponent: bigint) {
    return base ** exponent
  },
}

export const copyToClipboard = (e: React.MouseEvent, textToCopy: string) => {
  e.preventDefault();
  // Create new element
  const el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = textToCopy;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

export const isNumber = (value: any): boolean => {
  try {
    const v = parseFloat(value);
    return !Number.isNaN(v);
  } catch (error) {
    return false;  
  }
};

export const isPositiveNumber = (value: any): boolean => {
  try {
    const val = parseFloat(value);
    return val > 0;  
  } catch (error) {
    return false;  
  }
};

export const isNonNegativeNumber = (value: any): boolean => {
  try {
    const val = parseFloat(value);
    return val >= 0;  
  } catch (error) {
    return false;  
  }
};

export const hasMaxDecimalsAllowed = (value: number | string, maxDecimals: number): boolean => {
  let decimalsCount = 0;
  let parts = value.toString().split(".");
  if (parts.length > 1) {
    decimalsCount = parts[1].length;
  } else {
    parts = value.toString().split(",");
    if (parts.length > 1) { 
      decimalsCount = parts[1].length;
    }
  }

  return decimalsCount <= maxDecimals;
};

export const makeShortAddress = (address: string) => {
  const shortAddress = address.slice(0, 6).concat("...").concat(address.slice(address.length - 6, address.length));

  return shortAddress;
};

export const sumBNArray = (values: bigint[]): bigint => {
  return values.reduce((resp, v) => resp + v, 0n);
};

export const nextPosition = (pre: PrePositionStruct, pos: PositionStruct): PositionStruct => {
  return {
    maker: pos.maker + pre.openPosition.maker - pre.closePosition.maker,
    taker: pos.taker + pre.openPosition.taker - pre.closePosition.taker,
  }
}

export const addPositions = (a: PositionStruct, b: PositionStruct): PositionStruct => {
  return {
    maker: a.maker + b.maker,
    taker: a.taker + b.taker,
  }
}

export const userPositionDirection = (pos: PositionStruct, closePosition: PositionStruct) => {
  
  if (pos.maker === 0n && pos.taker === 0n) {
    if (!closePosition || (closePosition.maker === 0n && closePosition.taker === 0n)) {
      return "none";
    }

    if (0n < closePosition.maker) return OpenPositionType.Maker;
    return OpenPositionType.Taker;
  };

  if (0n < pos.maker) return OpenPositionType.Maker;
  return OpenPositionType.Taker;
}

export const userPositionSize = (pos?: PositionStruct) => {
  if (!pos) return 0n;
  if (pos.maker > 0) return pos.maker;

  return pos.taker;
}

export const calculatePositionFee = (price: bigint, positionDelta: bigint, feeRate: bigint) => {
  const priceF = parseFloat(ethers.formatEther(price));
  const positionF = parseFloat(ethers.formatEther(positionDelta));
  const fee = parseFloat(ethers.formatEther(feeRate));

  return Math.abs(priceF * positionF * fee);
}

export const calculateLeverage = (price: bigint, position: bigint, collateral: bigint): string => {
  if (position === 0n || collateral === 0n) return "0";
  const notional = BigMath.abs(price) * position;
  return ethers.formatEther(notional / collateral);
}

export const calculateLeverageBN = (price: bigint, position: bigint, collateral: bigint): bigint => {
  if (position === 0n || collateral === 0n) return 0n;
  const notional = BigMath.abs(price) * position;
  return notional / collateral;
}

export const calculateExposure = (userPosition: bigint, globalPosition: PositionStruct) => {
  if (globalPosition.maker === 0n) return 0n;

  return (userPosition * globalPosition.taker) / globalPosition.maker;
}

export const calculateFunding = (leverage: bigint, rate: bigint, globalPosition: PositionStruct) => {
  if (globalPosition.maker === 0n) return 0n

  const lev = parseFloat(ethers.formatEther(leverage));
  const r = parseFloat(ethers.formatEther(rate));

  const taker = parseFloat(ethers.formatEther(globalPosition.taker));
  const maker = parseFloat(ethers.formatEther(globalPosition.maker));
  const funding = lev * r * taker / maker;

  return ethers.parseEther(funding.toFixed(17));
}

// Returns the latest average entry price for the user's current position
export const calculateLatestAvgEntryPrice = (positionChanges: UserPositionChanges | undefined) => {
  if (!positionChanges) return "-";
  if (!positionChanges.buckets?.length) return "-";
  const { buckets } = positionChanges
  const bucket = buckets[buckets.length - 1]

  const totalNotional = sumBNArray(bucket.subPositions.map((p) => BigMath.abs(p.price) * p.size))

  const denominator = sumBNArray(bucket.subPositions.map((p) => p.size))
  if (denominator === 0n) return "-";
  const averageEntry = totalNotional / denominator;

  return ethers.formatEther(averageEntry);
}

// LiquidationPrice = (position * abs(price) - collateral) / (position * (maintenanceRatio -1)
const getLiquidationPriceLong = (
  product: ProductSnapshot,
  collateral: string,
  position: string,
  decimals: number,
) => {
  const indexPrice = product.latestVersion.price;
  const maintenance = ethers.formatEther(product.maintenance);

  const notional = parseFloat(position) * parseFloat(ethers.formatEther(indexPrice));
  const numerator = notional - parseFloat(collateral);
  const denominator = parseFloat(position) * (parseFloat(maintenance) - 1);

  return Math.abs(numerator / denominator).toFixed(decimals);
};

// LiquidationPrice = (position * abs(price) + collateral) / (position * (1 + maintenanceRatio)
const getLiquidationPriceShort = (
  product: ProductSnapshot,
  collateral: string,
  position: string,
  decimals: number,
) => {
  const indexPrice = ethers.formatEther(product.latestVersion.price);
  const maintenance = ethers.formatEther(product.maintenance);

  const notional = parseFloat(position) * Math.abs(parseFloat(indexPrice));
  const numerator = notional + parseFloat(collateral);
  const denominator = (1 + parseFloat(maintenance)) * parseFloat(position);

  return Math.abs(numerator / denominator).toFixed(decimals);
};

export const getLiquidationPrice = (
  product: ProductSnapshot | undefined,
  collateral: string,
  position: string,
  isLong: boolean,
  decimals: number = 4,
) => {
  if (!product) return "-";

  if (isLong) {
    return getLiquidationPriceLong(product, collateral, position, decimals);
  } else {
    return getLiquidationPriceShort(product, collateral, position, decimals);
  }
};

export const getRateDisplay = (rate: bigint) => {
  return parseFloat(ethers.formatEther(rate * 100n)).toFixed(5);
}

// Returns the latest average entry price for the user's current position
export const calculateLatestPositionPnl = (
  positionChanges: UserPositionChanges | undefined,
  currentCollateral: bigint
) => {
  if (!positionChanges) return 0n;
  if (!positionChanges.buckets?.length) return 0n;
  if (currentCollateral === 0n) return 0n;
  
  const { buckets } = positionChanges;
  const bucket = buckets[buckets.length - 1];

  const totalDeposits = sumBNArray(bucket.collateralChanges.deposits);
  const totalWithdrawals = sumBNArray(bucket.collateralChanges.withdrawals);

  const net = bucket.startingCollateral + (totalDeposits - totalWithdrawals);

  return currentCollateral - net;
}

export const getMarketByProduct = (chainId: number, productAddress: string): TokenType => {
  const prodAdress = productAddress.toLowerCase();
  const markets = Object.values(MARKETS);

  for (let i = 0; i < markets.length; i += 1) {
    if (
      markets[i].longContract[chainId].address.toLowerCase() === prodAdress ||
      markets[i].shortContract[chainId].address.toLowerCase() === prodAdress
    ) {
      return markets[i].market;
    }
  }  

  return TCAP_MARKET.market;
};

export const getSymbolByProduct = (chainId: number, productAddress: string): string => {
  const prodAdress = productAddress.toLowerCase();
  const markets = Object.values(MARKETS);

  for (let i = 0; i < markets.length; i += 1) {
    if (
      markets[i].longContract[chainId].address.toLowerCase() === prodAdress ||
      markets[i].shortContract[chainId].address.toLowerCase() === prodAdress
    ) {
      return markets[i].market.symbol;
    }
  }  

  return TCAP_MARKET.market.symbol;
};

export const getNameByProduct = (chainId: number, productAddress: string): string => {
  const prodAdress = productAddress.toLowerCase();
  const markets = Object.values(MARKETS);

  for (let i = 0; i < markets.length; i += 1) {
    if (
      markets[i].longContract[chainId].address.toLowerCase() === prodAdress ||
      markets[i].shortContract[chainId].address.toLowerCase() === prodAdress 
    ) {
      return markets[i].market.description;
    }
  }

  return TCAP_MARKET.market.description;
};

export const getCollateralTokenByProduct = (chainId: number, productAddress: string): TokenType => {
  const prodAdress = productAddress.toLowerCase();
  const markets = Object.values(MARKETS);

  for (let i = 0; i < markets.length; i += 1) {
    if (
      markets[i].longContract[chainId].address.toLowerCase() === prodAdress ||
      markets[i].shortContract[chainId].address.toLowerCase() === prodAdress
    ) { 
      return markets[i].collateral;
    }
  }

  return TCAP_MARKET.collateral;
};

export const vaultHasLPRewards = (chainId: number, vaultAddress: string) => {
  return vaultAddress.toLowerCase() === tcapVaultContract[chainId].address.toLowerCase();
};

export const isLongPosition = (chainId: number, productAddress: string): boolean => {
  const prodAdress = productAddress.toLowerCase();
  const markets = Object.values(MARKETS);

  for (let i = 0; i < markets.length; i += 1) { 
    if (prodAdress === markets[i].longContract[chainId].address.toLowerCase()) {
      return true;
    }
  }

  return false;
};

export const formatDate = (date: Date, timeFormat: string = "") => {
  const options = { month: "short", day: "numeric" };
  // @ts-ignore
  let formatted = date.toLocaleDateString("en-US", options);

  if (timeFormat === "default") {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
  }

  if (timeFormat === "long") {
    const time = date.toTimeString().slice(0, 15);
    formatted = formatted.concat(", ").concat(time);
  }
  
  if (timeFormat === "short") {
    const time = date.toTimeString().slice(0, 5);
    formatted = formatted.concat(", ").concat(time);
  }

  return formatted;
};

export const decimalAdjust = (type: string, value: number, exp: number) => {
  type = String(type);
  if (!["round", "floor", "ceil"].includes(type)) {
    throw new TypeError(
      "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.",
    );
  }
  exp = Number(exp);
  value = Number(value);
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN;
  } else if (exp === 0) {
    // @ts-ignore
    return Math[type](value);
  }
  const [magnitude, exponent = 0] = value.toString().split("e");
  // @ts-ignore
  const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
  
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
  return Number(`${newMagnitude}e${+newExponent + exp}`);
}

export const isSupportedV1Chain = (chainId: number | undefined): boolean => {
  if (!chainId) return false;
  return Object.values(CHAINS).indexOf(chainId) !== -1;
}
