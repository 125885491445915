import React, { useMemo, useState } from "react";
import { Button, Image, Table } from "react-bootstrap/esm";
import { NumericFormat } from "react-number-format";
import {
  Big6Math,
  calcFundingRates,
  calcLiquidationPrice,
  calcMakerStats,
  formatBig6,
  formatBig6Percent,
  formatBig6USDPrice,
  MarketSnapshot,
  PositionSide,
  PositionStatus,
  SupportedAsset,
  UserMarketSnapshot,
} from "@perennial/sdk";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useMarketContext, useLivePriceContext } from "../../../contexts"
import { CustomTooltip } from "../../common";
import { AssetMetadata } from "../../../constants/markets";
import { capitalize } from "../../../v1/utils/utils";
import { UpdatePosition } from "../modals/UpdatePosition";
import { ClosePosition } from "../modals/ClosePosition";
import { useActiveMarketsPnls } from "../../../hooks/markets2/metrics";
import { useMarket7dData } from "../../../hooks/markets2/graph";


const pnlClassName = (pnl: number) => {
  if (Math.abs(pnl) < 0.0001) {
    return "number"
  }
  return pnl >= 0 ? "number green" : "number red"
}

export const PositionsList = ({ isProInterface }: { isProInterface: boolean }) => {
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery({ query: "(min-width: 851px) and (max-width: 1700px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" })
  const { snapshots2, setSelectedMarket, setSelectedMakerMarket } = useMarketContext()
  const livePrices = useLivePriceContext()
  const marketsPnl = useActiveMarketsPnls(livePrices)
  const [showUpdatePosition, setShowUpdatePosition] = useState(false)
  const [showClosePosition, setShowClosePosition] = useState(false)

  const hasPositions = useMemo(() => {
    if (snapshots2 && snapshots2.user) {
      let hasPositions =  false
      Object.keys(snapshots2.user).forEach((asset: string) => {
        if (snapshots2.user) {
          const position = snapshots2.user[asset as SupportedAsset]
          hasPositions = hasPositions ||
            ((isProInterface && (position.nextSide === PositionSide.maker || position.nextSide === PositionSide.none))
            || (!isProInterface && (position.nextSide !== PositionSide.maker)))

        }
      })
      return hasPositions
    }
    return false
  }, [isProInterface, snapshots2])

  const exposureTooltip = t("tooltip.exposure")

  const collateralTooltip = t("tooltip.collateral")

  const pnlTooltip = t("tooltip.pnl")

  const entryPriceTooltip = t("tooltip.entry-price-2")

  const liqPriceTooltip = t("tooltip.liquidation-price")

  const statusColorClass = (status: string) => {
    if (status === PositionStatus.open || status === PositionStatus.opening) {
      return "text-green"
    } else if (status === PositionStatus.closed || status === PositionStatus.closing) {
      return "text-grey"
    }
    return "text-red"
  };

  const closeBtnLabel = (positionStatus: string): string => {
    if (positionStatus === PositionStatus.closed) {
      return t("withdraw")
    }
    if (positionStatus === PositionStatus.closing) { 
      return t("closing")
    }
    return t("close")
  }

  const getPositionPnlData = (position: UserMarketSnapshot) => {
    if (marketsPnl) {
      const positionData = marketsPnl[position.asset]
      return {
        pnl: position.side === PositionSide.none && position.status === PositionStatus.closed ? 0n : positionData?.livePnl || 0n,
        initialCollateral: positionData?.data.startCollateral || 0n
      }
    }
    return {
      pnl: 0n,
      initialCollateral: 0n
    }
  }

  const PositionTotals = () => {
    let exposure = 0n
    let collateral = 0n
    let totalPnl = 0n
    
    if (snapshots2 && snapshots2.user) {
      const assets = Object.keys(snapshots2.user)
      for (let i = 0; i < assets.length; i += 1) {
        const position = snapshots2.user[assets[i] as SupportedAsset]
        const addPosition =(isProInterface && position.nextSide === PositionSide.maker)
          || (!isProInterface && position.nextSide !== PositionSide.maker)

        if (addPosition && position.status !== PositionStatus.resolved) {
          exposure += position.nextNotional
          collateral += position.local.collateral
          const { pnl } = getPositionPnlData(position)
          totalPnl += pnl
        }
      }
    }

    return (
      <tr className="table-totals">
        <td>
          <h5>{t("totals")}</h5>
        </td>
        <td className="right">
          <NumericFormat
            className="bold"
            value={formatBig6USDPrice(exposure)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </td>
        <td className="right">
          <NumericFormat
            className="bold"
            value={formatBig6USDPrice(collateral)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </td>
        <td className=" right">
          <NumericFormat
            className={
              "bold ".concat(pnlClassName(parseFloat(formatBig6(totalPnl))))
            }
            value={formatBig6USDPrice(totalPnl)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </td>
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    )
  }

  const RenderPositionMobile = (position: UserMarketSnapshot, index: number) => {
    if (position.status === PositionStatus.resolved) return <></>

    const assetMetadata = AssetMetadata[position.asset as SupportedAsset]
    let liquidationPrice = 0n
    let hourlyFunding = 0n

    if (snapshots2) {
      const market = snapshots2.market[position.asset as SupportedAsset]
      const minorSide = market?.minorSide

      const fundingRates = calcFundingRates(
        market ?
          market.fundingRate[
          isProInterface ?
            (minorSide as PositionSide.long | PositionSide.short) :
            (position.nextSide as PositionSide.long | PositionSide.short)
          ] : 0n
      )
      hourlyFunding = Big6Math.abs(fundingRates.hourlyFunding)

      // isMaker ? (minorSide as PositionSide2.long | PositionSide2.short) : position.nextSide
      const liquidationPrices = calcLiquidationPrice({
        marketSnapshot: market,
        collateral: position.local.collateral,
        position: position.nextMagnitude,
      })
      liquidationPrice = position.nextSide === PositionSide.long ? liquidationPrices.long : liquidationPrices.short
    }
    const { pnl, initialCollateral } = getPositionPnlData(position)

    return (
      <div key={index} className="position-box">
        <div className="box-header">
          <div className="market">
            <Image src={assetMetadata.icon} width={30} height={30} />
            <div className="market-info">
              <div className="postion-direction">
                <h6>{capitalize(position.side)}</h6>
                <div className="status">
                  <h6>-</h6>
                  <h6 className={statusColorClass(position.status)}>{capitalize(t(position.status))}</h6>
                </div>
              </div>
              <span className="title">{assetMetadata.name}</span>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className="balance"
                value={formatBig6USDPrice(position.nextNotional)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
              <CustomTooltip
                id="texposure"
                msg={exposureTooltip}
                showIcon={true}
                placement="top"
                iconOnLeft={true}
              >
                <h6 className="margin-right">{t("exposure")}</h6>
              </CustomTooltip>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={formatBig6USDPrice(position.nextMagnitude)}
                displayType="text"
                thousandSeparator=","
                suffix={" ".concat(assetMetadata.baseCurrency.toUpperCase())}
                decimalScale={4}
              />
              <h6 className="margin-right">{t("position-size")}</h6>
            </div>
          </div>
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className="balance"
                value={formatBig6USDPrice(initialCollateral)}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <h6>{t("initial-collateral")}</h6>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={formatBig6USDPrice(position.local.collateral)}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <h6>{t("current-collateral")}</h6>
            </div>
          </div>
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className={pnlClassName(parseFloat(formatBig6USDPrice(pnl)))}
                value={formatBig6USDPrice(pnl)}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <CustomTooltip
                id="tpnl"
                msg={pnlTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("total-pnl")}</h6>
              </CustomTooltip>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={formatBig6USDPrice(liquidationPrice)}
                displayType="text"
                thousandSeparator=","
                prefix="$"
              />
              <CustomTooltip
                id="tliqprice"
                msg={liqPriceTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("liq-price")}</h6>
              </CustomTooltip>
            </div>
          </div>
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className="balance"
                value={formatBig6USDPrice(position.prices[0])}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={assetMetadata.displayDecimals}
              />
              <CustomTooltip
                id="taprice"
                msg={entryPriceTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("est-entry-price")}</h6>
              </CustomTooltip>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={formatBig6Percent(hourlyFunding, { numDecimals: 4 })}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={5}
              />
              <CustomTooltip
                id="texposure"
                msg="The interest rate paid hourly."
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("h-funding-rate")}</h6>
              </CustomTooltip>
            </div>
          </div>
        </div>
        <div className={"box-footer "}>
          <Button
            variant="outline-primary"
            onClick={() => {
              setSelectedMarket(position.asset)
              setShowUpdatePosition(true)
            }}
          >
            {t("change")}
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              setSelectedMarket(position.asset)
              setShowClosePosition(true)
            }}
          >
            {closeBtnLabel(position.status)}
          </Button>
        </div>
      </div>
    );  
  }

  return (
    <>
      {!isMobile ? (
        <>
          {hasPositions && (
            <Table striped hover variant="dark">
              <thead>
                <tr>
                  <th>{t("market")}</th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={exposureTooltip}
                      showIcon={true}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("exposure")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={collateralTooltip}
                      showIcon={true}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("collateral")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="tpnl"
                      msg={pnlTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("total-profit-loss") : t("total-pnl")}
                      </h6>
                    </CustomTooltip>  
                  </th>
                  {!isProInterface ? (
                    <th className="right">
                      <CustomTooltip
                        id="taprice"
                        msg={entryPriceTooltip}
                        showIcon={true}
                        iconOnLeft={false}
                        placement="top"
                      >
                        <h6 className="margin-right">{t("est-entry-price")}</h6>
                      </CustomTooltip>
                    </th>
                  ) : (
                    <th className="right">
                      <h6 className="margin-right">{t("total-apr")}</h6>
                    </th>  
                  )}
                  <th className="right">
                    <CustomTooltip
                      id="tliqprice"
                      msg={liqPriceTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("liquidation-price") : t("liq-price")}
                      </h6>
                    </CustomTooltip>
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={t("tooltip.hourly-funding-rate")}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("hourly-funding-rate") : t("h-funding-rate")}
                      </h6>
                    </CustomTooltip>
                  </th>
                  <th className="center col-status">{t("status")}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {snapshots2 && snapshots2.user &&
                  Object.keys(snapshots2.user).map((asset: string, index: number) => {
                    if (snapshots2 && snapshots2.user) {
                      const market = snapshots2.market[asset as SupportedAsset]
                      if (!market) return <></>

                      return (
                        <PositionRow
                          key={index.toString()}
                          index={index}
                          isMaker={isProInterface}
                          market={market}
                          position={snapshots2.user[asset as SupportedAsset]}
                          setShowUpdatePosition={setShowUpdatePosition}
                          setShowClosePosition={setShowClosePosition}
                          setSelectedMarket={isProInterface ? setSelectedMakerMarket : setSelectedMarket}
                        />
                      )
                    }
                    return <></>
                  })
                }
                {PositionTotals()}
              </tbody>
            </Table>
          )}
          {!hasPositions && (
            <div className="empty-positions">
              <h5 className="text-purple">
                {t("info-msg.no-positions")}
              </h5>
            </div>
          )}
        </>  
      ) : (
        <>
          {snapshots2 && snapshots2.user &&
            Object.keys(snapshots2.user).map((asset: string, index: number) => {
              if (snapshots2 && snapshots2.user) {
                return RenderPositionMobile(snapshots2.user[asset as SupportedAsset], index);
              }
              return <></>
            })
          }  
          {!hasPositions && (
            <div className="empty-positions">
              <h5 className="text-purple">
                {t("info-msg.no-positions")}
              </h5>
            </div> 
          )}  
        </>    
      )}
      {showUpdatePosition && (
        <UpdatePosition
          showModal={showUpdatePosition}
          isMaker={isProInterface}
          onHide={() => setShowUpdatePosition(false)}
        />
      )}
      <ClosePosition
        showModal={showClosePosition}
        isMaker={isProInterface}
        onHide={() => setShowClosePosition(false)}
      />
    </>
  )
};


const PositionRow = ({
  index,
  isMaker,
  market,
  position,
  setShowUpdatePosition,
  setShowClosePosition,
  setSelectedMarket
}: {
  index: number
  isMaker: boolean
  market: MarketSnapshot
  position: UserMarketSnapshot
  setShowUpdatePosition: (v: boolean) => void
  setShowClosePosition: (v: boolean) => void
  setSelectedMarket: (v: SupportedAsset) => void
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery({ query: "(min-width: 851px) and (max-width: 1700px)" })
  const livePrices = useLivePriceContext()
  const marketsPnl = useActiveMarketsPnls(livePrices)
  const { data: market7dData } = useMarket7dData(position.asset)

  const addPosition =
    (isMaker && (position.nextSide === PositionSide.maker || position.nextSide === PositionSide.none))
    || (!isMaker && position.nextSide !== PositionSide.maker)
  
  const { pnl, initialCollateral, avgEntryPriceFormatted } = useMemo(() => {
    if (marketsPnl) {
      const positionData = marketsPnl[position.asset]

      return {
        pnl: position.side === PositionSide.none && position.status === PositionStatus.closed ? 0n : positionData?.livePnl || 0n,
        initialCollateral: positionData?.data.startCollateral || 0n,
        avgEntryPriceFormatted: positionData?.averageEntryPriceFormatted
      }
    }
    return {
      pnl: 0n,
      initialCollateral: 0n,
      avgEntryPriceFormatted: "0"
    }

  }, [marketsPnl, position])
  
  if (position.status === PositionStatus.resolved || !addPosition) return <></>
        
  const assetMetadata =  AssetMetadata[position.asset as SupportedAsset]
  const minorSide = market?.minorSide
  const fundingRates = calcFundingRates(
    market.fundingRate[
      isMaker ?
        (minorSide as PositionSide.long | PositionSide.short) :
        (position.nextSide as PositionSide.long | PositionSide.short)
    ]
  )
  const hourlyFunding = Big6Math.abs(fundingRates.hourlyFunding)
  const liquidationPrices = calcLiquidationPrice({
    marketSnapshot: market,
    collateral: position.local.collateral,
    position: position.nextMagnitude,
  })
  const liquidationPrice = position.nextSide === PositionSide.long ? liquidationPrices.long : liquidationPrices.short

  let makerStats = {
    fundingAPR: 0n,
    interestAPR: 0n,
    positionFeeAPR: 0n
  }
  if (isMaker) {
    makerStats = calcMakerStats({
      funding: market7dData?.makerAccumulation.funding ?? 0n,
      interest: market7dData?.makerAccumulation.interest ?? 0n,
      positionFee: market7dData?.makerAccumulation.positionFee ?? 0n,
      positionSize: position.nextMagnitude,
      collateral: position.local.collateral,
    })
  }

  const closeBtnLabel = (positionStatus: string): string => {
    if (positionStatus === PositionStatus.closed) {
      return t("withdraw")
    }
    if (positionStatus === PositionStatus.closing) { 
      return t("closing")
    }
    return t("close")
  }

  const statusColorClass = (status: string) => {
    if (status === PositionStatus.open || status === PositionStatus.opening) {
      return "text-green"
    } else if (status === PositionStatus.closed || status === PositionStatus.closing) {
      return "text-grey"
    }
    return "text-red"
  }

  return (
    <tr key={index.toString()}>
      <td>
        <div className="market">
          <Image className="token-icon margin-right" src={assetMetadata.icon} width={30} height={30} />
          <div className="market-info">
            <h6>
              {capitalize(position.side)}
            </h6>
            {isSmallScreen ? (
              <span className="title">{assetMetadata.name}</span>
            ): (
              <span className="title">{assetMetadata.name}</span>  
            )}
          </div>
        </div>
      </td>
      <td className="right">
        <div className="flex-column">
          <NumericFormat
            className="balance"
            value={formatBig6USDPrice(position.nextNotional)}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            prefix="$"
          />
          <NumericFormat
            className="number small gray"
            value={formatBig6USDPrice(position.nextMagnitude)}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            suffix={" ".concat(assetMetadata.baseCurrency.toUpperCase())}
          />
        </div>
      </td>
      <td className="right">
        <div className="flex-column">
          <NumericFormat
            className="balance"
            value={formatBig6USDPrice(position.local.collateral)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
          <NumericFormat
            className="number small gray"
            value={formatBig6USDPrice(initialCollateral)}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            prefix="$"
          />
        </div>  
      </td>
      <td className="right">        
        <NumericFormat
          className={pnlClassName(parseFloat(formatBig6(pnl)))}
          value={formatBig6USDPrice(pnl)}
          displayType="text"
          thousandSeparator=","
          prefix="$"
          decimalScale={4}
        />
      </td>
      {!isMaker ? (
        <td className="right">
          <NumericFormat
            className="balance"
            value={
              position.status !== PositionStatus.opening ? avgEntryPriceFormatted : formatBig6USDPrice(position.prices[0])
            }
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={assetMetadata.displayDecimals}
          />
        </td>
      ) : (
        <td className="right">
          <NumericFormat
            className="balance"
            value={formatBig6Percent(
              makerStats.fundingAPR + makerStats.interestAPR + makerStats.positionFeeAPR,
              { numDecimals: 4 }
            )}
            displayType="text"
            thousandSeparator=","
            suffix="%"
            decimalScale={4}
          />
        </td>
      )}
      <td className="right">
        <div className="flex-column liquidation-price">          
          <NumericFormat
            className="balance"
            value={formatBig6USDPrice(liquidationPrice)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
          />
        </div>  
      </td>
      <td className="right">        
        <NumericFormat
          className="balance"
          value={formatBig6Percent(hourlyFunding, { numDecimals: 4 })}
          displayType="text"
          thousandSeparator=","
          suffix="%"
          decimalScale={5}
        />
      </td>
      <td className="center col-status">
        <span className={statusColorClass(position.status)}>
          {capitalize(t(position.status))}
        </span>
      </td>
      <td className="right">
        <Button
          variant="outline-primary"
          onClick={() => {
            setSelectedMarket(position.asset)
            setShowUpdatePosition(true)
          }}
        >
          {t("change")}
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            setSelectedMarket(position.asset)
            setShowClosePosition(true)
          }}
        >
          {closeBtnLabel(position.status)}
        </Button>
      </td>
    </tr>
  )
}
