import React, { useMemo } from "react"
import { Dropdown, Image, Row, Stack } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { formatBig6Percent, PerennialVaultType, SupportedAsset, VaultMetadata, VaultSnapshot } from "@perennial/sdk"

import { useVaultContext } from "../../../contexts"
import { useChainId } from "../../../hooks/network"
import { AssetMetadata } from "../../../constants/markets"
import { useVaults7dAccumulations } from "../../../hooks/vaults2"
import { useExposureAndFunding } from "../hooks"


export const VaultSelector = () => {
  const { t } = useTranslation()
  const chainId = useChainId()
  const { setSelectedVault, vaultSnapshots, selectedVault } = useVaultContext()
  
  const selectedVaultSnapshot = useMemo(() => {
    if (vaultSnapshots && selectedVault) {
      return vaultSnapshots?.vault?.[selectedVault]
    }
      
  }, [vaultSnapshots, selectedVault])
  const selectedVaultMetadata = VaultMetadata[chainId]?.[selectedVault as PerennialVaultType]
  const vaultAccumulations = useVaults7dAccumulations()
  const exposureData = useExposureAndFunding ({
    vault: selectedVaultSnapshot,
    accumulations: vaultAccumulations.find((v) => v.data?.vaultAddress === selectedVaultSnapshot?.vault)?.data,
  })
  const selectedVaultApr = (exposureData?.totalFeeAPR || 0n) + (exposureData?.totalFundingAPR || 0n)

  return (
    <Dropdown
      className="vaults-dropdown"
      onSelect={(eventKey) => { setSelectedVault(eventKey as PerennialVaultType) }}
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <div className="dropdown-toggle-container">
          <div className="toggle-section desc-box">
            <div className="vault-assets">
              {selectedVaultSnapshot && selectedVaultSnapshot?.assets.map((asset) => {
                if (selectedVault === PerennialVaultType.alpha && asset.asset === SupportedAsset.btc) {
                  return null
                }
                return <Image className="token-icon margin-right" src={AssetMetadata[asset.asset].icon} height={25} width={25} />
              })}
            </div>
            <div className="market-desc">
              <h6 className="title">
                {selectedVaultMetadata?.name}
              </h6>
              <span className="caption">{t("select-vault")}</span>
            </div>
          </div>
          <div className="vertical-divisor" />
          <div className="toggle-section apr-box">
            <span className="number text-green">{formatBig6Percent(selectedVaultApr)}</span>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <>
          <Row className="dropdown-menu-header">
            <span className="small text-muted">{t("vault")}</span>
            <span className="small text-muted text-right">{t("apr")}</span>
          </Row>    
          {Object.values(vaultSnapshots?.vault ?? {}).map((vaultSnapshot, index) => {
            return (
              <VaultItem key={index.toString()} vaultSnapshot={vaultSnapshot} />
            )
          })}
        </>  
      </Dropdown.Menu>
    </Dropdown>
  )
    
}


const VaultItem = ({ vaultSnapshot } : { vaultSnapshot: VaultSnapshot }) => {
  const chainId = useChainId()
  const { vaultType, vault } = vaultSnapshot
  const metadata = VaultMetadata[chainId]?.[vaultType as PerennialVaultType]
  const vaultAccumulations = useVaults7dAccumulations()
  const exposureData = useExposureAndFunding ({
    vault: vaultSnapshot,
    accumulations: vaultAccumulations.find((v) => v.data?.vaultAddress === vaultSnapshot.vault)?.data,
  })
  const vaultApr = (exposureData?.totalFeeAPR || 0n) + (exposureData?.totalFundingAPR || 0n)

  if (!metadata) return <></>

  return (
    <Dropdown.Item key={vault} eventKey={vaultType}>
      <Stack className="left" direction="horizontal">
        {vaultSnapshot.assets.map((asset) => {
          if (vaultType === PerennialVaultType.alpha && asset.asset === SupportedAsset.btc) {
            return null
          }

          return (
            <Image
              key={"img-".concat(vault)}
              className="token-icon margin-right"
              src={AssetMetadata[asset.asset].icon}
              height={20}
              width={20}
            />
          )
        })}
        <>{metadata.name}</>
      </Stack>
      <Stack className="right">
        <span className="number text-green">
          {formatBig6Percent(vaultApr)}
        </span>
      </Stack>
    </Dropdown.Item>
  )
}