import React, { useEffect, useState } from "react"
import Exchange from "../Exchange"
import { useTranslation } from "react-i18next"
import { MobileTradingViewWidget } from "./MobileTradingViewWidget"
import { PriceChartWrapper } from "../charts"
import { Positions } from "../positions"
import { PLInterfaceToggle, PriceBox } from "../common"
import { CustomTooltip } from "../../../components/common"
import TabsChart from "../../../components/TabsChart"
import {
  useActiveProvider,
  useUserProductSnapshots,
} from "../../hooks";
import { OpenPositionType } from "../../utils/types"


type props = {
  currentInterface: number;
  setCurrentInterface: (value: number) => void;
}

const MobileProvidePro = ({ currentInterface, setCurrentInterface }: props) => {
  const { t } = useTranslation()
  const [tabState, setTabState] = React.useState("oracle");
  const { userAccount } = useActiveProvider();
  const { data: userSnapshots, isLoading } = useUserProductSnapshots(userAccount);
  const [openedPositions, setOpenedPositions] = useState(0);

  useEffect(() => {
    const load = async () => {
      if (userSnapshots) { 
        const addresses = Object.keys(userSnapshots);
        let oPositions = 0;
        for (let i = 0; i < addresses.length; i += 1) {
          const productAddress = addresses[i];
          const userSnapshot = userSnapshots[productAddress];
          const isPositionOpen =
            userSnapshot.collateral > 0n || userSnapshot.positionSize > 0n;
          
          if (isPositionOpen && userSnapshot.positionDirection === OpenPositionType.Maker) {
            oPositions += 1;
          }
        }

        setOpenedPositions(oPositions);
      }
    };
    load();
  },
    // eslint-disable-next-line
    [userSnapshots]
  );

  return(
    <div className="mobile-trade">
      <div className="version-chooser">
        <CustomTooltip
          id="tl-pl-interface"
          msg={t("tooltip.pro-interface")}
        >
          <PLInterfaceToggle currentInterface={currentInterface} setCurrentInterface={setCurrentInterface} />
        </CustomTooltip>  
      </div>
      <PriceBox showMarketCap={true} />
      <TabsChart onTabChange={setTabState} />
      <div className="text-left float-above-bg">
        {tabState === "market" ? <MobileTradingViewWidget /> : <PriceChartWrapper />}
        {openedPositions < 2 && <Exchange isTaker={false} />}
        <Positions
          userSnapshots={userSnapshots}
          isLoadingPositions={isLoading}
          positionType={OpenPositionType.Maker}
        />
      </div>
    </div>
  );
};

export default MobileProvidePro
