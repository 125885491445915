import { Big6Math } from "@perennial/sdk"

export const formIds = {
  collateral: 'collateral-input',
  amount: 'amount-input',
  leverage: 'leverage-input',
  closeAmount: 'close-amount-input',
  receiveInput: 'receive-input',
  withdrawAmount: 'withdraw-amount-input',
}

export const buttonPercentValues = [10, 20, 50, 75, 100]

export enum FormNames {
  collateral = 'collateral',
  amount = 'amount',
  leverage = 'leverage',
  limitPrice = 'limitPrice',
  limitPricePercent = 'limitPricePercent',
  stopLoss = 'stopLoss',
  takeProfit = 'takeProfit',
  triggerAmount = 'triggerAmount',
  notionalAmount = 'notionalAmount',
  triggerComparison = 'triggerComparison',
}

export type TradeFormValues = {
  amount: string
  collateral: string
  leverage: string
  limitPrice: string
  limitPricePercent: string
  stopLoss: string
  takeProfit: string
  notionalAmount: string
}

export type OrderValues = {
  collateral: string
  amount: string
  fullClose?: boolean
  crossCollateral?: bigint
  limitPrice?: string
  stopLoss?: string
  takeProfit?: string
  triggerAmount?: string
}

export enum OrderTypes {
  market = 'market',
  limit = 'limit',
  stopLoss = 'stopLoss',
  takeProfit = 'takeProfit',
}

export const OrderTypesLabels = {
  [OrderTypes.market]: 'Market',
  [OrderTypes.limit]: 'Limit',
  [OrderTypes.stopLoss]: 'Stop Loss',
  [OrderTypes.takeProfit]: 'Take Profit',
  'limitOpen': 'Limit Open',
  'limitClose': 'Limit Close',
}


export const orderTypes = [OrderTypes.market, OrderTypes.limit, OrderTypes.stopLoss, OrderTypes.takeProfit]
export const triggerOrderTypes = [OrderTypes.stopLoss, OrderTypes.takeProfit]


/// temporal
export const OrderExecutionDeposit = Big6Math.fromFloatString('20')
