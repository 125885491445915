import * as contracts from "./contracts";

export const CHAINS = {
  ARBITRUM: 42161,
  ARBITRUM_GOERLI: 421613
};

export const ARBITRUM_GRAPHQL_URL = "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/cryptex-arbitrum/version/successive_scarlet_chipmunk/api";

export const ERROR_USER_REJECTED_TRAN = "UserRejectedRequestError";

export enum ApproveType {
  DEPOSIT,
  WITHDRAW,
  CLAIM,
  STAKE,
}

export const TOKENS_SYMBOLS = {
  arb: "arb",
  ether: "eth",
  tcap: "tcap",
  perpe: "pepe",
};

export const WIKI_TCAP_URL = "https://cryptexfinance.notion.site/TCAP-Total-Crypto-Market-Cap-b87f0d5331a24b0ba6796f579fe531af";

export const API_BASE_URL = {
  [CHAINS.ARBITRUM]: {
    url: "https://rebate-program.herokuapp.com/"
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    url: "https://rebate-program.herokuapp.com/"
  }
};

export const USDC_TOKEN = {
  key: "usdc",
  symbol: "USDC",
  description: "USDC",
  displayDecimals: 4,
  multiplier: 1n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.usdcOracleContract,
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    oracleContract: contracts.usdcOracleContract,
  }
};

export const ARB_TOKEN = {
  key: TOKENS_SYMBOLS.arb,
  symbol: TOKENS_SYMBOLS.arb.toUpperCase(),
  description: "ARB / Arbitrum Perpetual Marlet",
  displayDecimals: 4,
  multiplier: 10000000000n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.ethOracleContract,
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    oracleContract: contracts.ethOracleContract,
  }
};


export const ETH_TOKEN = {
  key: TOKENS_SYMBOLS.ether,
  symbol: TOKENS_SYMBOLS.ether.toUpperCase(),
  description: "ETHER / Ethereum Perpetual Market",
  displayDecimals: 4,
  multiplier: 10000000000n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.ethOracleContract,
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    oracleContract: contracts.ethOracleContract,
  }
};

export const PERPE_TOKEN = {
  key: TOKENS_SYMBOLS.perpe,
  symbol: TOKENS_SYMBOLS.perpe.toUpperCase(),
  description: "PERPE / PEPE Perpetual Market",
  displayDecimals: 10,
  multiplier: 1n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.pepeOracleContract,
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    oracleContract: contracts.pepeOracleContract,
  }
};

export const TCAP_TOKEN = {
  key: TOKENS_SYMBOLS.tcap,
  symbol: TOKENS_SYMBOLS.tcap.toUpperCase(),
  description: "TCAP / Total Crypto Perpetual Market",
  displayDecimals: 4,
  multiplier: 1n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.tcapOracleContract,
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    oracleContract: contracts.tcapOracleContract,
  }
};

// Markets
export const ARB_MARKET = {
  market: ARB_TOKEN,
  collateral: USDC_TOKEN,
  longContract: contracts.arbLongMarketContract,
  shortContract: contracts.arbShortMarketContract,
  collateralContract: contracts.productCollateralContract,
  vaultContract: contracts.arbVaultContract,
};

export const ETH_MARKET = {
  market: ETH_TOKEN,
  collateral: USDC_TOKEN,
  longContract: contracts.ethLongMarketContract,
  shortContract: contracts.ethShortMarketContract,
  collateralContract: contracts.productCollateralContract,
  vaultContract: contracts.ethVaultContract,
};

export const PERPE_MARKET = {
  market: PERPE_TOKEN,
  collateral: USDC_TOKEN,
  longContract: contracts.perpeLongMarketContract,
  shortContract: contracts.perpeShortMarketContract,
  collateralContract: contracts.productCollateralContract,
  vaultContract: contracts.perpeVaultContract,
};

export const TCAP_MARKET = {
  market: TCAP_TOKEN,
  collateral: USDC_TOKEN,
  longContract: contracts.tcapLongMarketContract,
  shortContract: contracts.tcapShortMarketContract,
  collateralContract: contracts.productCollateralContract,
  vaultContract: contracts.tcapVaultContract,
};


export const MARKETS = {
  [TOKENS_SYMBOLS.tcap]: TCAP_MARKET,
}

export const PRODUCTS_ADDRESS = {
  [CHAINS.ARBITRUM]: [
    TCAP_MARKET.shortContract[CHAINS.ARBITRUM].address,
    TCAP_MARKET.longContract[CHAINS.ARBITRUM].address,
    /* PERPE_MARKET.shortContract[CHAINS.ARBITRUM].address,
    PERPE_MARKET.longContract[CHAINS.ARBITRUM].address,
    ETH_MARKET.shortContract[CHAINS.ARBITRUM].address,
    ETH_MARKET.longContract[CHAINS.ARBITRUM].address,
    ARB_MARKET.shortContract[CHAINS.ARBITRUM].address,
    ARB_MARKET.longContract[CHAINS.ARBITRUM].address, */
  ],
  [CHAINS.ARBITRUM_GOERLI]: [
    TCAP_MARKET.shortContract[CHAINS.ARBITRUM_GOERLI].address,
    TCAP_MARKET.longContract[CHAINS.ARBITRUM_GOERLI].address,
    /* PERPE_MARKET.shortContract[CHAINS.ARBITRUM_GOERLI].address,
    PERPE_MARKET.longContract[CHAINS.ARBITRUM_GOERLI].address,
    ETH_MARKET.shortContract[CHAINS.ARBITRUM_GOERLI].address,
    ETH_MARKET.longContract[CHAINS.ARBITRUM_GOERLI].address,
    ARB_MARKET.shortContract[CHAINS.ARBITRUM_GOERLI].address,
    ARB_MARKET.longContract[CHAINS.ARBITRUM_GOERLI].address, */
  ],
}

export const aggregatorContracts = {
  [TOKENS_SYMBOLS.arb]: {
    address: "0x46de66F10343b59BAcc37dF9b3F67cD0CcC121A3",
  },
  [TOKENS_SYMBOLS.ether]: {
    address: "0x3607e46698d218B3a5Cae44bF381475C0a5e2ca7",
  },
  [TOKENS_SYMBOLS.tcap]: {
    address: "0x7B9845A634822c543F5Ce544Dd7D7797B79a06b8"
  },
  [TOKENS_SYMBOLS.perpe]: {
    address: "0x67db13c76Ce77E8FEef9B500616162eC142597ac",
  },
};

export const ACTION_TYPES = {
  deposit: "DEPOSIT",
  liquidated: "LIQUIDATED",
  makeClosed: "MAKE_CLOSED",
  makeOpened: "MAKE_OPENED",
  takeClosed: "TAKE_CLOSED",
  takeOpened: "TAKE_OPENED",
  withdraw: "WITHDRAW",
}

export const POSITION_STATUS = {
  closed: "closed",
  closing: "closing",
  liquidated: "liquidated",
  liquidable: "liquidable",
  liquidating: "liquidating",
  open: "open",
}
