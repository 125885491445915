import { AssetMetadata as SDKAssetMetadata, AssetMetadataType as sdkAssetMetadataType, SupportedAsset } from '@perennial/sdk';

import arbLogo from '../assets/icons/arb.png'
import bnbLogo from '../assets/icons/bnb.png'
import btcLogo from '../assets/icons/btc.png'
import celestiaLogo from '../assets/icons/celestia.png'
import linkLogo from '../assets/icons/chainlink.png'
import ethLogo from '../assets/icons/eth.png'
import jupLogo from '../assets/icons/jup.png'
import msqBtcLogo from '../assets/icons/msqBtc.png'
import msqEthLogo from '../assets/icons/msqEth.png'
import polygonLogo from '../assets/icons/polygon.png'
import solanaLogo from '../assets/icons/solana.png'
import usdcLogo from '../assets/icons/usdc.png'
import xrpLogo from '../assets/icons/xrp.png'

export enum RewardAsset {
  usdc = 'usdc',
  arb = 'arb',
}

export const RewardAssetData: {
  [asset in RewardAsset]: { logo: string; name: string; symbol: string; pythFeedId: string }
} = {
  [RewardAsset.usdc]: {
    logo: usdcLogo,
    name: 'USDC',
    symbol: 'USDC',
    pythFeedId: '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a',
  },
  [RewardAsset.arb]: {
    logo: arbLogo,
    name: 'Arbitrum',
    symbol: 'ARB',
    pythFeedId: '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
  },
}

export type AssetMetadataType = {
  [asset in SupportedAsset]: {
    tvPythSymbol: string;
    icon: string;
  } & sdkAssetMetadataType[SupportedAsset];
};

export const AssetMetadata: AssetMetadataType = {
  [SupportedAsset.btc]: {
    icon: btcLogo,
    tvPythSymbol: 'PYTH:BTCUSD',
    ...SDKAssetMetadata[SupportedAsset.btc]
  },
  [SupportedAsset.eth]: {
    icon: ethLogo,
    tvPythSymbol: 'PYTH:ETHUSD',
    ...SDKAssetMetadata[SupportedAsset.eth]
  },
  [SupportedAsset.arb]: {
    icon: arbLogo,
    tvPythSymbol: 'PYTH:ARBUSD',
    ...SDKAssetMetadata[SupportedAsset.arb]
  },
  [SupportedAsset.link]: {
    icon: linkLogo,
    tvPythSymbol: 'PYTH:LINKUSD',    
    ...SDKAssetMetadata[SupportedAsset.link]
  },
  [SupportedAsset.sol]: {
    icon: solanaLogo,
    tvPythSymbol: 'PYTH:SOLUSD',
    ...SDKAssetMetadata[SupportedAsset.sol]
  },
  [SupportedAsset.matic]: {
    icon: polygonLogo,
    tvPythSymbol: 'PYTH:MATICUSD',
    ...SDKAssetMetadata[SupportedAsset.matic]
  },
  [SupportedAsset.tia]: {
    icon: celestiaLogo,
    tvPythSymbol: 'PYTH:TIAUSD',
    ...SDKAssetMetadata[SupportedAsset.tia]
  },
  [SupportedAsset.bnb]: {
    icon: bnbLogo,
    tvPythSymbol: 'PYTH:BNBUSD',
    ...SDKAssetMetadata[SupportedAsset.bnb]
  },
  [SupportedAsset.xrp]: {
    icon: xrpLogo,
    tvPythSymbol: 'PYTH:XRPUSD',
    ...SDKAssetMetadata[SupportedAsset.xrp]
  },
  [SupportedAsset.msqBTC]: {
    icon: msqBtcLogo,
    tvPythSymbol: 'PYTH:BTCUSD',
    ...SDKAssetMetadata[SupportedAsset.msqBTC]
  },
  [SupportedAsset.cmsqETH]: {
    icon: msqEthLogo,
    tvPythSymbol: 'PYTH:ETHUSD',
    ...SDKAssetMetadata[SupportedAsset.cmsqETH]
  },
  [SupportedAsset.jup]: {
    icon: jupLogo,
    tvPythSymbol: 'PYTH:JUPUSD',
    ...SDKAssetMetadata[SupportedAsset.jup]
  },
  [SupportedAsset.rlb]: {
    icon: jupLogo,
    tvPythSymbol: 'PYTH:JUPUSD',
    ...SDKAssetMetadata[SupportedAsset.rlb]
  }
}
