import { useWriteContract } from "wagmi"
import { buildInvokerAction, InvokerAction } from "../helpers"
import { multiInvokerContract } from "../utils/contracts"
import { getAddress } from "viem";


export const useWithdrawOrClosePosition = (
  chainId: number,
  userAddress: string,
  isTaker: boolean,
  onSettled: (isSucces: boolean, error: any, tranHash: string) => void,
) => {
  const { writeContract } = useWriteContract();

  return {
    onWithdraw: (productAddress: string, amount: bigint, collateral: bigint) => {
      const argsArray = new Array<{ action: number, args: string }>();
      const params = { userAddress, productAddress, position: amount, collateral };
      const invokerArgs = buildInvokerAction(InvokerAction.WITHDRAW_AND_UNWRAP, params)
      argsArray.push(invokerArgs)

      writeContract(
        {
          address: getAddress(multiInvokerContract[chainId].address),
          abi: multiInvokerContract.abi,
          functionName: "invoke",
          args: [argsArray],
        },
        {
          onSuccess(data){
            onSettled(true, undefined, data)
          },
          onError(error) {
            console.log("Withdraw: ", error)
            onSettled(false, error, "")
          },
        }
      )
    },
    onClose: (productAddress: string, amount: bigint, collateral: bigint) => {
      const argsArray = new Array<{ action: number, args: string }>();
      const params = { userAddress, productAddress, position: amount, collateral };
      const closeAction = isTaker ? InvokerAction.CLOSE_TAKE : InvokerAction.CLOSE_MAKE;
      const invokerArgs = buildInvokerAction(closeAction, params)
      argsArray.push(invokerArgs)

      writeContract(
        {
          address: getAddress(multiInvokerContract[chainId].address),
          abi: multiInvokerContract.abi,
          functionName: "invoke",
          args: [argsArray],
        },
        {
          onSuccess(data){
            onSettled(true, undefined, data)
          },
          onError(error) {
            console.log("CLose: ", error)
            onSettled(false, error, "")
          },
        }
      )
    }
  }
};
