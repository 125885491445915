import React, { useMemo, useState } from "react";
import { Button, Image, Spinner } from "react-bootstrap/esm";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { useEnsName, useSignMessage } from "wagmi";
import GiftPic from "../../assets/gift.png";
import { useActiveProvider } from "../../v1/hooks"
import { API_BASE_URL } from "../../v1/utils/constants";
import { capitalize, makeShortAddress } from "../../v1/utils/utils";
import { errorNotification, notifyUser } from "../common/notifications";

type props = {
  referrerUuid: string;
}

export const AcceptReferral = ({ referrerUuid }: props) => {
  const messageToSign = process.env.REACT_APP_REFERRAL_SIGNATURE_MESSAGE || "";
  const { chainId, userAccount, isConnected } = useActiveProvider();
  const [acceptingReferral, setAcceptingReferral] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const { data: ensData } = useEnsName({
    // @ts-ignore
    address: userAccount,
    chainId: 1,
  });

  const { ensName } = useMemo(() => {
    let ensName = makeShortAddress(userAccount);

    if (ensData) {
      ensName = ensData
    }

    return {
      ensName
    }
  }, [ensData, userAccount])

  const acceptReferralUUID = (signedMessage: string) => {
    const url = API_BASE_URL[chainId].url.concat("referral/create/");

    axios.post(url, {
      wallet: userAccount,
      signed_message: signedMessage,
      referrer_uuid: referrerUuid,
    })
      .then((response: any) => {
        if (response.data.success) {
          notifyUser("Referral has been accepted.", "Signature verified!");
        } else {
          errorNotification("Unexpected error.");
        }
        setAcceptingReferral(false);
        setShowBanner(false);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response &&
            (error.response.status === 400 || error.response.status === 401 || error.response.status === 406)) {
          errorNotification(capitalize(error.response.data.error));
        } else {
          errorNotification("Unexpected error.");
        }
        setAcceptingReferral(false);
      });
  };

  const { status, signMessage } = useSignMessage()

  
  const onSignMessageClick = () => {
    signMessage({ message: messageToSign }, {
      onSuccess(data: any) {
        acceptReferralUUID(data);
      },
      onError(error: any) {
        console.log("Error signing ", error);
        errorNotification("Error trying to sign message");
        setAcceptingReferral(false);
      },
    });
  }

  if (!showBanner) {
    return <></>;
  }

  return (
    <div className="referral-accept">
      <div className="btn-close-container">
        <Button className="btn-close2" onClick={() => setShowBanner(false)}>
          <AiOutlineClose size={20} />
        </Button>
      </div>  
      <h2>Welcome, {ensName}</h2>
      <div className="info-container">
        <div className="info-box">
          <div className="img-container">
            <Image src={GiftPic} />
          </div>
          <p>
            {isConnected
              ? "Accept invite to earn additional reward when trading."
              : "Connect your wallet to accept referral."
            }
          </p>
        </div>    
      </div>
      {isConnected && (
        <div className="btn-container">
          <Button
            className="btn-get-reflink"
            onClick={() => {
              setAcceptingReferral(true);
              onSignMessageClick();
            }}
            disabled={status === "pending" || acceptingReferral}
          >
            <div className="btn-spinner">
              {status === "pending" && <Spinner animation="border" variant="secondary" className="small" />}
              {status === "pending" ? "Accepting Referral" : "Accept Referral"}
            </div>
          </Button>
        </div>
      )}
    </div>    
  );   
};
