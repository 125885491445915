import { chainVaultsWithAddress } from '@perennial/sdk'
import { useQueries } from '@tanstack/react-query'
import { useBlockNumber } from 'wagmi'

import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'

import { useChainId } from '../network'
import { useVaultSnapshots2 } from './chain'

export type VaultAccumulations = NonNullable<Awaited<ReturnType<typeof useVaults7dAccumulations>>[number]['data']>
export const useVaults7dAccumulations = () => {
  const chainId = useChainId()
  const { data: vaultSnapshots, isLoading: vaultSnapshotsLoading } = useVaultSnapshots2()
  const vaults = chainVaultsWithAddress(chainId)
  const { data: blockNumber } = useBlockNumber()
  const sdk = usePerennialSDKContext()

  return useQueries({
    queries: vaults.map((vault) => {
      return {
        queryKey: ['vaults7dAccumulations', chainId, vault.vaultAddress],
        enabled: !vaultSnapshotsLoading && !!vaultSnapshots?.vault?.[vault.vault] && !!blockNumber,
        queryFn: async () => {
          const vaultSnapshot = vaultSnapshots?.vault[vault.vault]
          if (!vaultSnapshot || !blockNumber) return

          const vault7dAccumulations = await sdk.vaults.read.vault7dAccumulations({
            vaultAddress: vault.vaultAddress,
            vaultSnapshot,
            latestBlockNumber: blockNumber,
          })

          return vault7dAccumulations
        },
      }
    }),
  })
}