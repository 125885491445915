import {
  AssetMetadata,
  Big6Math,
  BigOrZero,
  KeeperOracleAbi,
  PriceUpdate,
  SupportedAsset,
  chainAssetsWithAddress,
  last24hrBounds,
  pythPriceToBig6,
  unique,
} from '@perennial/sdk'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useChainId, usePythSubscription, useViemWsClient } from '../network'
import { useMarketOracles2 } from './chain'
import { getAddress } from "viem";
import { PythDataFeedUrl } from '../../constants/network'


export const useMarket24HrHighLow = (asset: SupportedAsset) => {
  const metadata = AssetMetadata[asset]

  return useQuery({
    queryKey: ['market24HrHighLow', asset],
    enabled: !!metadata,
    queryFn: async () => {
      if (!metadata) return

      const { tvTicker, transform } = metadata
      const { from, to } = last24hrBounds()
      const request = await fetch(`${PythDataFeedUrl}/history?symbol=${tvTicker}&resolution=D&from=${from}&to=${to}`)
      const prices = (await request.json()) as { h: number[]; l: number[]; o: number[] }

      return {
        open: transform(Big6Math.fromFloatString(prices.o[0].toString())),
        high: transform(Big6Math.fromFloatString(Math.max(...prices.h).toString())),
        low: transform(Big6Math.fromFloatString(Math.min(...prices.l).toString())),
      }
    },
  })
}

export const useChainLivePrices2 = () => {
  const chain = useChainId()
  const markets = chainAssetsWithAddress(chain)
  const [prices, setPrices] = useState<{ [key in SupportedAsset]?: { price: bigint; untransformed: bigint } }>({})

  const [feedIds, feedToAsset] = useMemo(() => {
    const feedToAsset = markets.reduce((acc, { asset }) => {
      const feed = AssetMetadata[asset].pythFeedId
      if (!feed) return acc
      if (acc[feed]) {
        acc[feed].push(asset)
      } else {
        acc[feed] = [asset]
      }
      return acc
    }, {} as { [key: string]: SupportedAsset[] })

    const feedIds = Object.keys(feedToAsset)

    return [feedIds, feedToAsset]
  }, [markets])

  const feedSubscription = usePythSubscription(feedIds)
  const onUpdate = useCallback(
    (priceFeed: PriceUpdate) => {
      const parsedData = priceFeed.parsed
      if (!parsedData) return
      parsedData.forEach((data) => {
        const price = data.price
        const normalizedPrice = pythPriceToBig6(BigOrZero(price?.price), price?.expo ?? 0)
        setPrices((prices) => ({
          ...prices,
          ...feedToAsset['0x' + data.id].reduce((acc, asset) => {
            const { transform } = AssetMetadata[asset]
            // Pyth price is has `expo` (negative number) decimals, normalize to expected 18 decimals by multiplying by 10^(18 + expo)
            acc[asset] = price ? { price: transform(normalizedPrice), untransformed: normalizedPrice } : undefined
            return acc
          }, {} as { [key in SupportedAsset]?: { price: bigint; untransformed: bigint } }),
        }))
      })
    },
    [feedToAsset],
  )

  useEffect(() => {
    feedSubscription.on('updates', onUpdate)

    return () => {
      feedSubscription.off('updates', onUpdate)
    }
  }, [feedSubscription, onUpdate])

  return prices
}

export type LivePrices = Awaited<ReturnType<typeof useChainLivePrices2>>

const RefreshKeys = ['marketSnapshots2']
export const useRefreshKeysOnPriceUpdates2 = (invalidKeys: string[] = RefreshKeys) => {
  const chainId = useChainId()
  const queryClient = useQueryClient()
  const { data: marketOracles } = useMarketOracles2()
  const wsClient = useViemWsClient()

  const refresh = useCallback(
    async (asset?: SupportedAsset) => {
      // Wait a random amount of time between 0 and 5s to prevent rate limiting
      await new Promise((resolve) => setTimeout(resolve, 5000))
      await queryClient.invalidateQueries({
        predicate: ({ queryKey }) => invalidKeys.includes(queryKey.at(0) as string) && queryKey.includes(chainId),
      })
      if (asset) {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) =>
            (queryKey.at(0) as string) === 'marketPnls2' && queryKey.includes(chainId) && queryKey.includes(asset),
        })
      }
    },
    [invalidKeys, queryClient, chainId],
  )

  const oracleProviders = useMemo(() => {
    if (!marketOracles) return []
    return unique(Object.values(marketOracles).flatMap((p) => p.providerAddress))
  }, [marketOracles])

  useEffect(() => {
    if (!oracleProviders.length || !marketOracles) return
    const unwatchFns = oracleProviders.map((a) => {
      return [
        wsClient.watchContractEvent({
          address: a,
          abi: KeeperOracleAbi,
          eventName: 'OracleProviderVersionFulfilled',
          onLogs: (logs) => {
            const logAddresses = unique(logs.map((log) => getAddress(log.address)))
            Object.values(marketOracles)
              ?.filter((o) => logAddresses.includes(o.providerAddress))
              .forEach((o) => refresh(o.asset))
          },
        }),
      ]
    })
    return () => unwatchFns.flat().forEach((unwatch) => unwatch())
  }, [oracleProviders, refresh, wsClient, marketOracles])
}

export * from './chain'
export * from './tx'
