// TradingViewWidget.jsx

import React, { useContext, useEffect, useRef } from "react";
import { productContext } from "../../states";
import { TOKENS_SYMBOLS } from "../../utils/constants";


// @ts-ignore
let tvScriptLoadingPromise;

export const MobileTradingViewWidget = () => {
  const onLoadScriptRef = useRef();
  const currentProduct = useContext(productContext);

  const widgetSymbols = {
    [TOKENS_SYMBOLS.arb]: "COINBASE:ARBUSD",
    [TOKENS_SYMBOLS.ether]: "COINBASE:ETHUSD",
    [TOKENS_SYMBOLS.perpe]: "UNISWAP3ETH:PEPEUSDC_CEE31C.USD",
    [TOKENS_SYMBOLS.tcap]: "CRYPTOCAP:TOTAL",
  }

  useEffect(
    () => {
      const load = () => {
        // @ts-ignore
        onLoadScriptRef.current = createWidget;
        // @ts-ignore
        if (!tvScriptLoadingPromise) {
          tvScriptLoadingPromise = new Promise((resolve) => {
            const script = document.createElement("script");
            script.id = "tradingview-widget-loading-script";
            script.src = "https://s3.tradingview.com/tv.js";
            script.type = "text/javascript";
            script.onload = resolve;

            document.head.appendChild(script);
          });
        }
        // @ts-ignore
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

        // @ts-ignore
        return () => onLoadScriptRef.current = null;

        function createWidget() {
          if (document.getElementById("tradingview_14a8d") && "TradingView" in window) {
            // @ts-ignore
            new window.TradingView.widget({
              autosize: true,
              symbol: widgetSymbols[currentProduct.market.key],
              interval: "1",
              timezone: "Etc/UTC",
              theme: "dark",
              style: "1",
              locale: "en",
              toolbar_bg: "#f1f3f6",
              enable_publishing: false,
              allow_symbol_change: true,
              container_id: "tradingview_14a8d"
            });
          }
        }
      }
      load();
    },
    // eslint-disable-next-line
    [currentProduct.market]
  );

  return (
    <><div className="tradingview-widget-container-mobile">
      <div id="tradingview_14a8d" />
    </div></>
  );
}
