import { chainIdToChainMap } from '@perennial/sdk'
import { GetContractReturnType, WalletClient, createPublicClient, getContract, http } from 'viem'
import { usePerennialSDKContext } from '../contexts/perennialSdkContext'

import { SeasonalMerkleTreeAddresses } from '../constants/contracts'
import { useChainId, useRPCProviderUrl } from '../hooks/network'

import { SeasonMerkleClaim } from '../abi/SeasonalMerkleClaim.abi'


export const useDSU = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getDSUContract()
}

export const useUSDC = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getUSDCContract()
}

export const useMultiInvoker2 = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getMultiInvokerContract()
}

export const useMarketFactory = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getMarketFactoryContract()
}

export const useVaultFactory = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getVaultFactoryContract()
}

export const usePythFactory = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getPythFactoryContract()
}

export const useSeasonalMerkleClaim = (signer?: WalletClient) => {
  const chainId = useChainId()
  const rpcUrl = useRPCProviderUrl()
  const publicClient = createPublicClient({ chain: chainIdToChainMap[chainId], transport: http(rpcUrl) })

  const contract = getContract({
    address: SeasonalMerkleTreeAddresses[chainId],
    abi: SeasonMerkleClaim,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })

  return signer
    ? (contract as GetContractReturnType<
        typeof SeasonMerkleClaim,
        typeof signer,
        (typeof SeasonalMerkleTreeAddresses)[typeof chainId]
      >)
    : (contract as GetContractReturnType<
        typeof SeasonMerkleClaim,
        typeof publicClient,
        (typeof SeasonalMerkleTreeAddresses)[typeof chainId]
      >)
}
