import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Big6Math, efficiency, formatBig6USDPrice, PositionSide, TriggerComparison } from '@perennial/sdk'

import { useMarketContext } from '../../contexts'
import { useLivePriceContext } from '../../contexts/livePriceContext'


export function useCollateralValidators({
  usdcBalance,
  requiredMaintenance,
  currentCollateral,
  minMargin,
}: {
  usdcBalance: bigint
  requiredMaintenance: bigint
  currentCollateral: bigint
  minMargin: bigint
}) {
  const { t } = useTranslation()

  const maxValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      const balance = Big6Math.fromDecimals(usdcBalance, 6)
      if (Big6Math.max(0n, inputValue - currentCollateral) > balance) {
        return { isValid: false, error: t("error.no-usdc") }
      }
      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [usdcBalance, currentCollateral]
  )

  const minValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)

      if (inputValue < requiredMaintenance) {
        const reqMaintenance = formatBig6USDPrice(requiredMaintenance, { compact: true })
        return { 
          isValid: false,
          error: t("error.below-maintenance", { value: reqMaintenance  }),
        }
      }

      if (inputValue && minMargin && inputValue < minMargin * 2n) {
        const minMaintenanceReq = formatBig6USDPrice(minMargin * 2n, { compact: true })

        return {
          isValid: false,
          error: t("error.min-collateral", { value: minMaintenanceReq  })
        }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [requiredMaintenance, minMargin]
  )

  return {
    max: maxValidator,
    min: minValidator,
  }
}

export function usePositionValidators({
  isMaker,
  taker,
  takerLiquidity,
  makerLiquidity,
  maker,
  major,
  currentPositionAmount,
  makerLimit,
  efficiencyLimit,
  marketClosed,
  isSocialized,
}: {
  isMaker: boolean
  takerLiquidity: bigint
  makerLiquidity: bigint
  taker: bigint
  maker: bigint
  major: bigint
  currentPositionAmount: bigint
  makerLimit: bigint
  efficiencyLimit: bigint
  marketClosed: boolean
  isSocialized: boolean
}) {
  const { t } = useTranslation()
 
  const maxValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      const positionDelta = inputValue - currentPositionAmount
      // Disallow increasing position if liquidity is insufficient
      if (positionDelta > 0n && positionDelta + taker > takerLiquidity) {
        return { isValid: false, error: t("error.exceeds-liquidity") }
      }

      // Disallow increasing position if market is closed
      if (marketClosed && positionDelta > 0n) {
        return { isValid: false, error: t("error.close-only-mode") }
      }

      // Disallow increasing position if socialized
      if (isSocialized && positionDelta > 0n) {
        return { isValid: false, error: t("error.disallow-increasing") }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [currentPositionAmount, taker, takerLiquidity, marketClosed, isSocialized]
  )

  const maxMakerValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      const positionDelta = inputValue - currentPositionAmount
      // Disallow increasing position if exceeds maker limit
      if (positionDelta > 0n && maker + positionDelta > makerLimit) {
        return { isValid: false, error: t("error.exceeds-maker") }
      }

      // Disallow increasing position if market is closed
      if (marketClosed && positionDelta > 0n) {
        return { isValid: false, error: t("error.close-only-mode") }
      }
      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [makerLimit, maker, currentPositionAmount, marketClosed]
  )

  const minMakerValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      const positionDelta = inputValue - currentPositionAmount

      if (marketClosed) return { isValid: true } // socialization does not apply in closed markets

      // Disallow decreasing position if liquidity is insufficient
      if (inputValue < 0n && makerLiquidity - inputValue < major) {
        return { isValid: false, error: t("error.below-minimum-maker") }
      }

      // Disallow decreasing position if below minimum efficiency
      if (positionDelta < 0n && efficiency(maker + positionDelta, major) < efficiencyLimit) {
        return { isValid: false, error: t("error.below-minimum-maker") }
      }
      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [makerLiquidity, maker, major, marketClosed, efficiencyLimit, currentPositionAmount]
  )

  return {
    max: isMaker ? maxMakerValidator : maxValidator,
    ...(isMaker ? { min: minMakerValidator } : {}),
  }
}

export function useLeverageValidators({ maxLeverage }: { maxLeverage: number }) {
  const { t } = useTranslation()

  const maxValidator = useMemo(() => {
    return (value: string) => {
      if (Number(value) > maxLeverage) {
        return { isValid: false, error: t("error.exceeds-leverage") }
      }
      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [maxLeverage]
  )

  return {
    max: maxValidator
  }
}

export function useCloseAmountValidator({
  currentPositionAmount,
  isMaker,
  liquidity,
  maker,
  major,
  marketClosed,
  efficiencyLimit,
}: {
  currentPositionAmount: bigint
  isMaker: boolean
  liquidity: bigint
  maker: bigint
  major: bigint
  marketClosed: boolean
  efficiencyLimit: bigint
}) {
  const { t } = useTranslation()

  const maxValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      if (inputValue > currentPositionAmount) {
        return { isValid: false, error: t("error.exceeds-amount") }
      }
      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [currentPositionAmount]
  )

  const maxMakerValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)

      if (inputValue > currentPositionAmount) {
        return { isValid: false, error: t("error.exceeds-amount") }
      }

      if (marketClosed) return { isValid: true } // socialization does not apply in closed markets

      // Disallow decreasing position if liquidity is insufficient
      if (inputValue !== 0n && liquidity - inputValue < major) {
        return { isValid: false, error: t("error.below-minimum-maker") }
      }

      // Disallow decreasing position if below minimum efficiency
      if (inputValue !== 0n && efficiency(maker - inputValue, major) < efficiencyLimit) {
        return { isValid: false, error: t("error.below-minimum-maker") }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [currentPositionAmount, marketClosed, liquidity, major, maker, efficiencyLimit]
  )

  return { max: isMaker ? maxMakerValidator : maxValidator }
}

export const useLimitPriceValidators = ({
  selectedLimitComparison,
  currentPrice
}: { selectedLimitComparison: TriggerComparison, currentPrice: number }) => {
  const { t } = useTranslation()

  const minValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      if (inputValue <= 0n) {
        return { isValid: false, error: t("error.must-be-positive", { control: t("trigger-price") }) }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    []
  )

  const comparisonValidator = useMemo(() => {
    return (value: string) => {
      const limitPrice = parseFloat(value)
      const willExecuteAsMarketOrder =
              selectedLimitComparison === TriggerComparison.lte
                ? currentPrice * 1.01 <= limitPrice
                : currentPrice * 0.99 >= limitPrice
      
      if (willExecuteAsMarketOrder) {
        return { isValid: false, error: t("error.execute-at-market-price") }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [selectedLimitComparison, currentPrice]
  )

  return {
    min: minValidator,
    comparison: comparisonValidator
  }
}

export const useStopLossValidator = ({
  orderDirection,
  latestPrice,
  isLimit,
  limitPrice = 0n,
  liquidationPrice,
}: {
  orderDirection: PositionSide.long | PositionSide.short
  latestPrice: bigint
  isLimit: boolean
  limitPrice?: bigint
  liquidationPrice: bigint
}) => {
  const { t } = useTranslation()
  const livePrices = useLivePriceContext()
  const { selectedMarket } = useMarketContext()
  const indexPrice = livePrices[selectedMarket]?.price ?? latestPrice
  const price = isLimit ? limitPrice : indexPrice

  const minValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)

      if (orderDirection === PositionSide.long && inputValue > price) {
        if (isLimit) {
          return { isValid: false, error: t("error.price-above-limit", { orderType: "Stop" }) }
        }
        return { isValid: false, error: t("error.price-above-index", { orderType: "Stop" }) }
      }

      if (orderDirection === PositionSide.short && inputValue < price && inputValue !== 0n) {
        if (isLimit) {
          return { isValid: false, error: t("error.price-below-limit", { orderType: "Stop" }) }
        }
        return { isValid: false, error: t("error.price-below-index", { orderType: "Stop" }) }
      }

      if (orderDirection === PositionSide.long && inputValue < liquidationPrice) {
        return { isValid: false, error: t("error.price-below-liquidation", { orderType: "Stop" }) }
      }

      if (orderDirection === PositionSide.short && inputValue > liquidationPrice) {
        return { isValid: false, error: t("error.price-above-liquidation", { orderType: "Stop" }) }
      }

      if (inputValue < 0n) {
        return { isValid: false, error: t("error.must-be-greater-than", { value: "zero" }) }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [orderDirection, price, liquidationPrice, isLimit]
  )

  return {
    min: minValidator,
  }
}

export const useTakeProfitValidators = ({
  orderDirection,
  latestPrice,
  isLimit,
  limitPrice = 0n,
}: {
  orderDirection: PositionSide.long | PositionSide.short
  latestPrice: bigint
  isLimit: boolean
  limitPrice?: bigint
}) => {
  const { t } = useTranslation()
  const livePrices = useLivePriceContext()
  const { selectedMarket } = useMarketContext()
  const indexPrice = livePrices[selectedMarket]?.price ?? latestPrice
  const price = isLimit ? limitPrice : indexPrice

  const minValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)

      if (orderDirection === PositionSide.short && inputValue > price) {
        if (isLimit) {
          return { isValid: false, error: t("error.price-above-limit", { orderType: "Take profit" }) }
        }
        return { isValid: false, error: t("error.price-above-index", { orderType: "Take profit" }) }
      }

      if (orderDirection === PositionSide.long && inputValue < price && inputValue !== 0n) {
        if (isLimit) {
          return { isValid: false, error: t("error.price-below-limit", { orderType: "Take profit" }) }
        }
        return { isValid: false, error: t("error.price-below-index", { orderType: "Take profit" }) }
      }

      if (inputValue < 0n) {
        return { isValid: false, error: t("error.must-be-positive", { control: "Price" }) }
      }

      return { isValid: true }
    }
  },
    // eslint-disable-next-line
    [price, orderDirection, isLimit]
  )

  return {
    min: minValidator,
  }
}

export const useTriggerAmountValidators = ({ position }: { position: bigint }) => {
  const { t } = useTranslation()

  const maxValidator = useMemo(() => {
    return (value: string) => {
      const inputValue = Big6Math.fromFloatString(value)
      if (inputValue > position) {
        return t("error.amount-exceeds-position")
      }
      if (inputValue <= 0) {
        return t("error.must-be-positive", { control: "Amount" })
      }
      return true
    }
  },
    // eslint-disable-next-line
    [position]
  )

  return {
    max: maxValidator,
  }
}
