import React, { useMemo,  useState } from "react"
import { Button, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import { ethers } from "ethers"
import { useTranslation } from "react-i18next"

import { useActiveProvider, useRewardsTransactions } from "../../hooks"
import { ApproveCollateralButton } from "../ActionsButtons"
import { CollateralInput } from "../CollateralInput"
import { hasMaxDecimalsAllowed, isPositiveNumber } from "../../utils/utils"
import { errorNotification, notifyUser, TokenIcon } from "../../../components/common"
import { ApproveType, CHAINS, ERROR_USER_REJECTED_TRAN } from "../../utils/constants"
import { RewardsType, VaultAllowances, VaultUserSnapshot } from "../../utils/types"


type props = {
  allowances: VaultAllowances | undefined;
  rewardsInfo: RewardsType | undefined;
  userVaultSnapshot: VaultUserSnapshot | undefined;
  rewardsApr: number;
  mutateData: () => void;
}

export const Rewards = ({
  allowances,
  rewardsInfo,
  userVaultSnapshot,
  rewardsApr,
  mutateData,
}: props) => {
  const { t } = useTranslation()
  const stakeActions = [
    { name: t("stake"), value: 0 },
    { name: t("withdraw"), value: 1 },
  ]
  const { chainId } = useActiveProvider();
  const [currentAction, setCurrentAction] = useState(stakeActions[0].value);
  const [sharesAmount, setSharesAmount] = useState("0");
  const [sharesAmountBN, setSharesAmountBN] = useState(0n);
  const [writingTran, setWritingTran] = useState(false);
  const [claiming, setClaiming] = useState(false);

  const {
    availableSharesBN,
    availableShares,
    sharesAllowance,
    amountStaked,
    earned,
  } = useMemo(() => {
    const availableSharesBN = userVaultSnapshot
      ? userVaultSnapshot?.balance
      : 0n
    
    const availableShares = parseFloat(ethers.formatEther(availableSharesBN));
    
    const sharesAllowance = allowances ? allowances?.sharesToStake : 0n;

    const amountStaked = rewardsInfo ? rewardsInfo?.amountStaked : 0n;

    const earned = rewardsInfo ? rewardsInfo?.earned : 0n;

    return {
      availableSharesBN,
      availableShares,
      sharesAllowance,
      amountStaked,
      earned,
    }
  },
    // eslint-disable-next-line
    [allowances, userVaultSnapshot]
  )

  const { errorStake, errorWithdraw } = useMemo(() => {
    let errorStake = "";
    let errorWithdraw = "";

    if (currentAction === 0) {
      if (sharesAmountBN > availableSharesBN) {
        errorStake = t("error.no-shares")
      }
    } else {
      if (sharesAmountBN > amountStaked) {
        errorWithdraw = t("error.no-staked-shares")
      }
    }

    return {
      errorStake,
      errorWithdraw,
    }
  },
    // eslint-disable-next-line
    [currentAction, availableSharesBN, amountStaked, sharesAmountBN]
  );

  const onTransactionSettled = (isSuccess: boolean, successMsg: string, error: any) => {
    if (isSuccess) {
      notifyUser(successMsg);
      setSharesAmount("0");
      setSharesAmountBN(0n);
      mutateData();
    } else {
      if (error.name !== ERROR_USER_REJECTED_TRAN) {
        errorNotification(t("error.transaction"))
      }
    }
    setWritingTran(false);
    setClaiming(false);
  };

  const { onStakeShares, onClaimRewards, onWithdrawShares } = useRewardsTransactions(chainId, onTransactionSettled);

  const { stakeEnable, withdrawEnable } = useMemo(() => {
    return {
      stakeEnable: sharesAmountBN > 0n && sharesAmountBN <= availableSharesBN && sharesAmountBN <= sharesAllowance && currentAction === 0,
      withdrawEnable: sharesAmountBN > 0n && sharesAmountBN <= amountStaked && currentAction === 1,
    }
  },
    [amountStaked, availableSharesBN, sharesAmountBN, currentAction, sharesAllowance]
  )

  const onSharesChange = async (value: string) => {
    if (value !== "" && isPositiveNumber(value)) {
      if (hasMaxDecimalsAllowed(value, 18)) {
        setSharesAmount(value);
        setSharesAmountBN(ethers.parseEther(value));
      }
    } else {
      setSharesAmount("");
      setSharesAmountBN(0n);
    }
  };

  const onSetMaxAmount = () => {
    if (currentAction === 0) {
      setSharesAmount(availableShares.toString());
      setSharesAmountBN(availableSharesBN);
    } else {
      setSharesAmount(ethers.formatEther(amountStaked));
      setSharesAmountBN(amountStaked);
    }
  }

  const onStakeClick = async () => {
    if (stakeEnable) {
      setWritingTran(true);
      onStakeShares(sharesAmountBN);
    }
  };

  const onWithdrawClick = async () => {
    if (withdrawEnable) {
      setWritingTran(true);
      onWithdrawShares(sharesAmountBN);
    }
  };

  const onClaimClick = async () => {
    if (earned !== 0n) {
      setWritingTran(true);
      setClaiming(true);
      onClaimRewards()
    }
  };

  const RenderErrorMsg = () => {
    if (currentAction === 0 && errorStake !== "") {
      return (
        <span className="error">
          {errorStake}
        </span>
      )
    }
    if (currentAction === 1 && errorWithdraw !== "") {
      return (
        <span className="error">
          {errorWithdraw}
        </span>
      )
    }
    return <></>;
  }

  return (
    <div className="liquidity-rewards">
      <div className="body-left rewards-v2">
        <div className="options rewards-v2">
          <ToggleButtonGroup type="radio" name="options" defaultValue={0}>
            {stakeActions.map((action, idx) => (
              <ToggleButton
                key={`kstake-${idx}`}
                id={`stake-${idx}`}
                type="radio"
                name="radio"
                value={action.value}
                checked={currentAction === action.value}
                onChange={(e) => {
                  setCurrentAction(parseInt(e.currentTarget.value));
                  setSharesAmount("0");
                  setSharesAmountBN(0n);
                }}
              >
                {action.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <div className="controls">
          <CollateralInput
            control_id="reward-collateral-input-id"
            showBalance={true}
            value={sharesAmount}
            title={t("vault-shares")}
            onChange={onSharesChange}
            onSetMaxAmount={onSetMaxAmount}
            maxCaption={currentAction === 0 ? t("shares") : t("staked")}
            vaultWithdrawal={true}
            deposited={
              currentAction === 0 ? availableSharesBN : amountStaked
            }
            showIcon={false}
            tokenSymbol="TVA"
            showUSDValue={false}
          />
          <RenderErrorMsg />  
        </div>
        {currentAction === 0 && sharesAmountBN > sharesAllowance ? (
          <ApproveCollateralButton
            approvalAction={ApproveType.STAKE}
            currentAllowance={0}
            amountToApprove={
              parseFloat(ethers.formatEther(sharesAmountBN))
            }
            onSuccess={() => {
              mutateData();
            }}
          />
        ) : (
          <Button
            className="btn btn-primary btn-block deposit-button rewards-v2"
            onClick={currentAction === 0 ? onStakeClick : onWithdrawClick}
            disabled={currentAction === 0
              ? writingTran || (!stakeEnable && sharesAmountBN !== 0n)
              : writingTran || (!withdrawEnable && sharesAmountBN !== 0n)
            }
          >
            <div className="btn-spinner">
              {writingTran && !claiming && <Spinner animation="border" variant="secondary" className="small" />}
              {stakeActions[currentAction].name}
            </div>
          </Button>
        )}
      </div>
      <div className="body-right rewards-v2">
          <div className="values-top">
            <div className="value-item">
              <h6>{t("available-to-stake")}:</h6>
              <NumericFormat
                className="balance-usd bold"
                value={availableShares.toFixed(4)}
                displayType="text"
                thousandSeparator=","
                suffix=" TVA"
                decimalScale={4}
              />
            </div>
            <div className="value-item">
              <h6>{t("amount-staked")}:</h6>
              <NumericFormat
                className="balance-usd bold"
                value={parseFloat(ethers.formatEther(amountStaked)).toFixed(4)}
                displayType="text"
                thousandSeparator=","
                suffix=" TVA"
                decimalScale={4}
              />
            </div>
            <div className="value-item">
              <h6>{t("current-reward")}:</h6>
              <div className="icon-item">
                <NumericFormat
                  className="number bold"
                  value={
                    CHAINS.ARBITRUM === chainId
                      ? parseFloat(ethers.formatEther(earned)).toFixed(4)
                      : parseFloat(ethers.formatUnits(earned, 6)).toFixed(4)
                  }
                  displayType="text"
                  thousandSeparator=","
                  decimalScale={4}
                />
                <TokenIcon name="ARB" size="small" />
              </div>
            </div>
            <div className="value-item">
            <h6>{t("apr")}:</h6>
              <NumericFormat
                className="balance-usd bold"
                value={rewardsApr}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={4}
              />
            </div>
          </div>
          <div className="values-bottom">
            <Button
              className="btn-claim"
              onClick={() => onClaimClick()}
              disabled={earned === 0n || writingTran}
            >
              <div className="btn-spinner">
                {claiming && <Spinner animation="border" variant="secondary" className="small" />}
                {t("claim")}
              </div>
            </Button>
          </div>  
      </div>
    </div>    
  )
}
