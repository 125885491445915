import { useContext } from "react";
import {
  useActiveProvider,
  useCollateral,
  useProductsSnapshots,
  useUserProductSnapshots,
  useVaultSnapshot,
  useVaultUserSnapshot,
  useTcapOraclePrice,
  useUSDCOraclePrice,
  useTokensBalance,
} from ".";
import { tcapLongMarketContract, tcapShortMarketContract } from "../utils/contracts";
import { productContext } from "../states";


export const useMutateAll = () => {
  const { chainId, userAccount } = useActiveProvider();
  const currentMarket = useContext(productContext);

  // call snapshots to mutate data if needed
  const { mutate: mutateCollateralLong } = useCollateral(tcapLongMarketContract[chainId].address);
  const { mutate: mutateCollateralShort } = useCollateral(tcapShortMarketContract[chainId].address);
  const { mutate: mutateProductsSnapshots } = useProductsSnapshots();
  const { mutate: mutateUserProductSnapshot } = useUserProductSnapshots(userAccount);
  const { mutate: mutateVault } = useVaultSnapshot(currentMarket.vaultContract[chainId].address, currentMarket.productContract[chainId].address);
  const { mutate: mutateUserVault } = useVaultUserSnapshot(userAccount);
  const { mutate: mutateBalances } = useTokensBalance();
  const { mutate: mutateTcapPrice } = useTcapOraclePrice();
  const { mutate: mutateUsdcPrice } = useUSDCOraclePrice();
  
  const mutateQueries = () => {
    mutateTcapPrice();
    mutateUsdcPrice();
    mutateCollateralLong();
    mutateCollateralShort();
    mutateProductsSnapshots();
    mutateUserProductSnapshot();
    mutateVault();
    mutateUserVault();
    mutateBalances();
  };
  
  const mutateAll = () => {
    mutateQueries();
    setTimeout(() => {
      mutateQueries();
    }, 25000);
  }

  return {
    mutateAll
  }
}