import { initializeApp } from "firebase/app";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";
import { InvokerAction } from "../helpers"
import { CHAINS, TOKENS_SYMBOLS } from "../utils/constants";


// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FB_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const TRANSACTIONS_COLLECTION = "transactions";
const OPEN_MONTHLY_COLLECTION = "open-monthly-metrics";
const CLOSE_MONTHLY_COLLECTION = "close-monthly-metrics";
const LIQUIDITY_MONTHLY_COLLECTION = "liquidity-monthly-metrics";



const getMonthKey = (date: Date): string => {
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");

  return `${date.getUTCFullYear()}-${month}`;
}

const getActionName = (action: number): string => {
  if (action === InvokerAction.OPEN_TAKE) {
    return "open_take";
  } else if (action === InvokerAction.CLOSE_TAKE) {
    return "close_take";
  } else if (action === InvokerAction.OPEN_MAKE) {
    return "open_make";
  } else if (action === InvokerAction.CLOSE_MAKE) {
    return "close_make";
  } else if (action === InvokerAction.VAULT_WRAP_AND_DEPOSIT) { 
    return "vault_wrap_deposit";
  }

  return "vault_redeem";
};

// transactions
export const saveTransactionToDB = async (
  chainId: number,
  txHash: string,
  ownerAddress: string,
  marketKey: string,
  contractAddress: string,
  collateral: number,
  position: number,
  exposure: number,
  action: number,
) => {
  if (chainId !== CHAINS.ARBITRUM || (marketKey !== TOKENS_SYMBOLS.ether && marketKey !== TOKENS_SYMBOLS.arb)) {
    console.log("Not Market");
    return;
  }

  const data = {
    user_account: ownerAddress,
    hash: txHash, 
    market: marketKey,
    contract_address: contractAddress,
    collateral,
    position: Math.abs(position),
    exposure,
    action: getActionName(action),
    timestamp: serverTimestamp(),
  }  

  try {
    const batch = writeBatch(db);
    const transactionDoc = doc(db, TRANSACTIONS_COLLECTION, txHash);
    batch.set(transactionDoc, data);

    if (action === InvokerAction.VAULT_WRAP_AND_DEPOSIT || action === InvokerAction.VAULT_REDEEM) {
      await saveLiquidityMetrics(batch, marketKey, collateral, action);
    } else {
      await saveTradeMetrics(batch, marketKey, collateral, Math.abs(position), exposure, action);
    }

    await batch.commit();
  } catch (error) {
    console.log("Error saving to fb: ", error);
  }
};

const saveTradeMetrics = async (
  batch: WriteBatch,
  marketKey: string,
  collateral: number,
  position: number,
  exposure: number,
  action: number,
) => {

  const monthKey = getMonthKey(new Date());
  const marketMonthKey = marketKey.concat("_").concat(monthKey);
  const isTaker = action === InvokerAction.OPEN_TAKE || action === InvokerAction.CLOSE_TAKE;
  const collectionName = action === InvokerAction.OPEN_TAKE || action === InvokerAction.OPEN_MAKE
    ? OPEN_MONTHLY_COLLECTION
    : CLOSE_MONTHLY_COLLECTION;

  
  // get the docs
  const monthlyDocRef = doc(db, collectionName, monthKey);
  const marketDocRef = doc(db, collectionName, marketMonthKey);
  const monthlyDoc = await getDoc(monthlyDocRef);
  const marketMonthlyDoc = await getDoc(marketDocRef);

  // save data
  try {
    const monthlyDefaultData = {
      total_collateral: collateral,
      total_position: 0,
      total_exposure: exposure,
      maker_collateral: isTaker ? 0 : collateral,
      maker_position: 0,
      maker_exposure: isTaker ? 0 : exposure,
      taker_collateral: isTaker ? collateral : 0,
      taker_position: 0,
      taker_exposure: isTaker ? exposure : 0,
      transactions: 1,
    }  
    
    if (monthlyDoc.exists()) {
      const monthlyData = monthlyDoc.data();
      monthlyData.total_collateral += collateral;
      monthlyData.total_exposure += exposure;
      monthlyData.transactions += 1;

      if (isTaker) {
        monthlyData.taker_collateral += collateral;
        monthlyData.taker_exposure += exposure;
      } else {
        monthlyData.maker_collateral += collateral;
        monthlyData.maker_exposure += exposure;
      }

      batch.update(monthlyDocRef, monthlyData);
    } else {
      batch.set(monthlyDocRef, monthlyDefaultData);
    }

    // market metrics per month
    if (marketMonthlyDoc.exists()) {
      const marketMonthlyData = marketMonthlyDoc.data();
      marketMonthlyData.total_collateral += collateral;
      marketMonthlyData.total_position += position;
      marketMonthlyData.total_exposure += exposure;
      marketMonthlyData.transactions += 1;

      if (isTaker) {
        marketMonthlyData.taker_collateral += collateral;
        marketMonthlyData.taker_position += position;
        marketMonthlyData.taker_exposure += exposure;
      } else {
        marketMonthlyData.maker_collateral += collateral;
        marketMonthlyData.maker_position += position;
        marketMonthlyData.maker_exposure += exposure;
      }

      batch.update(marketDocRef, marketMonthlyData);
    } else {
      monthlyDefaultData.total_position = position;
      monthlyDefaultData.taker_position = isTaker ? position : 0;
      monthlyDefaultData.maker_position = isTaker ? 0 : position;
      batch.set(marketDocRef, monthlyDefaultData);
    }
  } catch (error: any) {
    console.log("Error: ", error);
  }

};

const saveLiquidityMetrics = async (
  batch: WriteBatch,
  marketKey: string,
  collateral: number,
  action: number,
) => {
  const monthKey = getMonthKey(new Date());
  const marketMonthKey = marketKey.concat("_").concat(monthKey);
  const isDeposit = action === InvokerAction.VAULT_WRAP_AND_DEPOSIT;

  // get the docs
  const monthlyDocRef = doc(db, LIQUIDITY_MONTHLY_COLLECTION, monthKey);
  const marketDocRef = doc(db, LIQUIDITY_MONTHLY_COLLECTION, marketMonthKey);
  const monthlyDoc = await getDoc(monthlyDocRef);
  const marketMonthlyDoc = await getDoc(marketDocRef);

  // save data
  try {
    const monthlyDefaultData = {
      total_deposits: collateral,
      total_withdrawals: 0,
      total_liquidity: collateral,
      transactions: 1,
    }  
    
    if (monthlyDoc.exists()) {
      const monthlyData = monthlyDoc.data();
      monthlyData.transactions += 1;

      if (isDeposit) {
        monthlyData.total_deposits += collateral;
        monthlyData.total_liquidity += collateral;
      } else {
        monthlyData.total_withdrawals += collateral;
        monthlyData.total_liquidity -= collateral;
      }

      batch.update(monthlyDocRef, monthlyData);
    } else {
      batch.set(monthlyDocRef, monthlyDefaultData);
    }

    // market metrics per month
    if (marketMonthlyDoc.exists()) {
      const marketMonthlyData = marketMonthlyDoc.data();
      marketMonthlyData.transactions += 1;

      if (isDeposit) {
        marketMonthlyData.total_deposits += collateral;
        marketMonthlyData.total_liquidity += collateral;
      } else {
        marketMonthlyData.total_withdrawals += collateral;
        marketMonthlyData.total_liquidity -= collateral;
      }

      batch.update(marketDocRef, marketMonthlyData);
    } else {
      batch.set(marketDocRef, monthlyDefaultData);
    }
  } catch (error: any) {
    console.log("Error: ", error);
  }

};
