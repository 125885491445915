import React, { useContext, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap/esm"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"
import { NumericFormat } from "react-number-format"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { productContext } from "../../states"
import { TOKENS_SYMBOLS } from "../../utils/constants"
import { formatDate, decimalAdjust } from "../../utils/utils"
import { useMutateAll } from "../../hooks"


const PRICE_UPDATES = gql`
  query PriceUpdates($aggregatorAddress: String!, $lastBlockTimestamp: BigInt!) {
    answerUpdateds(
      first: 1000,
      orderBy: blockTimestamp, 
      orderDirection: asc, 
      where: { aggregatorAddress: $aggregatorAddress, blockTimestamp_gt: $lastBlockTimestamp }
    ) {
      id
      updatedAt
      roundId
      blockTimestamp
      blockNumber
      answer
    }
  }
`;

const LAST_PRICE_UPDATES = gql`
  query LastPriceUpdates($aggregatorAddress: String!) {
    answerUpdateds(
      first: 1,
      orderBy: blockTimestamp, 
      orderDirection: desc,
      where: { aggregatorAddress: $aggregatorAddress }
    ) {
      id
      updatedAt
      roundId
      blockTimestamp
      blockNumber
      answer
    }
  }
`;

type PointType = {
  label: string;
  date: string;
  price: number;
  ts: number;
}

// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation()
  const currentProduct = useContext(productContext)

  if (active && payload && payload.length) {
    const blockTimeMS = payload[0].payload.ts * 1000;
    const date = new Date(blockTimeMS);

    return (
      <div className="chart-custom-tooltip">
        <div>
          <span>{t("price")}:</span>
          <NumericFormat
            className="number"
            value={payload[0].value}
            displayType="text"
            thousandSeparator=","
            prefix=" $"
            decimalScale={currentProduct.market.displayDecimals}
          />
        </div>
        <div>
          <span>{t("date")}:</span>
          <span className="number">{formatDate(date, "long")}</span>
        </div>
      </div>
    );
  }

  return null;
};

type props = {
  aggregatorAddress: string;
  initialMinBlockTS: number;
  hoursFrame: number;
}

export const PricesChart = ({ aggregatorAddress, initialMinBlockTS, hoursFrame }: props) => {
  const currentProduct = useContext(productContext);
  const smallPrices = currentProduct.market.key === TOKENS_SYMBOLS.perpe;
  const [domain, setDomain] = useState([100, 140]);
  const [priceRange, setPriceRange] = useState([10000, 0]);
  const [prices, setPrices] = useState<Array<PointType>>([]);
  const [lastBlockTS, setLastBlockTS] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const { mutateAll } = useMutateAll();

  const formatData = (data: any) => {
    const pricesArray = [...prices];
    let nextLastBlockTS = lastBlockTS;
    let currentPriceRange = [...priceRange];
    const minTimeMSLimit = Date.now() - initialMinBlockTS * 1000;

    for (let i = 0; i < data.length; i += 1) {
      const blockTimeMS = data[i].blockTimestamp * 1000;
      const date = new Date(blockTimeMS);
      const price = parseFloat(ethers.formatEther(
        BigInt(data[i].answer) * currentProduct.market.multiplier
      ));

      if (blockTimeMS >= minTimeMSLimit) {
        if (currentPriceRange[0] > price) {
          currentPriceRange[0] = price;
        }
        if (currentPriceRange[1] < price) {
          currentPriceRange[1] = price;
        }

        pricesArray.push({
          label: formatDate(date, hoursFrame === 24 ? "short" : ""),
          date: formatDate(date, hoursFrame === 24 ? "short" : ""),
          price: parseFloat(price.toFixed(currentProduct.market.displayDecimals)),
          ts: data[i].blockTimestamp,
        });
      }
      nextLastBlockTS = data[i].blockTimestamp;
    }

    let adjustNumber = hoursFrame === 24 ? 0 : 3;
    if (hoursFrame >= 4380) {
      adjustNumber = 8;
    }

    let domainMin = decimalAdjust("floor", currentPriceRange[0], 0) - adjustNumber;
    let domainMax = decimalAdjust("ceil", currentPriceRange[1], 0) + adjustNumber;

    if (currentProduct.market.key === TOKENS_SYMBOLS.arb) {
      const rangeDiff = currentPriceRange[1] - currentPriceRange[0]; 
      domainMin = parseFloat((currentPriceRange[0] - rangeDiff).toFixed(2));
      domainMax = parseFloat((currentPriceRange[1] + rangeDiff).toFixed(2));
    }
    if (currentProduct.market.key === TOKENS_SYMBOLS.perpe) {
      const rangeDiff = currentPriceRange[1] - currentPriceRange[0]; 
      domainMin = parseFloat((currentPriceRange[0] - rangeDiff).toFixed(9));
      domainMax = parseFloat((currentPriceRange[1] + rangeDiff).toFixed(9));
    }

    setDomain([domainMin, domainMax]);
    setPriceRange(currentPriceRange);
    setPrices(pricesArray);
    setLastBlockTS(nextLastBlockTS);

    if (data.length === 1000) {
      setLastBlockTS(nextLastBlockTS);
    } else {
      setFinishedLoading(true);
    }
  }

  const addToPrices = (data: any) => {
    if (data.length > 0) {
      const lastTS = prices[prices.length - 1].ts;
      if (data[0].blockTimestamp > lastTS) {
        const pricesArray = [...prices];
        const blockTimeMS = data[0].blockTimestamp * 1000;
        const date = new Date(blockTimeMS);
        const price = parseFloat(ethers.formatEther(
          BigInt(data[0].answer) * currentProduct.market.multiplier
        ));

        pricesArray.push({
          label: formatDate(date, hoursFrame === 24 ? "short" : ""),
          date: formatDate(date, hoursFrame === 24 ? "short" : ""),
          price: parseFloat(price.toFixed(currentProduct.market.displayDecimals)),
          ts: data[0].blockTimestamp,
        });

        setPrices(pricesArray);
        mutateAll();
      }
    }
  }

  const [loadPrices, { refetch: refetchPrices }] =
    useLazyQuery(PRICE_UPDATES, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {
        aggregatorAddress: aggregatorAddress,
        lastBlockTimestamp: initialMinBlockTS.toFixed(),
      },
      onError: (error) => {
        console.log(error);
      },
      onCompleted: (data: any) => {
        setFirstLoad(false);
        if (data && !finishedLoading) {
          formatData(data.answerUpdateds);
        }
      }
    });
  
  useQuery(LAST_PRICE_UPDATES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      aggregatorAddress: aggregatorAddress,
    },
    pollInterval: 15000,
    onError: (error) => {
      console.log(error);
    },
    onCompleted: (data: any) => {
      if (data && finishedLoading && !firstLoad) {
        addToPrices(data.answerUpdateds);
      }
    },
  });

  useEffect(() => {
    const load = () => {
      if (!finishedLoading) {
        if (firstLoad) {
          setPrices(new Array<PointType>());
          loadPrices();
        } else {
          refetchPrices({
            aggregatorAddress: aggregatorAddress,
            lastBlockTimestamp: lastBlockTS.toString(),
          });
        }
      }
    }
    load();
  },
    // eslint-disable-next-line
    [lastBlockTS]
  );

  return (
    <>
      {finishedLoading ? (
        <ResponsiveContainer
          className={smallPrices ? "pepe-chart" : ""}
          width="100%"
        >
          <AreaChart
            width={500}
            height={400}
            data={prices}
            margin={{
              top: 0,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="label"
              interval="preserveEnd"
              minTickGap={60}
              height={30}
            />
            <YAxis
              dataKey="price"
              type="number"
              domain={domain}
              tickFormatter={(number) => {
                return "$".concat(number.toString());
              }}
            />
            <Tooltip
              // @ts-ignore
              content={<CustomTooltip />}
            />
            <Area
              type="monotone"
              dataKey="price"
              strokeWidth={1.5}
              stroke="#A440F2"
              fill="#a540f24a"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <div className="loading-container">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>  
  );
}
