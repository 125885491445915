import React, { useEffect, useMemo, useState } from "react"
import { Dropdown, Image, Row, Stack } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { Big6Math, formatBig6USDPrice, calcNotional, ChainMarkets, SupportedAsset } from "@perennial/sdk"

import { useLivePriceContext, useMarketContext } from '../../../contexts'
import { AssetMetadata,  } from "../../../constants/markets"


export const MarketsDropdown = ({ selectedLatestPrice }: { selectedLatestPrice: bigint } ) => {
  const { t } = useTranslation()
  const {
    chainId,
    isMaker,
    snapshots2,
    setSelectedMarket,
    selectedMarket,
    setSelectedMakerMarket,
    selectedMakerMarket,
  } = useMarketContext()

  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket
  const handleItemChange = (value: string | null) => {
    if (value) {
      if (isMaker) {
        setSelectedMakerMarket(value as SupportedAsset)
      } else { 
        setSelectedMarket(value as SupportedAsset)
      } 
    }
  };
  const [positiveChange, setPositiveChange] = useState(true)
  const [previousPrice, setPreviousPrice] = useState(0n)
  const livePrices = useLivePriceContext()
  const oraclePrice = livePrices[currentMarket as SupportedAsset]?.price ?? selectedLatestPrice
  const currentAssetMetadata = AssetMetadata[currentMarket]

  useEffect(() => {
    setPositiveChange(previousPrice < oraclePrice)
    setPreviousPrice(oraclePrice)
  },
    // eslint-disable-next-line
    [oraclePrice]
  )

  const sortedMarkets = useMemo(() => {
    const unsorted = Object.keys(ChainMarkets[chainId]).map((market) => {
      const assetMetadata = AssetMetadata[market as SupportedAsset]
      const marketSnapshot = snapshots2?.market?.[market as SupportedAsset]
      const marketPrice = marketSnapshot?.global?.latestPrice ?? 0n

      return {
        assetMetadata,
        marketPrice,
        makerNotional: calcNotional(marketSnapshot?.position?.maker ?? 0n, marketPrice)
      }
    })

    return unsorted.sort((a, b) => {
      return Big6Math.toUnsafeFloat(b.makerNotional) - Big6Math.toUnsafeFloat(a.makerNotional)
    })
  },
    [chainId, snapshots2?.market]
  )

  return (
    <Dropdown
      className="markets-dropdown"
      onSelect={(eventKey) => handleItemChange(eventKey)}
    >
      <Dropdown.Toggle id="dropdown-basic" variant="success">
        <div className="dropdown-toggle-container">
          <div className="toggle-section market">
            <Image src={currentAssetMetadata.icon} height={35} width={35} />
            <div className="market-desc">
              <h6 className="title">
                {currentAssetMetadata.symbol}
              </h6>
              <span className="caption">{currentAssetMetadata.name}</span>
            </div>
          </div>
          <div className="vertical-divisor" />
          <div className="toggle-section price-box">
            <div className="flex-column">
              <span className={"price ".concat(positiveChange ? "text-green" : "text-red")}>
                {formatBig6USDPrice(oraclePrice)}
              </span>
            </div>  
          </div>
        </div>  
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <>
          <Row className="dropdown-menu-header">
            <span className="small text-muted">{t("market")}</span>
            <span className="small text-muted text-right">{t("price")} / {t("liquidity")}</span>
          </Row>
          {sortedMarkets.map((market) => {
            if (market.assetMetadata.baseCurrency === SupportedAsset.rlb) return <></>
            
            return (
              <Dropdown.Item key={market.assetMetadata.symbol} eventKey={market.assetMetadata.baseCurrency}>
                <Stack className="left" direction="horizontal">
                  <Image className="token-icon margin-right" src={market.assetMetadata.icon} height={30} width={30} />
                  <Stack>
                    <h6>{market.assetMetadata.symbol}</h6>
                    <span className="small text-muted">{market.assetMetadata.name}</span>
                  </Stack>
                </Stack>
                <Stack className="right">
                  <span className="number">
                    {formatBig6USDPrice(Big6Math.abs(market.marketPrice))}
                  </span>
                  <span className="number small text-muted">
                    {formatBig6USDPrice(market.makerNotional, {
                      compact: true,
                    })}
                  </span>
                </Stack>
              </Dropdown.Item>
            )
          })}
        </>   
      </Dropdown.Menu>
    </Dropdown>
  )
};
