import React, { useContext } from "react"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useTranslation } from "react-i18next"

import { CustomTooltip } from "./common"
import { versionContext } from "../contexts"


type props = {
    onTabChange: (k: string) => void;
}

const TabsChart = ({ onTabChange }: props) => {
  const { t } = useTranslation()
  const appVersion = useContext(versionContext)

  const setterKey = (k: string | null) => {
    if(!k) onTabChange("oracle")
    else onTabChange(k);
  }

  const TooltipMsg = () => {
    return (
      <>
        <p>
          <b>{t("oracle-chart")}</b>: {t("tooltip.oracle-chart")}
        </p>
        <p>
          <b>{t("reference-chart")}</b>: {t("tooltip.reference-chart")}
        </p>
      </>
    )
  }

  return (
    <div className="markets-tabs-container">
      {!appVersion.isV2_5() && (
        <>
          <Tabs
            defaultActiveKey="market"
            id="oracle-chart-tabs"
            onSelect={(k) => setterKey(k)}
          >
            <Tab eventKey="market" title={t("reference-chart")} />
            {!appVersion.isV2_5() && <Tab eventKey="oracle" title={t("oracle-chart")} />}
          </Tabs>
          <CustomTooltip
            id="taprice2"
            msg={TooltipMsg()}
            hideDelay={300}
            iconSize={16}
            showIcon={true}
          >
            <></>
          </CustomTooltip>
        </>  
      )}  
    </div>
  )
}

export default TabsChart;