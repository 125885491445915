import React from "react"
import { Spinner } from "react-bootstrap/esm"

import VaultForm from "./VaultForm"
import { VaultMetrics } from "./VaultMetrics"
import { useVaultContext } from "../../../contexts"
import { useBalances } from "../../../hooks/wallet"


export const Provide = () => {
  const { data: balances } = useBalances()
  const { status, vaultSnapshots, selectedVault } = useVaultContext()

  if (status !== "success") {
    return (
      <div className="liquidity-spinner">
        <Spinner animation="border" variant="primary" className="small" />
      </div>
    )
  }

  const snapshot = selectedVault && vaultSnapshots?.vault[selectedVault]
  if (!snapshot) {
    return <div className="liquidity-provide" />
  }

  return (
    <div className="liquidity-provide">
      <div className="body-left provide">
        <VaultForm
          vaultSnapshot={snapshot}
          vaultUserSnapshot={vaultSnapshots.user && vaultSnapshots.user[selectedVault]}
          balances={balances}
        />
      </div>
      <div className="body-right provide">
        <VaultMetrics
          vault={snapshot}
          vaultAccountSnapshot={vaultSnapshots.user && vaultSnapshots.user[selectedVault]}
        />
      </div>
    </div>
  )   
}
