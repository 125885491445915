import React, { useMemo, useState } from "react"
import { Stack, Tabs, Tab } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { BigOrZero, formatBig6USDPrice, sum as sumBI, PositionSide } from "@perennial/sdk"

import { RewardSeasons } from "./components/RewardSeasons"
import { CurrentSTIPSeason, STIPDropParams, STIPSeasonNumber } from "../../constants/stipDrop"
import { useMarketSnapshots2 } from "../../hooks/markets2"
import { useVaultSnapshots2 } from "../../hooks/vaults2"
import { useAccountARBSeasonData } from "../../hooks/markets2/graph"
import { useClaimRewards, useRewardData } from "../../hooks/rewards"
import { RewardsCard } from "./components/RewardsCard"


const Rewards = () => {
  const { t } = useTranslation()
  const [season, setSeason] = useState<STIPSeasonNumber>(CurrentSTIPSeason)
  const { data: marketSnapshots } = useMarketSnapshots2()
  const { data: vaultSnapshots } = useVaultSnapshots2()
  const { data: rewards } = useAccountARBSeasonData(season)
  const allSeasonData = useRewardData()
  
  const previousClaimable = useMemo(() => {
    if (!allSeasonData) return false
    return allSeasonData
      .filter(({ data }) => data?.season && data.season < season)
      .some(({ data }) => !data?.claimed && BigOrZero(data?.amount) > 0n)
  }, [allSeasonData, season])

  const { claimRewards } = useClaimRewards()
  const { rewardClaimData, takerRewards, feesRewards, makerRewards, vaultRewards, totalRewards } = useMemo(() => {
    const { data: rewardClaimData } = allSeasonData.find(({ data }) => data?.season === season) ?? {
      rewardClaimData: undefined,
    }

    return {
      rewardClaimData,
      takerRewards: rewardClaimData?.userRewards.taker ?? 0n,
      feesRewards: rewardClaimData?.userRewards.fee ?? 0n,
      makerRewards: rewardClaimData?.userRewards.maker ?? 0n,
      vaultRewards: rewardClaimData?.userRewards.vault ?? 0n,
      totalRewards: rewardClaimData?.amount ?? 0n,
    }
  }, [allSeasonData, season])


  const takerStats = useMemo(() => {
    const currentOI = marketSnapshots?.user
      ? sumBI(
          Object.values(marketSnapshots.user).map((ss) => (ss.nextSide === PositionSide.maker ? 0n : ss.nextNotional)),
        )
      : 0n
    const stats = [
      { label: t("earn-rewards.fees-paid"), value: formatBig6USDPrice(rewards?.fees ?? 0n, { compact: true }) },
      { label: t("metrics.volume"), value: formatBig6USDPrice(rewards?.volume ?? 0n, { compact: true }) },
    ]
    if (!STIPDropParams[season].over)
      stats.push({ label: t("metrics.open-interest"), value: formatBig6USDPrice(currentOI, { compact: true }) })
    return stats
  },
    // eslint-disable-next-line
    [marketSnapshots?.user, rewards?.volume, rewards?.fees, season]
  )

  const makerStats = useMemo(() => {
    const vaultOI = vaultSnapshots?.user ? sumBI(Object.values(vaultSnapshots.user).map((ss) => ss.assets)) : 0n
    const makerOI = marketSnapshots?.user
      ? sumBI(
          Object.values(marketSnapshots.user).map((ss) => (ss.nextSide !== PositionSide.maker ? 0n : ss.nextNotional)),
        )
      : 0n

    return [
      {
        label: t("vault-liquidity"),
        value: formatBig6USDPrice(vaultOI, { compact: true }),
      },
      {
        label: t("earn-rewards.individual-makers"),
        value: formatBig6USDPrice(makerOI, { compact: true }),
      },
    ]
  },
    // eslint-disable-next-line
    [marketSnapshots?.user, vaultSnapshots?.user]
  )

  const onClaim = () => {
    if (!rewardClaimData) return
    claimRewards({ ...rewardClaimData })
  }

  return (
    <Stack className="rewards" direction="vertical" gap={5}>
      <Stack className="rewards-container">
        <RewardSeasons
          onChangeSeason={setSeason}
          season={season}
          currentSeason={CurrentSTIPSeason}
          rewardClaimed={rewardClaimData ? rewardClaimData.claimed : false}
          onClaim={onClaim}
          rewards={rewardClaimData?.claimed ? 0n : totalRewards}
          previousClaimable={previousClaimable}
        />
        <div className="rewards-data">
          <Tabs id="rewards-tab" defaultActiveKey={"taker"}>
            <Tab eventKey="taker" title={t("earn-rewards.taker-rewards")} >
              <RewardsCard
                title={"taker"}
                description={t("earn-rewards.message-1")}
                stats={takerStats}
                rewards={takerRewards + feesRewards}
                iconText=""
                rewardBreakdown={[
                  { label: t("earn-rewards.fee-rebates"), value: feesRewards },
                  { label: t("metrics.open-interest"), value: takerRewards },
                ]}
                season={season}
              />
            </Tab>  
            <Tab eventKey="maker" title={t("earn-rewards.maker-rewards")}>
              <RewardsCard
                title={"maker"}
                description={t("earn-rewards.message-2")}
                stats={makerStats}
                rewards={makerRewards + vaultRewards}
                iconText=""
                rewardBreakdown={[
                  { label: t("vaults"), value: vaultRewards },
                  { label: t("individual"), value: makerRewards },
                ]}
                season={season}
              />
           </Tab>   
          </Tabs>
        </div>  
      </Stack>  
    </Stack>    
  )  
}

export default Rewards
