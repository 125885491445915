import React, { useMemo, useState } from "react"
import { LiFiWidget, WidgetConfig } from '@lifi/widget'
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { SupportedLanguage } from "../../constants/localization"
// import { useDisconnect } from "wagmi"
import { useEthersSigner } from "../../hooks/ethersSigner"


export const LifiWidgetModal = () => {
  const { i18n } = useTranslation()
  // const { connectAsync, connectors } = useConnect()
  // const { disconnect } = useDisconnect()
  const signer = useEthersSigner()
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en)
  const [show, setShow] = useState(false)

  const lifiConfig = useMemo(() => {
    const lifiConfig: WidgetConfig = {
      appearance: 'dark',
      containerStyle: {
        border: 'none',
        borderRadius: '0px',
        padding: '0px',
      },
      fromChain: "arb",
      hiddenUI: ['appearance'],
      integrator: "cryptex-finance",
      languages: {
        // default to German
        default: currentLanguage as "bn" | "de" | "en" | "es" | "fr" | "id" | "it" | "ko" | "pt" | "th" | "tr" | "uk" | "vi" | "zh" | undefined,
        // allow German and Spanish languages only
        // allow: ['de', 'es'],
      },
      theme: {
        palette: {
          primary: { main: '#A440F2' },
          secondary: { main: '#F5B5FF' },
          background: { default: "#0a0a0a", paper: "#0a0a0a" },
          text: { primary: "#f5f5f5", disabled: "#aaa9bf" }
        },
        shape: {
          borderRadius: 5,
          borderRadiusSecondary: 5,
        },
        typography: {
          fontFamily: 'Helvetica Neue',
        },
      },
      // subvariant: "split",
      toChain: "arb",
      toToken: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      variant: "expandable",
      /* walletManagement: {
        signer: signer,
        connect: async () => {
          /*  const result = await connectAsync({ connector: connectors[0] });
          const walletClient = await result.connector?.getWalletClient();
          if (walletClient) {
            return walletClientToSigner(walletClient);
          } else {
            throw Error('WalletClient not found')
          } 
        },
        disconnect: async () => {
          disconnect();
        }
      }, */
    }
    return lifiConfig
  },
    // eslint-disable-next-line
    [currentLanguage, signer]
  )

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShow(!show)}
        style={{
          borderRadius: "10px",
          padding: "1.5px 3px",
          width: "4.5rem",
          fontSize: "0.9rem"
        }}
      >
        USDC
      </Button>
      <Modal show={show} onHide={() => setShow(false)} className="lifi-modal">
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            paddingBottom: "0px",            
          }}
        />
        <Modal.Body style={{ paddingTop: "0px" }}>
          <LiFiWidget integrator="Cryptex Finance" config={lifiConfig} />
        </Modal.Body>  
      </Modal>
    </>  
  );
};