import { Big6Math, calcMakerExposure, formatBig6USDPrice, MarketSnapshot, PositionSide, SupportedAsset, UserMarketSnapshot } from '@perennial/sdk';
import { LivePrices } from "."
import { useMarketContext } from "../../contexts"
import { useActivePositionsMarketPnls } from "./graph"


export type PnlMetricsType = ReturnType<typeof getPnlMetrics>
const getPnlMetrics = (
  userMarketSnapshot?: UserMarketSnapshot,
  marketSnapshot?: MarketSnapshot,
  livePrices?: LivePrices,
  pnlData?: any,
  failedClose?: boolean
) => {
  if (!pnlData || !userMarketSnapshot) return undefined
  const { asset, side, nextSide, nextMagnitude, magnitude } = userMarketSnapshot

  if (!asset || !pnlData?.[asset]) return undefined

  const { realtime, realtimePercent, realtimePercentDenominator, averageEntryPrice } = pnlData?.[asset]
  const averageEntryPriceFormatted =  formatBig6USDPrice (averageEntryPrice)

  let livePnl = realtime
  let livePnlPercent = realtimePercent
  if (marketSnapshot && livePrices) {
    const {
      global: { latestPrice },
      nextPosition,
    } = marketSnapshot
    const livePrice = livePrices[asset]?.price
    const liveDelta = livePrice ? livePrice - latestPrice : 0n
    const magnitudeForCalc = failedClose ? magnitude : nextMagnitude

    let livePnlDelta =  Big6Math.mul(magnitudeForCalc, liveDelta)
    if (side ===  PositionSide.maker || nextSide === PositionSide.maker) {
      const makerExposure = calcMakerExposure(
        magnitudeForCalc,
        nextPosition.maker,
        nextPosition.long,
        nextPosition.short,
      )
      // Maker positions are dampened by exposure
      livePnlDelta = Big6Math.mul(liveDelta, makerExposure)
    } else if (side === PositionSide.short || nextSide === PositionSide.short) {
      // Shorts are negative
      livePnlDelta = Big6Math.mul(liveDelta, magnitudeForCalc * -1n)
    }

    livePnl = livePnl + livePnlDelta
    livePnlPercent =
      realtimePercentDenominator > 0n ? Big6Math.abs(Big6Math.div(livePnl, realtimePercentDenominator)) : 0n
  }

  let positionFees = pnlData[asset].positionFees
  // Subtract price impact fees if taker
  if (side !== PositionSide.maker && nextSide !== PositionSide.maker)
    positionFees = positionFees - pnlData[asset].priceImpactFees

  const totalFees =
    pnlData[asset].keeperFees + pnlData[asset].interfaceFees + positionFees + pnlData[asset].liquidationFee

  return {
    data: pnlData[asset],
    pnl: realtime,
    unrealized: realtime - (pnlData[asset].accumulatedPnl.value - totalFees),
    pnlPercent: realtimePercent,
    averageEntryPriceFormatted,
    averageEntryPrice,
    liquidation: pnlData[asset].liquidation,
    livePnl,
    livePnlPercent,
    totalFees,
    liveUnrealized: livePnl - (pnlData[asset].accumulatedPnl.value - totalFees),
  }
}

export const useActiveMarketsPnls = (livePrices?: LivePrices) => {
  const { snapshots2 } = useMarketContext()
  const { data: pnlData } = useActivePositionsMarketPnls()
  
  if (!snapshots2) return undefined
  if (!snapshots2.user) return undefined
    
  return Object.keys(snapshots2.user).reduce((acc, asset) => {
    const market = snapshots2.market[asset as SupportedAsset]
    const userMarketSnapshot = snapshots2.user ? snapshots2.user[asset as SupportedAsset] : undefined

    acc[asset as SupportedAsset] = getPnlMetrics(
      userMarketSnapshot,
      market,
      livePrices,
      pnlData
    )
    return acc
  }, {} as Record<SupportedAsset, PnlMetricsType>)

}

export const useMarketPnl = (
  userMarketSnapshot?: UserMarketSnapshot,
  marketSnapshot?: MarketSnapshot,
  livePrices?: LivePrices,
  failedClose?: boolean,
) => {
  const { data: pnlData } = useActivePositionsMarketPnls()

  return getPnlMetrics(
    userMarketSnapshot,
    marketSnapshot,
    livePrices,
    pnlData,
    failedClose
  )
}