import { useCallback } from 'react'
import { StandardMerkleTree } from '@openzeppelin/merkle-tree'
import { notEmpty } from '@perennial/sdk'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useQueries, useQueryClient } from '@tanstack/react-query'
import { Hex, getAddress, zeroAddress } from 'viem'
import { useAccount, usePublicClient, useWalletClient } from 'wagmi'
import { useTranslation } from 'react-i18next'

import { metamaskTxRejectedError } from '../constants/network'
import { FinishedSTIPSeasons, STIPDropParams } from '../constants/stipDrop'
import { errorNotification } from '../components/common/notifications'

import { useSeasonalMerkleClaim } from './contracts'
import { useAddress, useChainId } from './network'


type RewardDataJSON = {
  userRewards: Record<
    string,
    | {
        taker: string
        maker: string
        fee: string
        vault: string
        total: string
      }
    | undefined
  >
  tree: ReturnType<StandardMerkleTree<['address', 'uint256']>['dump']>
}
export const useRewardData = () => {
  const chainId = useChainId()
  const { address } = useAddress()
  const seasonMerkleClaim = useSeasonalMerkleClaim()

  return useQueries({
    queries: FinishedSTIPSeasons.map((season) => {
      const blobUrl = STIPDropParams[season].blobUrl
      return {
        queryKey: ['RewardData', season, address, chainId],
        enabled: !!address && !!blobUrl,
        queryFn: async () => {
          if (!address || !blobUrl) return undefined

          const response = await fetch(blobUrl)
          const data = (await response.json()) as RewardDataJSON
          const tree = StandardMerkleTree.load(data.tree)
          // Find proof in tree
          let proof: Hex[] = []
          let claimAmount = 0n
          for (const [i, v] of tree.entries()) {
            if (getAddress(v[0]) === address) {
              // (3)
              proof = tree.getProof(i) as Hex[]
              claimAmount = BigInt(v[1])
            }
          }
          const root = tree.root as Hex
          const claimed = await seasonMerkleClaim.read.claimed([address, root])

          return {
            season,
            userRewards: {
              taker: BigInt(data.userRewards[address]?.taker ?? 0n),
              maker: BigInt(data.userRewards[address]?.maker ?? 0n),
              fee: BigInt(data.userRewards[address]?.fee ?? 0n),
              vault: BigInt(data.userRewards[address]?.vault ?? 0n),
            },
            proof: proof,
            amount: claimAmount,
            root,
            claimed,
          }
        },
      }
    }),
  })
}

export const useClaimRewards = () => {
  const {  t } = useTranslation()
  const { data: walletClient } = useWalletClient()
  const { address } = useAddress()
  const { chain } = useAccount()
  const chainId = useChainId()
  const merkleClaim = useSeasonalMerkleClaim(walletClient ?? undefined)
  const queryClient = useQueryClient()
  const publicClient = usePublicClient({ chainId })
  const addRecentTransaction = useAddRecentTransaction()
  const allRewards = useRewardData()

  const refresh = useCallback(async () => {
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) => ['RewardData'].includes(queryKey[0] as string) && queryKey.includes(chainId),
    })
  }, [queryClient, chainId])

  const claimRewards = async ({ root, amount, proof }: { root: Hex; amount: bigint; proof: Hex[] }) => {
    if (!walletClient || !address) return
    try {
      const hash = await merkleClaim.write.claim([[amount], [root], [proof]], {
        chain,
        chainId,
        account: address || zeroAddress,
      })
      await publicClient?.waitForTransactionReceipt({ hash })
      await refresh()
      addRecentTransaction({
        hash,
        description: t("notification.claimed-rewards"),
      })
    } catch (err: any) {
      // Ignore metamask tx rejected error
      if (err.details !== metamaskTxRejectedError) {
        errorNotification(t("notification.error-claiming-rewards"))
      }

      console.error(err)
    }
  }

  const claimAllRewards = async () => {
    if (!walletClient || !address || !allRewards) return
    const allUnclaimedRewardData = allRewards
      .map((r) => r.data)
      .filter(notEmpty)
      .filter((r) => !r.claimed && r.amount > 0n && r.proof.length > 0)
    const amounts = allUnclaimedRewardData.map((r) => r.amount)
    const roots = allUnclaimedRewardData.map((r) => r.root)
    const proofs = allUnclaimedRewardData.map((r) => r.proof)

    try {
      const hash = await merkleClaim.write.claim([amounts, roots, proofs], {
        chain,
        chainId,
        account: address || zeroAddress,
      })
      await publicClient?.waitForTransactionReceipt({ hash })
      await refresh()
      addRecentTransaction({
        hash,
        description: "Claimed all rewards",
      })
    } catch (err: any) {
      // Ignore metamask tx rejected error
      if (err.details !== metamaskTxRejectedError) {
        errorNotification(t("notification.error-claiming-rewards"))
      }

      console.error(err)
    }
  }

  return { claimRewards, claimAllRewards }
}
