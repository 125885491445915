import { useMemo } from 'react'
import {
  Big6Math,
  calcLpExposure,
  calcSkew,
  calcTakerLiquidity,
  efficiency,
  formatBig6Percent,
  formatBig6USDPrice,
} from '@perennial/sdk'

import { useMarketContext } from '../../../contexts/marketContext'
import { useMarket24HrHighLow } from "../../../hooks/markets2"
import {  calcLpUtilization } from "../../../utils/positionUtils"
import { useMarket24hrData, useMarket7dData } from '../../../hooks/markets2/graph'
import { useLivePriceContext } from '../../../contexts/livePriceContext'


export const useFormattedMarketBarValues = () => {
  const livePrices = useLivePriceContext()
  const { selectedMarket: selectedMarket_, isMaker, selectedMarketSnapshot2, selectedMakerMarket } = useMarketContext()

  const selectedMarket = isMaker ? selectedMakerMarket : selectedMarket_
  const { data: priceData } = useMarket24HrHighLow(selectedMarket)
  const { data: dailyData } = useMarket24hrData(selectedMarket)
  const { data: weeklyData } = useMarket7dData(selectedMarket)

  const totalVolume = useMemo(() => {
    // @ts-ignore
    if (!dailyData?.volume) return 0n
    // @ts-ignore
    return dailyData.volume.reduce((acc, cur) => acc + BigInt(cur.longNotional) + BigInt(cur.shortNotional), 0n)
  },
    // @ts-ignore
    [dailyData?.volume]
  )

  const chainPrice = selectedMarketSnapshot2?.global?.latestPrice ?? 0n
  const currentPrice = livePrices[selectedMarket]?.price ?? chainPrice ?? 0n
  // const untransformedPrice = livePrices[selectedMarket]?.untransformed ?? chainPrice ?? 0n
  const change = currentPrice - BigInt(priceData?.open ?? currentPrice)

  const latestPrice = selectedMarketSnapshot2?.global?.latestPrice ?? 0n
  const nextLong = selectedMarketSnapshot2?.nextPosition?.long ?? 0n
  const nextShort = selectedMarketSnapshot2?.nextPosition?.short ?? 0n
  const longOpenInterest = Big6Math.mul(nextLong, latestPrice)
  const shortOpenInterest = Big6Math.mul(nextShort, latestPrice)

  const availableLiq = selectedMarketSnapshot2 ? calcTakerLiquidity(selectedMarketSnapshot2) : undefined
  const lpExposure = calcLpExposure(selectedMarketSnapshot2)
  const lpUtilization = calcLpUtilization(selectedMarketSnapshot2)
  const calculatedSkew = calcSkew(selectedMarketSnapshot2)
  const makerEfficiency = efficiency(
    selectedMarketSnapshot2?.nextPosition.maker ?? 0n,
    selectedMarketSnapshot2?.nextMajor ?? 0n,
  )

  return {
    price: formatBig6USDPrice(currentPrice),
    change: formatBig6Percent(Big6Math.abs(Big6Math.div(change, priceData?.open ?? 1n))),
    changeIsNegative: change < 0n,
    low: formatBig6USDPrice(Big6Math.min(currentPrice, priceData?.low ?? 0n)),
    high: formatBig6USDPrice(Big6Math.max(currentPrice, priceData?.high ?? 0n)),
    volume: formatBig6USDPrice(totalVolume, { compact: true }),
    openInterest: `${formatBig6USDPrice(longOpenInterest, {
      compact: true,
    })} / ${formatBig6USDPrice(shortOpenInterest, { compact: true })}`,
    availableLiquidity: `${formatBig6USDPrice(Big6Math.mul(availableLiq?.availableLongLiquidity ?? 0n, latestPrice), {
      compact: true,
    })} / ${formatBig6USDPrice(Big6Math.mul(availableLiq?.availableShortLiquidity ?? 0n, latestPrice), {
      compact: true,
    })}`,
    totalLiquidity: `${formatBig6USDPrice(Big6Math.mul(availableLiq?.totalLongLiquidity ?? 0n, latestPrice), {
      compact: true,
    })} / ${formatBig6USDPrice(Big6Math.mul(availableLiq?.totalShortLiquidity ?? 0n, latestPrice), {
      compact: true,
    })}`,
    lpExposurePct: lpExposure?.formattedLpExposure ?? '0.00%',
    lpExposure: lpExposure?.exposureSide ?? '--',
    volume7d: `${formatBig6USDPrice((weeklyData?.takerVolumes.long ?? 0n) + (weeklyData?.takerVolumes.short ?? 0n), {
      compact: true,
    })}`,
    lpUtilization: lpUtilization?.formattedLpUtilization ?? '0.00%',
    skew: formatBig6Percent(calculatedSkew?.skew ?? 0n),
    longSkew: formatBig6Percent(calculatedSkew?.longSkew ?? 0n),
    shortSkew: formatBig6Percent(calculatedSkew?.shortSkew ?? 0n),
    efficiency: formatBig6Percent(makerEfficiency),
  }
}
