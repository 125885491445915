import React from "react";
import { Address, TransactionReceipt } from 'viem'
import { usePublicClient } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ReactComponent as CryptexIcon } from '../../assets/cryptex.svg'
import { useChainId } from "../../hooks/network";


export const sendNotification = async (
  title: string,
  body: string,
  duration: number | false = 4000,
  fn: any = () => {},
  delay: number = 0,
  className: string = "success"
) => {
  const toastConstant = (
    <div className="body">
      <CryptexIcon />
      <div className="body-msg">
        <h5>{title}</h5>
        <p>{body}</p>
      </div>  
    </div>
  );
  toast(toastConstant, {
    // @ts-ignore
    position: toast.POSITION.TOP_RIGHT,
    autoClose: duration,
    hideProgressBar: true,
    delay,
    className,
    onClose: () => {
      fn();
    },
  });
};

export const errorNotification = async (body: string) => {
  const title = "❌ Whoopsie!";
  sendNotification(title, body, 3000, () => {}, 0, "error");
};

export const notifyUser = async (msg: string, title = "Transaction Confirmed!", fn: any = () => {}) => {
  try {
    const notificationTitle = title;
    const notificationBody = msg;
    sendNotification(notificationTitle, notificationBody, 4000, fn, 1000, "success");
  } catch (error) {
    // catch error when vault screen changes in the middle of an update
  }
};

export const useTransactionToasts = () => {
  const { t } = useTranslation()
  const chainId = useChainId()
  const publicClient = usePublicClient({ chainId })

  return {
    waitForTransactionAlert: (
      hash: Address,
      options?: {
        successMsg?: string
        errorMsg?: string
        onError?: (e: any) => void
        onSuccess?: (receipt: TransactionReceipt) => void
      },
    ) => {
      const { successMsg, errorMsg, onError, onSuccess } = options || {}
      return publicClient
        ?.waitForTransactionReceipt({ hash })
        .then((res: TransactionReceipt) => {
          const notificationTitle = t("transaction-confirmed")
          const notificationBody = successMsg || "";
          sendNotification(notificationTitle, notificationBody)

          if (onSuccess) {
            onSuccess(res)
          }
          return res
        })
        .catch((e: any) => {
          errorNotification(errorMsg || "");
          if (onError) {
            onError(e)
          }
        })
    },
  }
}

