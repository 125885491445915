import React, { useContext, useMemo } from "react"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"
import { ContractType, TokenType } from "../../utils/types"
import { useActiveProvider, useVaultSnapshot } from "../../hooks/"
import { productContext } from "../../states"
import {  PriceBox } from "../common"
import { TokenIcon } from "../../../components/common"

type props = {
  market: TokenType;
  productContract: ContractType;
  showLiquidity: boolean;
};

export const MarketStats = ({ showLiquidity }: props) => {
  const { t } = useTranslation()
  const { chainId } = useActiveProvider();
  const currentMarket = useContext(productContext);
  const { data: vaultSnapshot } = useVaultSnapshot(
    currentMarket.vaultContract[chainId].address, currentMarket.productContract[chainId].address
  );

  const { currentLiquidity } = useMemo(() => {
    const currentLiquidity =
      vaultSnapshot ? ethers.formatEther(vaultSnapshot.totalAssets) : "0"

    return {
      currentLiquidity: parseFloat(currentLiquidity).toFixed(4),
    }
  }, [vaultSnapshot]);

  return (
    <div className="markets-stats">
      <div className="stats-values">
        <PriceBox showMarketCap={false} />
        {showLiquidity && (
          <div className="stats-item">
            <span className="title opaque small">{t("available-liquidity")}:</span>
            <div className="stats-item-2">
              <NumericFormat
                className="number small"
                value={currentLiquidity}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
              />
            </div>
            <TokenIcon name="usdc" size="xs" />
          </div>
        )}
      </div>
    </div>    
  )    
};
