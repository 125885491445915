import React from "react";
import { useParams } from "react-router-dom";
import { PositionSide, SupportedAsset } from '@perennial/sdk';

import { AcceptReferral } from "../referrals";
import { MarketProvider, useMarketContext } from '../../contexts/marketContext'
import { useRefreshKeysOnPriceUpdates2 } from '../../hooks/markets2'

import TabsChart from "../TabsChart"
import TradeForm from "./TradeForm"
import Positions from "./Positions"
import PLInterfaceToggle from "../common/PLInterfaceToggle"
import {TradingViewWidget } from "./charts/TradingViewWidget"
import { Spinner, Stack } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import { MobileTradingViewWidget } from "./charts/MobileTradingViewWidget"
import { MarketInfoMetrics } from "../common/MarketMetrics"
import { usePushStream } from "../push/hooks";
import { LivePricesProvider } from "../../contexts/livePriceContext";


const Trade = ({
  isProInterface,
  currentInterface,
  setCurrentInterface
}: {
  isProInterface: boolean,
  currentInterface?: number,
  setCurrentInterface?: (value: number) => void
}) => {
  useRefreshKeysOnPriceUpdates2()
  usePushStream()

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const queryParams = useParams();
  const referrerUuid = queryParams.uuid
  const {
    selectedMarket,
    orderDirection,
    setOrderDirection,
    selectedMakerMarket,
    isMaker,
    snapshots2,
    userCurrentPosition,
  } = useMarketContext()
  const market = snapshots2?.market?.[isMaker ? selectedMakerMarket : selectedMarket]

  return(
    <>
      {referrerUuid && <AcceptReferral referrerUuid={referrerUuid} />}
      <TabsChart onTabChange={() => {}} />
      <div className={(!isMobile ? "trade-module" : "mobile-trade").concat(" trade-v2")}>
        {isProInterface && (
          <div className="version-chooser">
            <PLInterfaceToggle
              currentInterface={currentInterface || 1}
              setCurrentInterface={setCurrentInterface}
            />
          </div>
        )}  
        <div className="trade-top">
          {isMobile && <MobileTradingViewWidget />}
          {!isMobile && (
            <div className="trade-left">
              <MarketInfoMetrics />
              <TradingViewWidget />
            </div>
          )}
          <div className="trade-right">
            {market ? (
              <Stack direction="vertical" gap={2}>
                <TradeForm
                  isNewPosition={true}
                  isProInterface={isProInterface}
                  asset={isMaker ? (selectedMakerMarket as SupportedAsset) : selectedMarket}
                  orderSide={isMaker ? PositionSide.maker : orderDirection}
                  setOrderDirection={setOrderDirection}
                  market={market}
                  position={userCurrentPosition}
                  showPositionAmount={true}
                />
              </Stack>  
            ) : (
              <div className="spinner-container trade">
                <Spinner animation="border" variant="primary" className="small" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="trade-bottom">
        <Positions isProInterface={isProInterface} />
      </div>
    </>  
  );
};

const TradeWrapper = ({
  isProInterface,
  currentInterface,
  setCurrentInterface
}: {
  isProInterface: boolean,
  currentInterface?: number,
  setCurrentInterface?: (value: number) => void
}) => (
  <MarketProvider isMaker={isProInterface}>
    <LivePricesProvider>
      <Trade isProInterface={isProInterface} currentInterface={currentInterface} setCurrentInterface={setCurrentInterface}  />
    </LivePricesProvider>  
  </MarketProvider>  
)


export default TradeWrapper;
