import React, { useState } from "react"
import { Button, Spinner } from "react-bootstrap/esm"
import { Address } from "viem"
import { useTranslation } from "react-i18next"
import { PerennialVaultType} from "@perennial/sdk"

import { useMarketTransactions2 } from "../../../hooks/markets2"
import { useVaultTransactions } from "../../../hooks/vaults2"
import "../../../styles/modals.scss"
import { errorNotification } from "../notifications"
import { useOnSupportedChain } from "../../../hooks/network"


type props = {
  approvalType: "market" | "vault";
  contractAddres: Address
  currentAllowance: string;
  amountToApprove: bigint;
  vaultType?: PerennialVaultType;
  isInterfaceFee?: boolean;
  onSuccess: () => void;  
};

export const ApproveButton = ({
  approvalType,
  contractAddres,
  currentAllowance,
  amountToApprove,
  vaultType,
  isInterfaceFee,
  onSuccess
}: props) => {
  const { t } = useTranslation()
  const isMarket = approvalType === "market";
  const { isUsingSupportedChain } = useOnSupportedChain()
  const [approvingUSDC, setApprovingUSDC] = useState(false);
  const { onApproveUSDC } = useMarketTransactions2(contractAddres);
  const { onApproveUSDC: onVaultApproveUSDC } = useVaultTransactions({
    vaultType: vaultType || PerennialVaultType.bravo,
    vaultAddress: contractAddres
  });

  const handleApproveUSDC = async () => {
      setApprovingUSDC(true)
      try {
        if (isMarket) {
          await onApproveUSDC({ suggestedAmount: amountToApprove })
        } else {
          await onVaultApproveUSDC({ suggestedAmount: amountToApprove })
        }
        onSuccess()
      } catch (err) {
        errorNotification(t("notification.approving-error"))
        console.error(err)
      } finally {
        setApprovingUSDC(false)
      }
    }

  const msg = (): string => {
    if (!isInterfaceFee) {
      return t("info-msg.approve-usdc-1", { value: currentAllowance })
    }
    return t("info-msg.approve-usdc-2", { value: currentAllowance })
  }

  return (
    <div className="btn-approve-container">
      <div className="btn-approve-container-msg">
        <h6>{msg()}</h6>
      </div>
      <Button
        className="btn-approve bold"
        onClick={() => handleApproveUSDC()}
        disabled={approvingUSDC || !isUsingSupportedChain }
      >
        <div className="btn-spinner">
          {approvingUSDC && <Spinner animation="border" variant="secondary" className="small" />}
          {approvingUSDC ? t("approving") : t("approve", { token: "USDC" })}
        </div>  
      </Button>
    </div>      
  );   
};
