import React from "react"
import { useTranslation } from "react-i18next"
import ProgressBar from "react-bootstrap/ProgressBar"
import {Big6Math, formatBig6 } from "@perennial/sdk"

import { NumericFormat } from "react-number-format"


export const LiquidityCapacity = ({ liquidity, capacity }: { liquidity: bigint; capacity: bigint }) => {
  const { t } = useTranslation()
  const capacityPercent = (Big6Math.toUnsafeFloat(liquidity) / Big6Math.toUnsafeFloat(capacity)) * 100

  return (
    <div className="capacity-container">
      <div className="capacity-info">
        <span>
          {t("current-liquidity")}
        </span>
        <div className="capacity-values">
          <NumericFormat
            className="number small"
            value={formatBig6(liquidity)}
            displayType="text"
            thousandSeparator=","
            decimalScale={0}
          />
          <span className="separator">/</span>
          <NumericFormat
            className="number small"
            value={formatBig6(capacity)}
            displayType="text"
            thousandSeparator=","
            decimalScale={0}
          />
        </div>
      </div>
      <ProgressBar
        variant="primary"
        now={capacityPercent}
        max={100}
      />
    </div>  
  );     
};
