import React from "react"
import { useTranslation } from "react-i18next"
import { Dropdown } from "react-bootstrap/esm"
import { TokenIcon } from "../../../components/common"
import { TokenType } from "../../utils/types"
import { MARKETS, TOKENS_SYMBOLS } from "../../utils/constants"

type props = {
  currentMarket: TokenType;
  handleMarketChange: (market: TokenType) => void;
};

export const MarketsDropdown = ({ currentMarket, handleMarketChange }: props) => {
  const { t } = useTranslation()

  const handleItemChange = (value: string) => {
    if (MARKETS[value]) {
      handleMarketChange(MARKETS[value].market);
    }
  };

  return (
    <Dropdown
      className="markets-dropdown"
      onSelect={(eventKey) => handleItemChange(eventKey || TOKENS_SYMBOLS.tcap.toUpperCase())}
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic" disabled>
        <div className="dropdown-toggle-container">
          <TokenIcon name={currentMarket.symbol} size="normal" />
          <div className="market-desc">
            <h6 className="title">{currentMarket.description}</h6>
            <span className="caption">{t("select-market")}</span>
          </div>          
        </div>  
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.values(MARKETS).map((market) => (
          <Dropdown.Item key={market.market.key} eventKey={market.market.key}>
            <TokenIcon name={market.market.symbol} size="medium"  />
            {market.market.description}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>            
    </Dropdown>
  )
};
