
export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const isNumber = (value: any): boolean => {
  try {
    const v = parseFloat(value);
    return !Number.isNaN(v);
  } catch (error) {
    return false;  
  }
};

export const isPositiveNumber = (value: any): boolean => {
  try {
    const val = parseFloat(value)
    return val > 0
  } catch (error) {
    return false;  
  }
};

export const isNonNegativeNumber = (value: any): boolean => {
  try {
    const val = parseFloat(value);
    return val >= 0;  
  } catch (error) {
    return false;  
  }
};
