import React, { useContext, useMemo, useState } from 'react'
import { ButtonGroup, Form, Tab, Tabs, ToggleButton } from 'react-bootstrap/esm'
import {
  Big6Math,
  calcInterfaceFee,
  calcLeverage,
  calcLiquidationPrice,
  calcNotional,
  calcTakerLiquidity,
  formatBig6,
  formatBig6USDPrice,
  getPositionFromSelectedMarket,
  MarketSnapshot,
  PositionSide,
  PositionStatus,
  SupportedAsset,
  TriggerComparison,
  UserMarketSnapshot,
} from '@perennial/sdk'
import { useTranslation } from 'react-i18next'

import { geoLocationContext } from '../../../contexts'

import { useAddress, useChainId, useOnSupportedChain } from '../../../hooks/network'
import { useBalances } from '../../../hooks/wallet'
import { useMarketContext } from '../../../contexts/marketContext'

import {
  useCollateralValidators,
  useLimitPriceValidators,
  usePositionValidators,
  useStopLossValidator,
  useTakeProfitValidators
} from '../validatorHooks'

import { FormNames, OrderTypes } from '../constants'
import { FormattedOpenOrder, useOnChangeHandlers } from '../hooks'
import { useTriggerOnChangeHandlers } from './components/TriggerOrders/hooks'
import { useOperators } from '../../../hooks/wallet'
import { calcMaxLeverage, needsApproval } from '../utils'

import { GeoblockMessage, LeverageSlider, CustomTooltip } from '../../common'
import { ApproveButton, ApproveOperatorButton } from '../../common/TxButtons'
import { SelectMarkets } from '../../common/markets'
import {
  CollateralInput,
  IndexInput,
  LeverageInput,
  ModifyPositionButton,
  OrdersWarning,
  PlaceOrderButton,
  TradeMetrics,
  TriggerLimitOrder,
  TriggerOrderGroup,
} from './components/'
import { capitalize } from '../../../v1/utils/utils'
import { isNonNegativeNumber } from '../../../utils/utils'
import { MarketInfoMetrics } from '../../common/MarketMetrics'
import { useMediaQuery } from 'react-responsive'
import { MarketInfo } from '../../common/MarketInfo'


interface TradeProps {
  isNewPosition: boolean
  isProInterface: boolean
  asset: SupportedAsset
  orderSide: PositionSide.long | PositionSide.short | PositionSide.maker
  setOrderDirection: (orderDirection: PositionSide.long | PositionSide.short) => void
  market: MarketSnapshot
  position?: UserMarketSnapshot
  order?: FormattedOpenOrder
  isLimitOrder?: boolean
  isMobile?: boolean
  showPositionAmount?: boolean
  setUpdateValues?: (
    canMofifyPosition: boolean,
    positionDelta: { collateralDelta: bigint, positionDelta: bigint },
    interfaceFee: bigint,
    newCollateral: string,
    newPosition: string,
    newLeverage: string,
    newLimitOrderPrice: string,
    slPrice: string,
    tpPrice: string,
  ) => void
}

enum TradeTabs {
  limit = OrderTypes.limit,
  market = OrderTypes.market,
  info = "info"
}

const TradeForm = ({
  isNewPosition,
  isProInterface,
  orderSide: positionSide,
  setOrderDirection,
  market,
  position,
  order,
  isLimitOrder,
  showPositionAmount,
  setUpdateValues
}: TradeProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const chainId = useChainId()
  const { isUsingSupportedChain } = useOnSupportedChain()
  const { address: accountAddress } = useAddress()
  const { data: balances, refetch: refetchBalances } = useBalances()
  const { data: operatorData } = useOperators()
  const currentGeolocation = useContext(geoLocationContext)
  const marketsSide = [PositionSide.long, PositionSide.short]

  const {
    market: marketAddress,
    global: { latestPrice },
    riskParameter: { margin, minMargin },
    parameter: { closed, settlementFee },
  } = market
  const marketFactoryApproved = operatorData && operatorData.marketFactoryApproved
  const isOpenRestricted =
    position?.side === PositionSide.long || position?.side === PositionSide.short
    || position?.side === PositionSide.maker

  const {
    assetMetadata,
    isMaker,
    orderDirection,
    snapshots2,
    selectedMarket,
    selectedMakerMarket,
  } = useMarketContext()

  const orderSize = isLimitOrder && order ? order.orderDelta : "0"
  const orderTriggerPrice = isLimitOrder && order ? order.triggerPrice : Big6Math.toFloatString(latestPrice)
  const orderTriggerPriceBI = isLimitOrder && order ? order.triggerPriceUnformatted : latestPrice

  // hasPosition doesn't account for collateral
  const hasPosition = !!getPositionFromSelectedMarket({
    isMaker,
    userMarketSnapshots: snapshots2?.user,
    selectedMarket,
    selectedMakerMarket,
  })
  const currentPositionAmount =
    hasPosition && !isLimitOrder ?
      position?.nextMagnitude ?? 0n :
      (isLimitOrder ? Big6Math.fromFloatString(orderSize) : 0n)
  const currentCollateral = position?.local.collateral ?? 0n 
  const positionLeverage = isLimitOrder && order ?
    calcLeverage(orderTriggerPriceBI, currentPositionAmount, currentCollateral) :
    position?.nextLeverage ?? 0n
  const userMaintenance = position?.maintenance ?? 0n
  const isSocialized = market.isSocialized && orderDirection === market.majorSide
  const availableLiquidity = calcTakerLiquidity(market)
  const hasCollateral = currentCollateral > 0n

  // Form values
  const [currentTab, setCurrentTab] = useState(TradeTabs.limit)
  const [collateral, setCollateral] = useState(isNewPosition ? "0" : Big6Math.toFloatString(currentCollateral))
  const [positionAmount, setPositionAmount] = useState(isNewPosition ? "0" : Big6Math.toFloatString(currentPositionAmount))
  const [leverage, setLeverage] = useState(isNewPosition ? "0" : Big6Math.toFloatString(positionLeverage))
  const [currentLeverage, setCurrentLeverage] = useState(isNewPosition ? 0 : parseFloat(Big6Math.toFloatString(positionLeverage)))
  const [inputLeverage, setInputLeverage] = useState(
    isNewPosition ? "0" : parseFloat(Big6Math.toFloatString(positionLeverage)).toFixed(1)
  )
  const [selectedLimitComparison, setSelectedLimitComparison] = useState<TriggerComparison>(
    positionSide === PositionSide.long ? TriggerComparison.lte : TriggerComparison.gte,
  )
  const [orderTypes, setOrderTypes] = useState([isLimitOrder ? OrderTypes.limit : OrderTypes.market])
  const [limitOrderPrice, setLimitOrderPrice] = useState(
    isLimitOrder && !isNewPosition ? orderTriggerPrice : Big6Math.toFloatString(latestPrice)
  )
  const [limitPricePercent, setLimitPricePercent] = useState("0")
  const [stopLossPrice, setStopLossPrice] = useState("0")
  const [takeProfitPrice, setTakeProfitPrice] = useState("0")

  const { usdcAllowance } = useMemo(() => {
    return {
      usdcAllowance: balances?.usdcAllowance || 0n
    }
  }, [balances])

  const maxLeverage = useMemo(
    () => calcMaxLeverage({ margin, minMargin, collateral: Big6Math.fromFloatString(collateral) }),
    [margin, minMargin, collateral],
  )
  
  const addOrRemoveOrderType = (type: OrderTypes, add: boolean) => {
    if (add) {
      const currentTypes = orderTypes
      currentTypes.push(type)

      if (type === OrderTypes.market) {
        setOrderTypes(currentTypes.filter((t) => t !== OrderTypes.limit))
      } else if (type === OrderTypes.limit) {
        setOrderTypes(currentTypes.filter((t) => t !== OrderTypes.market))
      } else {
        setOrderTypes(currentTypes)
      }
    } else {
      const currentTypes = orderTypes.filter((t) => t !== type)
      setOrderTypes(currentTypes)
    }
  }  

  const interfaceFee = calcInterfaceFee({
    positionStatus: position?.status,
    latestPrice: market.global.latestPrice,
    chainId,
    positionDelta: Big6Math.abs(Big6Math.fromFloatString(positionAmount) - currentPositionAmount),
    side: positionSide,
    referrerInterfaceFeeDiscount: 0n,
    referrerInterfaceFeeShare: 0n,
  })
  
  const liquidationPrices = calcLiquidationPrice({
    marketSnapshot: market,
    collateral: Big6Math.fromFloatString(collateral),
    position: Big6Math.fromFloatString(positionAmount),
  })

  const approvalInfo = needsApproval({
    collateralDifference: Big6Math.fromFloatString(collateral),
    usdcAllowance,
    interfaceFee: interfaceFee.interfaceFee,
  })

  const collateralValidators = useCollateralValidators({
    usdcBalance: balances?.usdc ?? 0n,
    requiredMaintenance: userMaintenance ?? 0n,
    currentCollateral: currentCollateral,
    minMargin,
  })

  const positionValidators = usePositionValidators({
    isMaker: isMaker,
    takerLiquidity:
      positionSide === PositionSide.long ? availableLiquidity.totalLongLiquidity : availableLiquidity.totalShortLiquidity,
    makerLiquidity: market.nextPosition.maker + market.nextMinor,
    taker: market.nextPosition[positionSide],
    maker: market.nextPosition.maker,
    major: market.nextMajor,
    currentPositionAmount,
    makerLimit: market.riskParameter.makerLimit,
    efficiencyLimit: market.riskParameter.efficiencyLimit,
    marketClosed: closed,
    isSocialized: isSocialized,
  })

  const limitOrderValidators = useLimitPriceValidators({
    selectedLimitComparison,
    currentPrice: Big6Math.toUnsafeFloat(latestPrice)
  })

  const stopLossValidators = useStopLossValidator({
    orderDirection: positionSide !== PositionSide.maker ? positionSide : orderDirection,
    latestPrice,
    isLimit: orderTypes.includes(OrderTypes.limit),
    limitPrice: Big6Math.fromFloatString(limitOrderPrice),
    liquidationPrice: positionSide === PositionSide.long ? liquidationPrices.long : liquidationPrices.short
  })

  const takeProfitValidators = useTakeProfitValidators({
    orderDirection: positionSide !== PositionSide.maker ? positionSide : orderDirection,
    latestPrice,
    isLimit: orderTypes.includes(OrderTypes.limit),
    limitPrice: Big6Math.fromFloatString(limitOrderPrice),
  })

  const { isCollateralValid, collateralError } = useMemo(() => {
    let isCollateralValid = true
    let collateralError = ""
    const maxValidation = collateralValidators.max(collateral)
    const minValidation = collateralValidators.min(collateral)
    if (!maxValidation.isValid || !minValidation.isValid) {
      isCollateralValid = false;
      collateralError = maxValidation?.error || minValidation?.error || "";
    }

    return {
      isCollateralValid,
      collateralError,
    }
  }, [collateral, collateralValidators])

  const { isAmountValid, positionError } = useMemo(() => {
    let isAmountValid = true;
    let positionError = "";
    const maxValidation = positionValidators.max(positionAmount)

    if (!maxValidation.isValid) {
      isAmountValid = false;
      positionError = maxValidation?.error || "";
    }
    if (positionValidators.min) {
      const minValidation = positionValidators.min(positionAmount)
      if (!maxValidation.isValid) { 
        isAmountValid = false;
        positionError = minValidation?.error || "";
      }
    }

    return {
      isAmountValid,
      positionError,
    }
  }, [positionAmount, positionValidators])

  const { limitOrderError } = useMemo(() => {
    let limitOrderError = ""

    if (orderTypes.includes(OrderTypes.limit)) {
      const minValidation = limitOrderValidators.min(limitOrderPrice)
      if (!minValidation.isValid) {
        limitOrderError = minValidation.error || ""
      }

      const comparisonValidation = limitOrderValidators.comparison(limitOrderPrice)
      if (!comparisonValidation.isValid) {
        limitOrderError = comparisonValidation.error || ""
      }
    }

    return {
      limitOrderError
    }
  }, [limitOrderPrice, limitOrderValidators, orderTypes])

  const { stopLossError } = useMemo(() => {
    let stopLossError = ""
    const minValidation = stopLossValidators.min(stopLossPrice)
    if (orderTypes.includes(OrderTypes.stopLoss) && !minValidation.isValid) {
      stopLossError = minValidation.error || ""
    }
    return {
      stopLossError
    }
  },
    [stopLossPrice, stopLossValidators, orderTypes]
  )

  const { takeProfitError } = useMemo(() => {
    let takeProfitError = ""
    const minValidation = takeProfitValidators.min(takeProfitPrice)
    if (!minValidation.isValid) {
      takeProfitError = minValidation.error || ""
    }
    return {
      takeProfitError
    }
  },
    [takeProfitPrice, takeProfitValidators]
  )

  const canModifyPosition =
    isCollateralValid &&
    isAmountValid &&
    stopLossError === "" &&
    takeProfitError === "" &&
    limitOrderError === "" &&
    accountAddress !== undefined;
  
  const setFormValues = (valueName: string, value: string) => {
    if (valueName === FormNames.collateral) {
      setCollateral(value);
    }
    if (valueName === FormNames.amount) {
      setPositionAmount(value)
    }
    if (valueName === FormNames.leverage) {
      setLeverage(value)
      setCurrentLeverage(parseFloat(value))
      setInputLeverage(value)
    }
  }

  const { onChangeLeverage, onChangeCollateral } = useOnChangeHandlers({
    setValue: setFormValues,
    leverageFixed: false,
    leverage,
    collateral,
    amount: positionAmount,
    currentPosition: currentPositionAmount,
    marketSnapshot: market,
    chainId,
    positionStatus: position?.status ?? PositionStatus.resolved,
    direction: isMaker ? PositionSide.maker : orderDirection,
    isLimitOrder: orderTypes.includes(OrderTypes.limit),
    limitOrderPrice,    
  })

  const setTriggerOrderValues = (valueName: string, value: string) => {
    if (valueName === FormNames.limitPrice) { 
      setLimitOrderPrice(value)
      onChangeLeverage(parseFloat(leverage) === 0 ? "1" : leverage)
    }
    if (valueName === FormNames.limitPricePercent) {
      setLimitPricePercent(value)
    }
    if (valueName === FormNames.stopLoss) {
      setStopLossPrice(value)
    }
    if (valueName === FormNames.takeProfit) { 
      setTakeProfitPrice(value)
    }
  }

  const { onChangeStopLoss, onChangeTakeProfit, onChangeLimitPrice, onChangeLimitPricePercent } = useTriggerOnChangeHandlers({
    latestPrice,
    setValue: setTriggerOrderValues,
  })
  
  const resetFields = () => {
    setCurrentLeverage(0)
    setLeverage("0")
    setInputLeverage("0")
    setCollateral("0")
    setPositionAmount("0")
    setLimitOrderPrice(Big6Math.toFloatString(latestPrice))
    setStopLossPrice("0")
    setTakeProfitPrice("0")
    setLimitPricePercent("0")
  }

  const onLeverageChange = (value: number, index: number) => {
    onChangeLeverage(value.toString());
  }

  const onLeverageInputChange = (value: number, strValue: string) => {
    setInputLeverage(strValue);
    if (value !== -1) {
      onChangeLeverage(value.toString());
    }
  }

  const onSetMaxAmount = () => {
    const maxValue = Big6Math.toFloatString(balances?.usdc ?? 0n);
    onChangeCollateral(maxValue);
  }

  const onOrderTypeChange = (key: string | null) => {
    setCurrentTab(key as TradeTabs)
    if (key !== TradeTabs.info) {
      resetFields()
      if (key === OrderTypes.market) {
        addOrRemoveOrderType(OrderTypes.market, true)
      } else {
        addOrRemoveOrderType(OrderTypes.limit, true)
      }
    }
  }

  const onMarketTypeChange = (value: string) => {
    const nextSide = value as PositionSide.long | PositionSide.short
    setOrderDirection(nextSide)
    setSelectedLimitComparison(nextSide === PositionSide.long ? TriggerComparison.lte : TriggerComparison.gte)
    resetFields()
  }

  const positionDelta = useMemo(() => {
    const positionDelta = {
      collateralDelta: Big6Math.fromFloatString(collateral) - currentCollateral,
      positionDelta: Big6Math.fromFloatString(positionAmount) - currentPositionAmount,
    }
    if (!isNewPosition && setUpdateValues) {
      setUpdateValues(
        canModifyPosition,
        positionDelta,
        interfaceFee.interfaceFee,
        collateral,
        positionAmount,
        leverage,
        limitOrderPrice,
        stopLossPrice,
        takeProfitPrice,
      )
    }
    
    return positionDelta
  },
    // eslint-disable-next-line
    [isNewPosition, collateral, positionAmount, currentCollateral, currentPositionAmount, limitOrderPrice, stopLossPrice, takeProfitPrice],
  )

  const notionalAmount = calcNotional(Big6Math.fromFloatString(positionAmount), latestPrice)

  return (
    <div className="exchange">
      <div className={"exchange-inner ".concat(isNewPosition && !isProInterface ? "fixed-height" : "")}>
        <SelectMarkets isNewPosition={isNewPosition} orderDirection={positionSide} showStats={isNewPosition} />
        {isMobile && <MarketInfoMetrics />}
        <div className="exchange-options">
          {isNewPosition && !isProInterface && (
            <Tabs
              id="order-types-tabs"
              defaultActiveKey="market"
              onSelect={(k) => onOrderTypeChange(k)}
            >
              <Tab eventKey={TradeTabs.market} title={t("market")} />
              <Tab eventKey={TradeTabs.limit} title={t("limit")} />
              {!isMobile && <Tab eventKey={TradeTabs.info} title={t("info")} />}
            </Tabs>
          )}
          <div className="exchange-options2">
            {currentTab !== TradeTabs.info ? (
              <>
                {isNewPosition && !isProInterface && (
                  <div className="options">
                    <ButtonGroup>
                      {marketsSide.map((side, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`market-${idx}`}
                          type="radio"
                          name="radio"
                          value={side}
                          checked={positionSide === side}
                          onChange={(e) => onMarketTypeChange(e.currentTarget.value)}
                        >
                          {capitalize(side)}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                )}
                <div className="controls">
                  <CollateralInput
                    control_id="exchange-collateral-input-id"
                    showBalance={true}
                    value={collateral}
                    title={t("collateral")}
                    onChange={(value: string) => {
                      if (isNonNegativeNumber(value) || value.trim() === "") {
                        onChangeCollateral(value)
                      }
                    }}
                    onSetMaxAmount={onSetMaxAmount}
                    maxCaption={t("balance")}
                  />
                  {collateralError !== "" && collateral !== "0" && collateral !== "" && (
                    <span className="error">
                      {collateralError}
                    </span>
                  )}
                  {showPositionAmount && (
                    <IndexInput
                      assetMetadata={assetMetadata}
                      showTokenBalance={false}
                      changingAmount={false}
                      amount={positionAmount}
                      amountUsd={formatBig6USDPrice(notionalAmount)}
                    />
                  )}
                  {positionError !== "" && (
                    <span className="error">
                      {positionError}
                    </span>
                  )}
                  {orderTypes.includes(OrderTypes.limit) && !isProInterface && (
                    <TriggerLimitOrder
                      onChangeLimit={onChangeLimitPrice}
                      limitPrice={limitOrderPrice}
                      limitError={limitOrderError}
                      latestPrice={latestPrice}
                      isFormDirty={false}
                      orderDirection={orderDirection}
                      amount={positionAmount}
                      collateral={collateral}
                      percent={limitPricePercent}
                      onPercentChange={onChangeLimitPricePercent}
                      showByDefault={true}
                    />
                  )}
                  <Form.Group className="leverage-group">
                    <div className="leverage-header">
                      <CustomTooltip
                        id="exlev-price"
                        msg={t("tooltip.leverage")}
                        showIcon={true}
                        iconSize={13}
                      >
                        <h6 className="margin-right">{t("leverage")}</h6>
                      </CustomTooltip> 
                      <LeverageInput
                        controlId="exchange-lev-input"
                        value={inputLeverage}
                        max={maxLeverage}
                        onChange={onLeverageInputChange}
                      />
                    </div>  
                    <LeverageSlider
                      defaultValue={0}
                      value={currentLeverage}
                      maxValue={maxLeverage}
                      onChange={onLeverageChange}
                    />
                    <div className="leverage-values">
                      <span className="small">0x</span>
                      <span className="small">{maxLeverage}x</span>
                    </div>
                  </Form.Group>
                  {!isProInterface && (
                    <TriggerOrderGroup
                      onChangeStopLoss={onChangeStopLoss}
                      onChangeTakeProfit={onChangeTakeProfit}
                      stopLossPrice={stopLossPrice}
                      takeProfitPrice={takeProfitPrice}
                      stopLossError={stopLossError}
                      takeProfitError={takeProfitError}
                      latestPrice={latestPrice}
                      isFormDirty={false}
                      orderDirection={
                        positionSide !== PositionSide.maker ? positionSide : orderDirection
                      }
                      amount={positionAmount}
                      collateral={collateral}
                      isLimit={orderTypes.includes(OrderTypes.limit)}
                      addOrRemoveOrderType={addOrRemoveOrderType}
                    />
                  )}
                </div>
                {isNewPosition&& !isProInterface && <OrdersWarning />}
                <TradeMetrics
                  isNewPosition={isNewPosition}
                  isProInterface={isProInterface}
                  market={market}
                  positionDelta={isNewPosition && hasPosition ?
                    {
                      collateralDelta: 0n,
                      positionDelta: 0n
                    } : {
                      collateralDelta: positionDelta.collateralDelta,
                      positionDelta: positionDelta.positionDelta
                    }
                  }
                  positionDetails={position}
                  leverage={parseFloat(leverage)}
                  isLimitOrder={orderTypes.includes(OrderTypes.limit)}
                  limitOrderPrice={
                    orderTypes.includes(OrderTypes.limit) && limitOrderError === "" ? Big6Math.fromFloatString(limitOrderPrice) : undefined
                  }
                />
                {!currentGeolocation.isRestricted() ? (
                  <>
                    {isNewPosition && (isOpenRestricted || hasCollateral) &&  (
                      <div className="p-2">
                        {isOpenRestricted ? (
                          <span className="text-red">
                            {t("error.position-opened", { side: capitalize(position?.nextSide || "") } )}
                          </span>
                        ) : (
                          <span className="text-red">
                            {t("error.collateral-deposited")}
                          </span>
                        )}       
                      </div>
                    )}
                    {!marketFactoryApproved && (
                      <ApproveOperatorButton isMarket={true} onHide={() => {}} />
                    )}
                    {isNewPosition && marketFactoryApproved && approvalInfo.needsApproval && (
                      <ApproveButton
                        approvalType="market"
                        contractAddres={marketAddress}
                        currentAllowance={formatBig6(usdcAllowance)}
                        amountToApprove={approvalInfo.approvalAmount}
                        onSuccess={() => {
                          refetchBalances()
                        }}
                      />
                    )}
                    {isNewPosition && orderTypes.includes(OrderTypes.market) && marketFactoryApproved && !approvalInfo.needsApproval && (
                      <ModifyPositionButton
                        marketAddress={marketAddress}
                        label={t("open-position")}
                        txHistoryLabel="Open Position"
                        positionSide={positionSide}
                        collateralDelta={isCollateralValid ? positionDelta.collateralDelta : 0n}
                        positionAbs={isAmountValid ? positionDelta.positionDelta : 0n}
                        interfaceFee={interfaceFee.interfaceFee}
                        stopLossPrice={stopLossError === "" ? Big6Math.fromFloatString(stopLossPrice) : 0n}
                        takeProfitPrice={takeProfitError === "" ? Big6Math.fromFloatString(takeProfitPrice) : 0n}
                        settlementFee={positionDelta.positionDelta > 0n ? settlementFee : 0n}
                        disabled={!canModifyPosition || hasPosition || hasCollateral || !isUsingSupportedChain}
                        successMessage={t("notification.position-opened-1")}
                        onSuccess={() => resetFields()}
                      />
                    )}
                    {isNewPosition && orderTypes.includes(OrderTypes.limit) && marketFactoryApproved && !approvalInfo.needsApproval && (
                      <PlaceOrderButton
                        label={t("place-limit-order")}
                        marketAddress={marketAddress}
                        orderType={OrderTypes.limit}
                        positionSide={positionSide}
                        collateralDelta={isCollateralValid ? positionDelta.collateralDelta : 0n}
                        positionAbs={isAmountValid ? positionDelta.positionDelta : 0n}
                        limitPrice={limitOrderError === "" ? Big6Math.fromFloatString(limitOrderPrice) : 0n}
                        stopLossPrice={stopLossError === "" ? Big6Math.fromFloatString(stopLossPrice) : 0n}
                        takeProfitPrice={takeProfitError === "" ? Big6Math.fromFloatString(takeProfitPrice) : 0n}
                        selectedLimitComparison={selectedLimitComparison}
                        disabled={!canModifyPosition || hasPosition || hasCollateral || !isUsingSupportedChain}
                        successMessage={t("notification.order-placed")}
                        onSuccess={() => {
                          resetFields()
                          addOrRemoveOrderType(OrderTypes.market, true)
                        }}
                      />
                    )}
                  </>
                ) : (
                  <GeoblockMessage />
                )}
              </>
            ) : (
              <div className="market-info">  
                <MarketInfo />
              </div>    
            )}  
          </div>
        </div>
      </div>
    </div>    
  )    
};

export default TradeForm;
