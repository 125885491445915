import React from "react"
import { Button, Image, Stack } from "react-bootstrap"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { formatBig6, formatBig18, SupportedAsset } from "@perennial/sdk"
import { format } from "date-fns"
import { useMediaQuery } from "react-responsive"
import { AllSTIPSeasons, STIPDropParams, STIPSeasonNumber } from "../../../constants/stipDrop"
import { NumericFormat } from "react-number-format"
import { AssetMetadata } from "../../../constants/markets"
import { CustomTooltip } from "../../common"


const ClaimBox = ({
  rewards,
  onClaim,
  selectedSeason,
  currentSeason,
  rewardClaimed,
}: {
  rewards: bigint
  selectedSeason: STIPSeasonNumber
  onClaim: () => void
  currentSeason: STIPSeasonNumber
  rewardClaimed: boolean
}) => {
  const { t } = useTranslation()
  const previousSeason = selectedSeason - 1 >= 0 ? STIPDropParams[(currentSeason - 1) as STIPSeasonNumber] : undefined
  const selectedSeasonEnd = STIPDropParams[selectedSeason].to

  // const hasRewardsData = !!STIPDropParams[selectedSeason].blobUrl
  const isCurrentSeason = selectedSeasonEnd.getTime() > Date.now()
  const isPending = selectedSeason === currentSeason -1 &&  !previousSeason?.blobUrl 

  return (
    <>
      <div className="reward-info-box">
        <div className="row-inner">
          {!isCurrentSeason && !isPending ? (
            <>
              <NumericFormat
                className="number text-green"
                displayType="text"
                value={formatBig18(rewards)}
                thousandSeparator=","
                decimalScale={4}
              />
              <Image src={AssetMetadata[SupportedAsset.arb].icon} width={20} height={20} />
            </>
          ) : (
            <span className="number text-green">
              {isCurrentSeason ? t("pending") : "-"}
            </span>  
          )}          
        </div>
        <h6>{t("earn-rewards.your-rewards")}</h6>
      </div>
      {isPending || isCurrentSeason ? (
        <CustomTooltip
          id="tt-arb-reward"
          msg={isCurrentSeason ? t("earn-rewards.tooltip-1") : t("earn-rewards.tooltip-2")}
          showIcon={false}
        >
          <Button className="reward-info-box" disabled={true}>
            {t("claim")}
          </Button>
        </CustomTooltip>  
      ) : (
        <Button className="reward-info-box" onClick={onClaim} disabled={isPending || rewardClaimed}>
          {rewardClaimed ? t("claimed") : t("claim")}
        </Button>
      )}
    </>  
  )
}

export const RewardSeasons = ({
  onChangeSeason,
  season,
  rewards,
  currentSeason,
  rewardClaimed,
  onClaim,
  previousClaimable,
}: {
  onChangeSeason: (season: STIPSeasonNumber) => void
  season: STIPSeasonNumber
  currentSeason: STIPSeasonNumber
  rewards: bigint
  rewardClaimed: boolean
  onClaim: () => void
  previousClaimable?: boolean
  }) => {
  const { t } = useTranslation()
  const seasonParams = STIPDropParams[season]
  const isTablet = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 850px)'
  })
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)'
  })

  return (
    <Stack className="reward-seasons" direction="vertical" gap={1}>
      <Stack className="seasons-pagination" direction="horizontal">
        <Button
          disabled={season <= 1}
          onClick={() => {
            onChangeSeason((season - 1) as STIPSeasonNumber)
          }}
        >
           <FaAngleLeft size={30} />
        </Button>
        <div className="flex-row">
          <h5>
            {t("earn-rewards.season")} {season}
          </h5>
        </div>
        <Button
          disabled={season === Math.max(...AllSTIPSeasons)}
          onClick={() => {
            onChangeSeason((season + 1) as STIPSeasonNumber)
          }}
        >
          <FaAngleRight size={30} />
        </Button>
      </Stack>
      <Stack className="seasons-info" direction={isMobile ? "vertical" : "horizontal"}>
        <div className="reward-info-box">
          <span className="date">
            {!isTablet
              ? <>{format(seasonParams.from, 'P')}{" "}{format(seasonParams.from, 'ha')}</>
              : <>{format(seasonParams.from, 'P').replace("/2023", "")}{" "}{format(seasonParams.from, 'ha')}</>}
          </span>
          <h6>{t("earn-rewards.begins")}</h6>
        </div>
        <div className="reward-info-box">
          <span className="date">
            {!isTablet
              ? <>{format(seasonParams.to, 'P')}{" "}{format(seasonParams.to, 'ha')}</>
              : <>{format(seasonParams.to, 'P').replace("/2023", "")}{" "}{format(seasonParams.to, 'ha')}</>}            
          </span>
          <h6>{t("earn-rewards.ends")}</h6>
        </div>
        <div className="reward-info-box">
          <div className="row-inner">
            <NumericFormat
              className="number text-green"
              displayType="text"
              value={formatBig6(seasonParams.totalRewards)}
              thousandSeparator=","
              decimalScale={0}
            />
            <Image src={AssetMetadata[SupportedAsset.arb].icon} width={20} height={20} />
          </div>  
          <h6>{t("earn-rewards.distribution")}</h6>
        </div>
        <ClaimBox rewards={rewards} onClaim={onClaim} selectedSeason={season} currentSeason={currentSeason} rewardClaimed={rewardClaimed} />
      </Stack>
    </Stack>    
  )  
}
