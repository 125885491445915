import { AbstractProvider, Contract } from "ethers";
import collateralAbi from "./abi/collateralAbi.json";
import lensAbi from "./abi/lensAbi.json";
import liquidityRewardsAbi from "./abi/liquidityRewardsAbi.json";
import multiInvokerAbi from "./abi/multiInvokerAbi.json";
import productAbi from "./abi/productAbi.json";
import cashbackHandlerAbi from "./abi/cashbackHandlerAbi.json";
import reserveAbi from "./abi/reserveAbi.json";
import vaultAbi from "./abi/vaultAbi.json";

import { LensProductSnapshotAbi } from "./abi/LensAbi";


const CHAINS = {
  ARBITRUM: 42161,
  ARBITRUM_GOERLI: 421613
};


export const multiInvokerContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xe72E82b672d7D3e206327C0762E9805fbFCBCa92",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x86ccFAdd81433929e04e32C056A2eEFc08359B60",
  },
  abi: multiInvokerAbi,
};

export const lensContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x1593318424df66128cb7d0c5574B1283C3A74C3d",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x19890Cf5C9A0B8d2F71eB71347d126b6F7d78B76",
  },
  abi: lensAbi,
};

export const arbVaultContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x1960628db367281B1a186dD5B80B5dd6978F016F",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x1960628db367281B1a186dD5B80B5dd6978F016F",
  },
  abi: vaultAbi,
};

export const arbLongMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x5E660B7B8357059241EAEc143e1e68A5A108D035",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x5E660B7B8357059241EAEc143e1e68A5A108D035",
  },
  abi: productAbi,
};

export const arbShortMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x4e67a8a428206Af5a6AC00Ba08a67Ce827182985",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x4e67a8a428206Af5a6AC00Ba08a67Ce827182985",
  },
  abi: productAbi,
};

export const ethVaultContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x5A572B5fBBC43387B5eF8de2C4728A4108ef24a6",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x5A572B5fBBC43387B5eF8de2C4728A4108ef24a6",
  },
  abi: vaultAbi,
};

export const ethLongMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x260422C091Da8C88ef21f5D1112AB43aa94787cd",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x260422C091Da8C88ef21f5D1112AB43aa94787cd",
  },
  abi: productAbi,
};

export const ethShortMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x5b99d122aF97bA012aA237Bd01577278BfAaff1e",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x5b99d122aF97bA012aA237Bd01577278BfAaff1e",
  },
  abi: productAbi,
};

export const perpeVaultContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xB84b9D427Fb30eD3641afAc2e07B8C471bb0C6Ee",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0xB84b9D427Fb30eD3641afAc2e07B8C471bb0C6Ee",
  },
  abi: vaultAbi,
};

export const perpeLongMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xc555E66b017fFCe7b17F64b2DDa186210364FD0d",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0xc555E66b017fFCe7b17F64b2DDa186210364FD0d",
  },
  abi: productAbi,
};

export const perpeShortMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x741BFeF86F612b43016430312E0f33Efce9Af8C5",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x741BFeF86F612b43016430312E0f33Efce9Af8C5",
  },
  abi: productAbi,
};

export const tcapVaultContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xEa281a4c70Ee2ef5ce3ED70436C81C0863A3a75a",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0xE4c55aad5b60f0C1983De54f00D28Fc887abc472",
  },
  abi: vaultAbi,
};

export const tcapLongMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x1cD33f4e6EdeeE8263aa07924c2760CF2EC8aAD0",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0xe86E16804CC7386bcdd36755C2115601B356dA92",
  },
  abi: productAbi,
};

export const tcapShortMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x4243b34374cfB0a12f184b92F52035d03d4f7056",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x18a0A5fBEFca5362D2A279e0819195C10361a03c",
  },
  abi: productAbi,
};

export const productCollateralContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xAF8CeD28FcE00ABD30463D55dA81156AA5aEEEc2",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0xf3E6057474199179D9eFb733f2cf47F41Cc8a1ED",
  },
  abi: collateralAbi,
};

export const reserveContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x0d49c416103Cbd276d9c3cd96710dB264e3A0c27",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x0d49c416103Cbd276d9c3cd96710dB264e3A0c27",
  },
  abi: reserveAbi,
};

export const liquidityRewardsContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x938F145D5f3ABf681618Dcc5c71f095B870747Ba",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x393024Ba0ECB3a684C9D0bc2BC023B8DD9Ed21c1",
  },
  abi: liquidityRewardsAbi,
};

export const liquidityRewardsContractCtx = {
  [CHAINS.ARBITRUM]: {
    address: "0x944Cfb09bc40ce43EC7f471297Ef83a325BA78Ff",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x393024Ba0ECB3a684C9D0bc2BC023B8DD9Ed21c1",
  },
  abi: liquidityRewardsAbi,
};

export const rebateHandlerContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x798D986239333d4fa9d7bDf1b143C3F022Ae580B",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0xD4Ff33F926D84645a5Fc5B1dcbF59f3Cea77b39A",
  },
  abi: cashbackHandlerAbi,
};


export const getVaultContract = (provider: AbstractProvider, vaultAddress: string): Contract => {
  return new Contract(vaultAddress, tcapVaultContract.abi, provider);
};

export const getLensContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(lensContract[chainId].address, lensContract.abi, provider);
};

export const getProductContract = (provider: AbstractProvider, productAddress: string): Contract => {
  return new Contract(productAddress, productAbi, provider);
};

export const getCollateralContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(productCollateralContract[chainId].address, collateralAbi, provider);
};

export const getMultiInvokerContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(multiInvokerContract[chainId].address, multiInvokerContract.abi, provider);
};

export const getLiquidityRewardsContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(liquidityRewardsContract[chainId].address, liquidityRewardsContract.abi, provider);
};

export const getLiquidityRewardsContractCtx = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(liquidityRewardsContractCtx[chainId].address, liquidityRewardsContractCtx.abi, provider);
};

export const getRebateHandlerContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(rebateHandlerContract[chainId].address, rebateHandlerContract.abi, provider);
};



export const getLensProductContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(lensContract[chainId].address, LensProductSnapshotAbi, provider);
};
