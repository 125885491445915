import { Big6Math, chainIdToChainMap, HermesClient, PositionSide } from '@perennial/sdk';
import {
  arbitrum,
  arbitrumSepolia,
  base,
} from "viem/chains"
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  trustWallet,
  okxWallet
} from "@rainbow-me/rainbowkit/wallets"
import { createConfig } from "wagmi"
import { Address, Chain, createPublicClient, http } from "viem"


export const chains = [arbitrum, arbitrumSepolia, base];

export const SupportedChainIds = [
  arbitrum.id,
  arbitrumSepolia.id,
  base.id
] as const;

export type SupportedChainId = (typeof SupportedChainIds)[number];

export const Chains: { [chainId in SupportedChainId]: Chain } = {
  [arbitrum.id]: arbitrum,
  [arbitrumSepolia.id]: arbitrumSepolia,
  [base.id]: base,
};

export const isSupportedChain = (chainId?: number) =>
  chainId !== undefined &&
  SupportedChainIds.includes(chainId as SupportedChainId);

export const isTestnet = (chainId?: number) =>
  chainId === arbitrumSepolia.id 

export const DefaultChain = chains[0];

export const GraphUrls: { [chainId in SupportedChainId]: string } = {
  [arbitrum.id]: process.env.REACT_APP_GRAPH_URL_ARBITRUM ?? "",
  [arbitrumSepolia.id]: process.env.REACT_APP_GRAPH_URL_ARBITRUM_SEPOLIA ?? "",
  [base.id]: process.env.REACT_APP_GRAPH_URL_ARBITRUM ?? "",
};

export const ExplorerNames: { [chainId in SupportedChainId]: string } = {
  [arbitrum.id]: arbitrum.blockExplorers.default.name,
  [arbitrumSepolia.id]: arbitrumSepolia.blockExplorers.default.name,
  [base.id]: base.blockExplorers.default.name,
}

export const ExplorerURLs: { [chainId in SupportedChainId]: string } = {
  [arbitrum.id]: arbitrum.blockExplorers.default.url,
  [arbitrumSepolia.id]: arbitrumSepolia.blockExplorers.default.url,
  [base.id]: base.blockExplorers.default.url,
};

export const interfaceFeeBps: {
  [chainId in SupportedChainId]: { feeAmount: { [key in PositionSide]: bigint }; feeRecipientAddress: Address }
} = {
  [arbitrum.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  },
  [arbitrumSepolia.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n,
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  },
  [base.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n,
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  }, 
}

const WalletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_ID || "";

export const AlchemyKey = process.env.REACT_APP_ALCHEMY_KEY || ""
export const AlchemySepoliaKey = process.env.REACT_APP_ALCHEMY_ARBITRUM_KEY_SEPOLIA || ""
export const AlchemyBaseKey = process.env.REACT_APP_ALCHEMY_KEY_SEPOLIA || ""


export const rpcUrls: { [chainId in SupportedChainId]: string } = {
  [arbitrum.id]: `https://arb-mainnet.g.alchemy.com/v2/${AlchemyKey}`,
  [arbitrumSepolia.id]: `https://arb-sepolia.g.alchemy.com/v2/${AlchemySepoliaKey}`,
  [base.id]: `https://base-mainnet.g.alchemy.com/v2/${AlchemyBaseKey}`,
}

// coinbaseWallet.preference = 'all';

const connectors = connectorsForWallets([{
    groupName: "Recommended",
    wallets: [metaMaskWallet, walletConnectWallet, rainbowWallet, coinbaseWallet, okxWallet, trustWallet],
  }],
  {
    appName: 'Cryptex Finance',
    projectId: WalletConnectProjectId,
  }
);

/* const { connectors } = getDefaultWallets({
  appName: 'Perennial Interface V2',
  projectId: WalletConnectProjectId,
}) */

export const wagmiConfig = createConfig({
  ssr: false,
  chains: [arbitrum, arbitrumSepolia, base],
  transports: {
    [arbitrum.id]: http(rpcUrls[arbitrum.id]),
    [arbitrumSepolia.id]: http(rpcUrls[arbitrumSepolia.id]),
    [base.id]: http(rpcUrls[base.id]),
  },
  connectors,
  batch: {
    multicall: true,
  },
})

export const PythMainnetUrl = "https://perennial.rpc.p2p.world"
export const PythTestnetUrl = "https://perennial.rpc.p2p.world"
export const PythDataFeedUrl = process.env.REACT_APP_PUBLIC_PYTH_DATAFEED_URL
export const BackupPythClient = new HermesClient(
  `${typeof window !== 'undefined' ? window.location.origin : 'https://app.perennial.finance'}/api/pyth`,
  {
    timeout: 30000,
  },
)

export const metamaskTxRejectedError = "MetaMask Tx Signature: User denied transaction signature."


export const getPublicClient = (chainId: SupportedChainId) => { 
  return createPublicClient({
    batch: {
      multicall: true,
    },
    chain: chainIdToChainMap[chainId],
    transport: http(rpcUrls[chainId])
  })
}