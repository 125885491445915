import React from "react"
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"


type props = {
  currentInterface: number;
  setCurrentInterface?: (value: number) => void;
}

const PLInterfaceToggle = ({ currentInterface, setCurrentInterface }: props) => {
  const { t } = useTranslation()
  const provideVersions = [
    { name: t("vaults"), value: 0 },
    { name: t("individual"), value: 1 },
  ]

  return (
    <ToggleButtonGroup
      type="radio"
      name="pl-versions" defaultValue={currentInterface}
      className="pl-version-chooser"
    >
      {provideVersions.map((action, idx) => (
        <ToggleButton
          key={idx}
          id={`plint-${idx}`}
          type="radio"
          name="radio"
          value={action.value}
          checked={currentInterface === action.value}
          onChange={(e) => {
            if (setCurrentInterface) {
              setCurrentInterface(parseInt(e.currentTarget.value))
            }
        }}
        >
          {action.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );     
}

export default PLInterfaceToggle
