import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { Address, zeroAddress } from 'viem'
import { usePublicClient, useWalletClient } from 'wagmi'
import { useTranslation } from 'react-i18next'

import { metamaskTxRejectedError } from '../../constants/network'
import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'

import { useAddress, useChainId } from '../network'
import { useMarketOracles2, useMarketSnapshots2 } from './chain'
import { errorNotification, useTransactionToasts } from '../../components/common'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'


export const useMarketTransactions2 = (productAddress: Address) => {
  const sdk = usePerennialSDKContext()
  const addRecentTransaction = useAddRecentTransaction()
  const toasts = useTransactionToasts()
  const copy = useMarketTransactionCopy()

  const { data: marketOracles } = useMarketOracles2()
  const { data: marketSnapshots } = useMarketSnapshots2()
  const { address } = useAddress()

  return {
    getUSDCAllowance: async () => sdk.operator.read.usdcAllowance({ address: address ?? zeroAddress }),
    onPlaceOrder: (
      args: Omit<Parameters<typeof sdk.markets.write.placeOrder>[0], 'marketAddress' | 'address'>,
      onError?: (err: any) => void,
    ) => {
      try {
        return sdk.markets.write.placeOrder({
          ...args,
          address: address ?? zeroAddress,
          marketAddress: productAddress,
          marketSnapshots,
          marketOracles,
        })
      } catch (err: any) {
        onError?.(err)
      }
    },
    onApproveUSDC: async (args: Parameters<typeof sdk.operator.write.approveUSDC>[0]) => {
      const hash = await sdk.operator.write.approveUSDC(args)
      
      await toasts.waitForTransactionAlert(hash, { successMsg: copy.approveUSDC })
      addRecentTransaction({
        hash,
        description: "Approved USDC.e for market",
      })
      const newAllowance = await sdk.operator.read.usdcAllowance({ address: address ?? zeroAddress })

      return { hash, newAllowance }
    },
    onModifyPosition: (
      args: Omit<Parameters<typeof sdk.markets.write.modifyPosition>[0], 'marketAddress' | 'address'>,
      onError?: (err: any) => void,
    ) => {
      try {
        return sdk.markets.write.modifyPosition({
          ...args,
          address: address ?? zeroAddress,
          marketAddress: productAddress,
          marketSnapshots,
          marketOracles,
        })
      } catch (err: any) {
        onError?.(err)
      }
    },
    onSubmitVaa: () => {
      if (!marketOracles) throw new Error('Market oracles data not available')
      return sdk.markets.write.submitVaa({
        marketAddress: productAddress,
        marketOracles,
      })
    },
  }
}

export const useCancelOrder = () => {
  const copy = useMarketTransactionCopy()
  const sdk = usePerennialSDKContext()
  const chainId = useChainId()

  const { address } = useAddress()
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient({ chainId })

  const queryClient = useQueryClient()
  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => ['openOrders'].includes(queryKey.at(0) as string) && queryKey.includes(chainId),
      }),
    [queryClient, chainId],
  )

  const onCancelOrder = async (orderDetails: { market: Address, nonce: bigint }[]) => {
    if (!address || !chainId || !walletClient) {
      return
    }

    try {
      const hash = await sdk.markets.write.cancelOrder({ orderDetails: orderDetails })

      publicClient
        ?.waitForTransactionReceipt({ hash })
        .then(() => refresh())
        .catch(() => null)
      // Refresh after a timeout to catch missed events
      setTimeout(() => refresh(), 15000)
      return hash
    } catch (err: any) {
      if (err.details !== metamaskTxRejectedError) {
        errorNotification(copy.errorCancelingOrder)
      }
      console.error(err)
      throw new Error(err)
    }
  }

  return onCancelOrder
}

const useMarketTransactionCopy = () => {
  const { t } = useTranslation()
  return {
    approveUSDC: t("notification.approved", { token: "USDC.e" }),
    errorCancelingOrder: t("notification.error-canceling-order"),
  }
}

