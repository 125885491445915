import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  ChainMarkets,
  MarketSnapshot,
  MarketSnapshots,
  PositionSide,
  SupportedAsset,
  UserMarketSnapshot,
} from '@perennial/sdk'

// import { AdjustmentModalProps } from '@/components/pages/Trade/TradeForm/components/AdjustPositionModal'
import { AssetMetadata } from '../constants/markets'
import { DefaultChain } from '../constants/network'
import { SupportedChainId } from '../constants/network'
import { AdjustmentModalProps } from '../components/Trade/types';
import { useMarketSnapshots2 } from '../hooks/markets2'
import { useChainId } from '../hooks/network'

export enum PositionsTab {
  current,
  all,
  history,
}

export type OverrideValues = Pick<
  AdjustmentModalProps,
  'market' | 'position' | 'asset' | 'orderValues' | 'positionSide' | 'positionDelta'
>

type MarketContextType = {
  chainId: SupportedChainId
  orderDirection: PositionSide.long | PositionSide.short
  setOrderDirection: (orderDirection: PositionSide.long | PositionSide.short) => void
  assetMetadata: (typeof AssetMetadata)[SupportedAsset]
  selectedMarket: SupportedAsset
  setSelectedMarket: (asset: SupportedAsset) => void
  selectedMakerMarket: SupportedAsset
  setSelectedMakerMarket: (makerMarket: SupportedAsset) => void
  activePositionTab: PositionsTab
  setActivePositionTab: (tab: PositionsTab) => void
  isMaker: boolean
  // V2
  snapshots2?: MarketSnapshots
  userCurrentPosition?: UserMarketSnapshot
  selectedMarketSnapshot2?: MarketSnapshot
  setOverrideValues: (overrideValues?: OverrideValues) => void
  overrideValues?: OverrideValues
  manualCommitment: boolean
}

const MarketContext = createContext<MarketContextType>({
  chainId: DefaultChain.id,
  orderDirection: PositionSide.long,
  setOrderDirection: (orderDirection: PositionSide.long | PositionSide.short) => {},
  selectedMarket: SupportedAsset.eth,
  assetMetadata: AssetMetadata[SupportedAsset.eth],
  setSelectedMarket: (asset: SupportedAsset) => {},
  setSelectedMakerMarket: (makerMarket: SupportedAsset) => {},
  selectedMakerMarket: SupportedAsset.eth,
  activePositionTab: PositionsTab.current,
  setActivePositionTab: (tab: PositionsTab) => {},
  isMaker: false,
  // V2
  snapshots2: undefined,
  userCurrentPosition: undefined,
  selectedMarketSnapshot2: undefined,
  setOverrideValues: (overrideValues?: OverrideValues) => {},
  overrideValues: undefined,
  manualCommitment: false,
})

export const MarketProvider = ({ children, isMaker }: { children: React.ReactNode; isMaker?: boolean }) => {
  const chainId = useChainId()
  const [selectedMarket, _setSelectedMarket] = useState<SupportedAsset>(SupportedAsset.eth)
  const [selectedMakerMarket, _setSelectedMakerMarket] = useState<SupportedAsset>(SupportedAsset.eth)
  const [orderDirection, _setOrderDirection] = useState<PositionSide.long | PositionSide.short>(PositionSide.long)
  const [activePositionTab, setActivePositionTab] = useState<PositionsTab>(PositionsTab.current)
  const [overrideValues, setOverrideValues] = useState<OverrideValues | undefined>(undefined)
  const [manualCommitment, setManualCommitment] = useState<boolean>(false)
  const { data: snapshots2 } = useMarketSnapshots2()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const marketFromParams = urlParams.get('market')?.toLowerCase()
    const makerMarketFromParams = urlParams.get('makerMarket')?.toLowerCase() as SupportedAsset
    const directionFromParams = urlParams.get('direction')?.toLowerCase()
    if (marketFromParams && marketFromParams in ChainMarkets[chainId]) {
      _setSelectedMarket(marketFromParams as SupportedAsset)
    } else {
      const marketFromLocalStorage = localStorage.getItem(`${chainId}_market`)
      const makerMarketFromLocalStorage = localStorage.getItem(`${chainId}_makerMarket`)

      if (marketFromLocalStorage && marketFromLocalStorage in ChainMarkets[chainId]) {
        _setSelectedMarket(marketFromLocalStorage as SupportedAsset)
      }

      if (makerMarketFromLocalStorage && makerMarketFromLocalStorage in ChainMarkets[chainId]) {
        _setSelectedMakerMarket(makerMarketFromLocalStorage as SupportedAsset)
      }
    }

    if (makerMarketFromParams && makerMarketFromParams in ChainMarkets[chainId]) {
      _setSelectedMakerMarket(makerMarketFromParams as SupportedAsset)
    }

    if (makerMarketFromParams && makerMarketFromParams in ChainMarkets[chainId]) {
      _setSelectedMakerMarket(makerMarketFromParams as SupportedAsset)
    }

    if (directionFromParams && [PositionSide.long, PositionSide.short].includes(directionFromParams as PositionSide)) {
      _setOrderDirection(directionFromParams as PositionSide.long | PositionSide.short)
    } else {
      const directionFromLocalStorage = localStorage.getItem(`${chainId}_orderDirection`)

      if (
        directionFromLocalStorage &&
        [PositionSide.long, PositionSide.short].includes(directionFromLocalStorage as PositionSide)
      ) {
        _setOrderDirection(directionFromLocalStorage as PositionSide.long | PositionSide.short)
      }
    }
  },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const manualCommitmentFromParams = urlParams.get('manualCommitment')?.toLowerCase()

    if (manualCommitmentFromParams === 'true' && !manualCommitment) setManualCommitment(true)

    if (!(selectedMarket in ChainMarkets[chainId])) {
      _setSelectedMarket((Object.keys(ChainMarkets[chainId])[0] as SupportedAsset) ?? SupportedAsset.eth)
    }

    if (!(selectedMakerMarket in ChainMarkets[chainId])) {
      _setSelectedMakerMarket(SupportedAsset.eth)
    }
  }, [chainId, selectedMarket, selectedMakerMarket, manualCommitment])

  const setSelectedMarket = (asset: SupportedAsset) => {
    localStorage.setItem(`${chainId}_market`, asset)
    _setSelectedMarket(asset)
  }

  const setSelectedMakerMarket = (makerMarket: SupportedAsset) => {
    localStorage.setItem(`${chainId}_makerMarket`, makerMarket)
    _setSelectedMakerMarket(makerMarket)
  }

  const setOrderDirection = (orderDirection: PositionSide.long | PositionSide.short) => {
    localStorage.setItem(`${chainId}_orderDirection`, orderDirection)
    _setOrderDirection(orderDirection)
  }

  return (
    <MarketContext.Provider
      value={{
        chainId,
        orderDirection,
        setOrderDirection,
        selectedMarket,
        setSelectedMarket,
        snapshots2,
        assetMetadata: AssetMetadata[isMaker ? selectedMakerMarket : selectedMarket],
        activePositionTab,
        setActivePositionTab,
        setSelectedMakerMarket,
        selectedMakerMarket,
        isMaker: isMaker ?? false,
        userCurrentPosition: snapshots2?.user?.[isMaker ? selectedMakerMarket : selectedMarket],
        selectedMarketSnapshot2: snapshots2?.market?.[isMaker ? selectedMakerMarket : selectedMarket],
        setOverrideValues,
        overrideValues,
        manualCommitment,
      }}
    >
      {children}
    </MarketContext.Provider>
  )
}

export const useMarketContext = () => {
  const context = useContext(MarketContext)
  if (context === undefined) {
    throw new Error('useMarketContext must be used within a MarketProvider')
  }
  return context
}
