import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import Flag from 'react-flagkit'
import { LanguageMetadata, SupportedLanguage } from '../../constants/localization'


export const LanguageDropdown = () => {
  const { i18n } = useTranslation()
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en)  as SupportedLanguage

  return (
    <Dropdown className="language-dropdown" onSelect={(eventKey) => i18n.changeLanguage(eventKey || SupportedLanguage.en)}>
      <Dropdown.Toggle>
        <Flag
          country={
            LanguageMetadata[currentLanguage] ? LanguageMetadata[currentLanguage].country : SupportedLanguage.en
          }
          size={29}
          className="flag"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
          {Object.keys(LanguageMetadata).map((lang) => (
            <Dropdown.Item key={lang} eventKey={lang}>
              <Flag country={LanguageMetadata[lang as SupportedLanguage].country} size={24} className="flag" />
              <span className="lang-text">{LanguageMetadata[lang as SupportedLanguage].name}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}


export const LanguageModal = ({ show, onHide } : { show: boolean, onHide: () => void }) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en) as SupportedLanguage
  
  return (
    <Modal show={show} onHide={onHide} className="language-modal">
      <Modal.Header closeButton>
        <h5 className="bold">{t("choose-language")}</h5>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(LanguageMetadata).map((lang) => (
          <Button
            key={lang}
            onClick={() => {
              i18n.changeLanguage(lang)
              onHide()
            }}
            className={currentLanguage === lang ? "btn-current-lang" : ""}
          >
            <Flag country={LanguageMetadata[lang as SupportedLanguage].country} size={26} className="flag" />
            <span className="lang-text ">
              {LanguageMetadata[lang as SupportedLanguage].name}
            </span>
          </Button>
        ))}
      </Modal.Body>
    </Modal>
  )
}