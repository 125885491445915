import React, { useState } from "react";


export interface IVersionContext { 
  version: string,
  setCurrentVersion: (currentVersion: string) => void;
  getPathPrefix: () => string;
  isV2_5: () => boolean;
};

export const DEFAULT_VERSION = {
  version: "2.5",
  setCurrentVersion: () => {},
  getPathPrefix: () => "/",
  isV2_5: () => true,
}

export const versionContext = React.createContext<IVersionContext>(DEFAULT_VERSION);


export const useVersionContext = (currentVersion: string): IVersionContext => {
  const [version, setVersion] = useState(currentVersion);
  const setCurrentVersion = React.useCallback((currentVersion: string): void => {
    setVersion(currentVersion);
  }, []);
  
  const isV2_5 = (): boolean => {
    return version === "2.5";
  }
  const getPathPrefix = (): string => {
    return isV2_5() ? "/" : "/v2/"
  }

  return {
    version,
    setCurrentVersion,
    getPathPrefix,
    isV2_5,
  };
};
