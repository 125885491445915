import React, { useContext, useEffect, useMemo, useState } from "react"
import { Button, ButtonGroup, Spinner, ToggleButton } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"

import { productContext } from "../../states"
import {
  useActiveProvider,
  convertAssetsToShares,
  useTokensBalance,
  useVaultSnapshot,
  useVaultTransactions
} from "../../hooks"
import { Markets } from "../markets/Markets"
import { CollateralInput } from "../CollateralInput"
import { ApproveCollateralButton } from "../ActionsButtons"
import { saveTransactionToDB } from "../../db/transactions"
import { LiquidityCapacity } from "../common"
import { CustomTooltip, errorNotification, notifyUser } from "../../../components/common"
import { PriceUpdateWarning } from "../common"
import { ApproveType, ERROR_USER_REJECTED_TRAN } from "../../utils/constants"
import { RewardsType, VaultAllowances, VaultUserSnapshot } from "../../utils/types"
import { InvokerAction } from "../../helpers"
import {
  BigMath,
  addPositions,
  calculateExposure,
  calculateFunding,
  calculateLeverageBN,
  hasMaxDecimalsAllowed,
  isPositiveNumber,
  isSupportedV1Chain,
  nextPosition,
  vaultHasLPRewards,
} from "../../utils/utils"


const provideActions = [
  { name: "Deposit", value: 0 },
  { name: "Withdraw", value: 1 },
]

type props = {
  allowances: VaultAllowances | undefined;
  rewardsInfo: RewardsType | undefined;
  loadingUserData: boolean;
  userVaultSnapshot: VaultUserSnapshot | undefined;
  rewardsApr: number;
  mutateData: () => void;
}

export const Provide = ({ allowances, rewardsInfo, loadingUserData, userVaultSnapshot, rewardsApr, mutateData }: props) => {
  const { t } = useTranslation()
  const { chainId, currentChainId, userAccount: ownerAddress, multiCallProvider } = useActiveProvider()
  const product = useContext(productContext)
  const { data: tokensBalance } = useTokensBalance()
  const { data: vaultSnapshot, mutate: mutateVault } = useVaultSnapshot(
    product.vaultContract[chainId].address, product.productContract[chainId].address
  )
  const [currentAction, setCurrentAction] = useState(provideActions[0].value)
  const [collateralAmount, setCollateralAmount] = useState("0")
  const [collateralNumber, setCollateralNumber] = useState(0)
  const [sharesAmount, setSharesAmount] = useState(0n)
  const [writingTran, setWritingTran] = useState(false)
  const [delta, setDelta] = useState(0)
  const [utilizationRate, setUtilizationRate] = useState({
    global: 0,
    long: 0,
    short: 0,
  })
  const [exposure, setExposure] = useState(0)
  const [funding, setFunding] = useState(0n)
  const [showBalanceDetail, setShowBalanceDetail] = useState(false);
  const [showAprDetail, setShowAprDetail] = useState(false);
  const [showURDetail, setShowURDetail] = useState(false);

  const { collateralBalanceRaw } = useMemo(() => {    
    const collateralBalanceRaw = tokensBalance ? tokensBalance.usdcBalance : 0n;

    return {
      collateralBalanceRaw
    }
  }, [tokensBalance])

  const { usdcAllowance, sharesAllowance, dsuAllowance } = useMemo(() => {
    const usdcAllowance = allowances
      ? parseFloat(ethers.formatUnits(allowances.usdc, 6))
      : 0
    
    const sharesAllowance = allowances ? allowances.shares : 0n;
    const dsuAllowance = allowances ? allowances.dsuAllowance : 0n;

    return {
      usdcAllowance,
      sharesAllowance,
      dsuAllowance,
    }
  }, [allowances])

  const {
    hasLPRewards,
    assetsBalance,
    sharesBalance,
    pendingDeposits,
    claimableAmount,
    pendingRedemptionAmount,
    maxAvailableToWithdraw,
  } = useMemo(() => {
    const hasLPRewards = vaultHasLPRewards(chainId, product.vaultContract[chainId].address);

    const assetsBalance = userVaultSnapshot
      ? userVaultSnapshot?.assets
      : 0n

    const sharesBalance = userVaultSnapshot
      ? userVaultSnapshot?.balance
      : 0n
    
    const pendingDeposits = userVaultSnapshot
      ? userVaultSnapshot?.pendingDepositAmount
      : 0n;

    const claimableAmount = userVaultSnapshot
      ? userVaultSnapshot?.claimable
      : 0n
    
    let pendingRedemptionAmount = 0n;
    if (vaultSnapshot && userVaultSnapshot) {
      const supplyAtLatestVersion = vaultSnapshot.totalSupply + vaultSnapshot.pendingRedemptions;
      pendingRedemptionAmount = supplyAtLatestVersion === 0n
        ? 0n
        : userVaultSnapshot.pendingRedemptionAmount * vaultSnapshot.totalAssets / supplyAtLatestVersion;
    }

    let maxAvailableToWithdraw = 0n;
    if (vaultSnapshot) {
      const { longSnapshot, shortSnapshot, targetLeverage } = vaultSnapshot;
      const totalLong = nextPosition(longSnapshot.pre, longSnapshot.position);
      const totalShort = nextPosition(shortSnapshot.pre, shortSnapshot.position);

      const maxCloseSizeLong = BigMath.abs(totalLong.maker - totalLong.taker);
      const maxCloseSizeShort = BigMath.abs(totalShort.maker - totalShort.taker);
      const maxCloseSize = maxCloseSizeLong <= maxCloseSizeShort ? maxCloseSizeLong : maxCloseSizeShort;

      maxAvailableToWithdraw = (maxCloseSize * longSnapshot.latestVersion.price * 2n) * targetLeverage;

      if (maxAvailableToWithdraw > assetsBalance) {
        maxAvailableToWithdraw = assetsBalance;
      }
    }

    return {
      hasLPRewards,
      assetsBalance,
      sharesBalance,
      pendingDeposits,
      claimableAmount,
      pendingRedemptionAmount,
      maxAvailableToWithdraw,
    }
  },
    // eslint-disable-next-line
    [vaultSnapshot, userVaultSnapshot]
  );
 
  const errorWithdrawal = useMemo(() => {
    let error = "";
    if (currentAction === 0) return error;

    const redeemAmount = ethers.parseEther(collateralNumber.toString());

    if (vaultSnapshot) {
      const { longSnapshot, shortSnapshot, targetLeverage } = vaultSnapshot
      const totalLong = nextPosition(longSnapshot.pre, longSnapshot.position);
      const totalShort = nextPosition(shortSnapshot.pre, shortSnapshot.position);
      const closeSize = BigMath.abs(targetLeverage * redeemAmount / longSnapshot.latestVersion.price) / 2n;

      // If the taker + close is larger than maker, error
      if (closeSize + totalLong.taker >= totalLong.maker || closeSize + totalShort.taker >= totalShort.maker) {
        error = t("error.exceeds-withdrawal")
      }
    }

    return error;
  },
    // eslint-disable-next-line
    [vaultSnapshot, userVaultSnapshot, sharesAmount, sharesBalance, currentAction]
  )
  
  const errorDeposit = useMemo(() => {
    let error = "";
    if (currentAction === 1) return error;

    const depositAmount = ethers.parseUnits(collateralNumber.toString(), 6);
    if (collateralBalanceRaw && depositAmount > collateralBalanceRaw) {
      error = t("error.no-usdc")
    }
    
    if (vaultSnapshot) {
      const { totalAssets, maxCollateral } = vaultSnapshot
      if (depositAmount + totalAssets > maxCollateral) {
        if (depositAmount > 0n) {
          error = t("error.exceeds-vault-maximum")
        }
        else {
          error = t("error.vault-at-capacity")
        }
      }
    }

    return error;
  },
    // eslint-disable-next-line
    [vaultSnapshot, collateralBalanceRaw, collateralNumber, currentAction]
  )

  useEffect(() => {
    if (!vaultSnapshot) return

    const { longSnapshot, longUserSnapshot, shortSnapshot, shortUserSnapshot, totalAssets } = vaultSnapshot
    const price = longSnapshot.latestVersion.price
    const longGlobalPosition = nextPosition(longSnapshot.pre, longSnapshot.position);
    const shortGlobalPosition = nextPosition(shortSnapshot.pre, shortSnapshot.position);
    const globlaTotalPosition = addPositions(longGlobalPosition, shortGlobalPosition);
    const userLongPosition = nextPosition(longUserSnapshot.pre, longUserSnapshot.position)
    const userShortPosition = nextPosition(shortUserSnapshot.pre, shortUserSnapshot.position)
    const userTotalPosition = addPositions(userLongPosition, userShortPosition);
    const leverage = calculateLeverageBN(price, userTotalPosition.maker, totalAssets)

    // Delta = ((long pos * long utilization) - (short pos * short utilization)) / (total pos)
    const longExposure = calculateExposure(userTotalPosition.maker, longGlobalPosition)
    const shortExposure = calculateExposure(userTotalPosition.maker, shortGlobalPosition)

    let delta = 0;
    let uRate = { global: 0, long: 0, short: 0 }
    if (userTotalPosition.maker !== 0n) {
      const numerator = ethers.formatEther(longExposure - shortExposure);
      const divisor = ethers.formatEther(userTotalPosition.maker)
      delta = parseFloat(numerator) / (-1 * parseFloat(divisor));
    }

    if (globlaTotalPosition.maker !== 0n) {
      // utilization rate: taker / maker
      const numerator = ethers.formatEther(globlaTotalPosition.taker);
      const divisor = ethers.formatEther(globlaTotalPosition.maker);
      uRate.global = (parseFloat(numerator) / parseFloat(divisor)) * 100;
    }
    if (longGlobalPosition.maker !== 0n) {
      const numerator = ethers.formatEther(longGlobalPosition.taker);
      const divisor = ethers.formatEther(longGlobalPosition.maker);
      uRate.long = (parseFloat(numerator) / parseFloat(divisor)) * 100;
    }
    if (shortGlobalPosition.maker !== 0n) {
      const numerator = ethers.formatEther(shortGlobalPosition.taker);
      const divisor = ethers.formatEther(shortGlobalPosition.maker);
      uRate.short = (parseFloat(numerator) / parseFloat(divisor)) * 100;
    }

    // Funding = Long(Utilization * Rate * Leverage) + Short(Utilization * Rate * Leverage)
    const longFunding = calculateFunding(
      leverage,
      longSnapshot.rate,
      longGlobalPosition
    )
    const shortFunding = calculateFunding(
      leverage,
      shortSnapshot.rate,
      shortGlobalPosition
    )

    setDelta(delta)
    setUtilizationRate(uRate);
    setFunding((longFunding + shortFunding) / 2n) // Take the average of funding rates
    setExposure(Math.abs(parseFloat(ethers.formatEther(leverage)) * delta));
  },
    // eslint-disable-next-line
    [vaultSnapshot, rewardsInfo]
  )

  let pnl = useMemo(() => {
    if (!vaultSnapshot || !userVaultSnapshot) return 0n;
    
    const supplyAtLatestVersion = vaultSnapshot.totalSupply + vaultSnapshot.pendingRedemptions;

    const inflightRedemption = supplyAtLatestVersion === 0n
      ? 0n
      : userVaultSnapshot.pendingRedemptionAmount * vaultSnapshot.totalAssets / supplyAtLatestVersion;
    
    const userNetDeposits = userVaultSnapshot
      ? userVaultSnapshot.currentPositionDeposits - userVaultSnapshot.currentPositionClaims
      : 0n;
    
    return userVaultSnapshot.assets
      + (hasLPRewards ? userVaultSnapshot.rewardsAssets : 0n)
      + userVaultSnapshot.claimable
      + userVaultSnapshot.pendingDepositAmount
      + inflightRedemption
      - userNetDeposits
  },
    [vaultSnapshot, userVaultSnapshot, hasLPRewards]
  );

  const fundingAPR = funding * (60n * 60n * 24n * 365n) * 100n;

  const withdrawalDisabled = userVaultSnapshot
    ? (userVaultSnapshot.assets === 0n &&
      userVaultSnapshot.claimable === 0n &&
      pendingRedemptionAmount === 0n)
    : false

  const onTransactionSettled = (isSuccess: boolean, successMsg: string, error: any, tranHash: string) => {
    if (isSuccess) {
      if (tranHash !== "") {
        saveTransactionToDB(
          chainId,
          tranHash,
          ownerAddress,
          product.market.key,
          product.vaultContract[chainId].address,
          collateralNumber,
          0,
          0,
          currentAction === 0 ? InvokerAction.VAULT_WRAP_AND_DEPOSIT : InvokerAction.VAULT_REDEEM,
        );
      }

      notifyUser(successMsg);
      setCollateralAmount("0");
      setCollateralNumber(0);

      mutateVault();
      setTimeout(mutateVault, 5000)
      mutateData();
      setTimeout(mutateData, 8000)
    } else {
      if (error.name !== ERROR_USER_REJECTED_TRAN) {
        errorNotification(t("error.transaction"));
      }
    }
    setWritingTran(false);
  };

  const { onClaim, onDeposit, onRedeem } = useVaultTransactions(
    chainId,
    ownerAddress,
    product.vaultContract[chainId].address,
    onTransactionSettled
  )

  const convertToShares = async (value: string) => {
    const valBN = ethers.parseEther(value);
    const shares = await convertAssetsToShares(multiCallProvider, product.vaultContract[chainId].address, valBN);

    setSharesAmount(shares);
  };

  const onCollateralChange = async (value: string) => {
    if (value !== "" && isPositiveNumber(value)) {
      if (hasMaxDecimalsAllowed(value, currentAction === 0 ? 6 : 18)) {
        setCollateralAmount(value);
        setCollateralNumber(parseFloat(value));
        convertToShares(value);
      }  
    } else {
      setCollateralAmount("");
    }
  };

  const onSetMaxAmount = () => {
    if (currentAction === 0) {
      const value = ethers.formatUnits(collateralBalanceRaw, 6);
      setCollateralAmount(value);
      setCollateralNumber(parseFloat(value));
    } else {
      const value = ethers.formatEther(maxAvailableToWithdraw);
      setCollateralAmount(parseFloat(value).toFixed(16));
      setCollateralNumber(parseFloat(value));
      setSharesAmount(sharesBalance);
    }
  }

  const onDepositClick = async () => {
    if (collateralNumber > 0 && collateralNumber <= usdcAllowance && ownerAddress !== ethers.ZeroAddress) {
      setWritingTran(true);
      onDeposit(collateralNumber)
    }
  };

  const onWithdrawClick = async () => {
    if (sharesAmount !== 0n && sharesAllowance >= sharesAmount && errorWithdrawal === "") {
      setWritingTran(true);
      onRedeem(sharesAmount)
    }
  };

  const onClaimClick = async () => {
    if (claimableAmount > 0n && claimableAmount <= dsuAllowance && ownerAddress !== ethers.ZeroAddress) {
      setWritingTran(true);
      onClaim(claimableAmount)
    }
  };

  const showDepositButton = (): boolean => {
    if (currentAction === 0) {
      return usdcAllowance >= collateralNumber;
    }

    return sharesAllowance >= sharesAmount && claimableAmount === 0n && pendingRedemptionAmount === 0n;
  };

  const buttonDisabled = (): boolean => {
    if (!writingTran) {
      if (currentAction === 1) {
        return withdrawalDisabled;
      }
    }
  
    return writingTran
  };

  const isWithdrawingOrClaiming = (): boolean => {
    return pendingRedemptionAmount > 0n || claimableAmount > 0n;
  }

  const RenderClaimAmount = () => (
    <div className="claim-container">
      <div className="claim-container-value">
        <h6>{t("claimable-amount")}:</h6>
        {claimableAmount > 0n ? (
          <NumericFormat
            className="balance-usd bold"
            value={parseFloat(ethers.formatEther(claimableAmount)).toFixed(4)}
            displayType="text"
            thousandSeparator=","
            suffix=" USD"
            decimalScale={4}
          />
        ) : (
          <Spinner animation="border" variant="secondary" className="xs" />
        )}
      </div>
      {pendingRedemptionAmount > 0n && claimableAmount === 0n && (
        <div className="claim-container-msg">
          <p>{t("info-msg.withdrawing-collateral")}</p>
        </div>
      )}
      {currentAction === 1 && claimableAmount > dsuAllowance ? (
        <ApproveCollateralButton
          approvalAction={ApproveType.CLAIM}
          currentAllowance={0}
          amountToApprove={
            parseFloat(ethers.formatEther(claimableAmount))
          }
          onSuccess={() => {
            mutateData();
          }}
        />
      ) : (
        <Button
          className="btn btn-primary btn-block deposit-button"
          onClick={onClaimClick}
          disabled={writingTran || pendingRedemptionAmount !== 0n || !isSupportedV1Chain(currentChainId)}
        >
          <div className="btn-spinner">
            {writingTran && <Spinner animation="border" variant="secondary" className="small" />}
            {pendingRedemptionAmount > 0n ? t("withdrawing") :
              (writingTran ? t("claiming") : t("claim"))
            }
          </div>
        </Button>
      )}
    </div>
  );
  
  const RenderErrorMsg = () => {
    if (currentAction === 0 && errorDeposit !== "") {
      return (
        <span className="error">
          {errorDeposit}
        </span>
      )
    }
    if (currentAction === 1) {
      if (errorWithdrawal !== "") {
        return (
          <span className="error">
            {errorWithdrawal}
          </span>
        )
      }
    }
    return <></>;
  }

  const isDataValid = (): boolean => {
    if (currentAction === 0) {
      return errorDeposit === "";
    }
    return errorWithdrawal === "";
  }

  const claimOrRedemptionAmount = () => {
    if (claimableAmount === 0n) {
      return parseFloat(ethers.formatEther(pendingRedemptionAmount)).toFixed(4);
    }
    return parseFloat(ethers.formatEther(claimableAmount)).toFixed(4);
  }

  const pnlClassName = (pnlBN: bigint) => {
    const pnl = parseFloat(ethers.formatEther(pnlBN));
    if (Math.abs(pnl) < 0.0001) {
      return "number";
    }
    return pnl > 0 ? "number green" : "number red";
  }

  const CurrentBalance = () => {
    const rewardsTotal = userVaultSnapshot && hasLPRewards ? userVaultSnapshot.rewardsAssets : 0n;
    const totalBalance = assetsBalance + rewardsTotal;

    return (
      <div className="value-group">
        <div className="value-item total">
          <div className="value-item-desc">
            <h6>{t("current-balance")}</h6>
            {hasLPRewards && (
              <Button
                variant="secondary small underline"
                onClick={() => setShowBalanceDetail(!showBalanceDetail)}
              >
                {showBalanceDetail ? `(${t("show-less")})` : `(${t("detail")})`}
              </Button>
            )}  
            <h6>:</h6>
          </div>
          {!loadingUserData ? (
            <NumericFormat
              className="balance-usd bold"
              value={parseFloat(ethers.formatEther(totalBalance)).toFixed(4)}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              prefix="$"
            />
          ) : (
            <Spinner animation="border" variant="secondary" className="xs" />
          )}  
        </div>
        {hasLPRewards && (
          <div className={"value-group-detail ".concat(showBalanceDetail ? "show" : "hide")}>
            <div className="value-item">
              <CustomTooltip
                id="liq-deposited-amount"
                msg={t("tooltip.staked-liquidity")}
                showIcon={true}
                iconOnLeft={true}
                iconSize={13}
              >
                <h6 className="margin-left">{t("deposited-amount")}:</h6>
              </CustomTooltip>
              <NumericFormat
                className="balance-usd bold"
                value={parseFloat(ethers.formatEther(assetsBalance)).toFixed(4)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
            </div>
            <div className="value-item">
              <CustomTooltip
                id="liq-staked-amount"
                msg={t("tooltip.staked-liquidity")}
                showIcon={true}
                iconOnLeft={true}
                iconSize={13}
              >
                <h6 className="margin-left">{t("staked-amount")}:</h6>
              </CustomTooltip>
              <NumericFormat
                className="balance-usd bold"
                value={parseFloat(ethers.formatEther(rewardsTotal)).toFixed(4)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
            </div>
          </div>
        )}  
      </div>
    );
  }

  const TotalApr = () => {
    let liqRewardsApr =
      rewardsInfo && rewardsInfo.totalSupply > 0n ? rewardsApr : 0;
    if (rewardsInfo && rewardsInfo.amountStaked === 0n) {
      liqRewardsApr = 0;
    }
    const totalApr = parseFloat(ethers.formatEther(fundingAPR)) + liqRewardsApr;

    return (
      <div className="value-group">
        <div className="value-item total">
          <div className="value-item-desc">
            <h6>{t("apr")}</h6>
            {hasLPRewards && (
              <Button
                variant="secondary small underline"
                onClick={() => setShowAprDetail(!showAprDetail)}
              >
                {showAprDetail ? `(${t("show-less")})` : `(${t("detail")})`}
              </Button>
            )}  
            <h6>:</h6>
          </div>  
          <NumericFormat
            className="balance-usd bold"
            value={totalApr.toFixed(4)}
            displayType="text"
            thousandSeparator=","
            suffix="%"
            decimalScale={4}
          />
        </div>
        {hasLPRewards && (
          <div
            className={"value-group-detail ".concat(showAprDetail ? "show" : "hide")}
          >
            <div className="value-item">
              <h6>{t("annualized-funding-rate")}:</h6>
              <NumericFormat
                className="balance-usd bold"
                value={parseFloat(ethers.formatEther(fundingAPR)).toFixed(4)}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={4}
              />
            </div>
            <div className="value-item">
              <h6>{t("rewards-apr")}:</h6>
              <NumericFormat
                className="balance-usd bold"
                value={liqRewardsApr.toFixed(4)}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={4}
              />
            </div>
          </div>
        )}  
      </div>
    );
  }

  return (
    <div className="liquidity-provide">
      <div className="body-left provide">
        <Markets editable={true} showStats={true} showLiquidity={false} />
        <div className="options provide">
          <ButtonGroup>
            {provideActions.map((action, idx) => (
              <ToggleButton
                key={idx}
                id={`market-${idx}`}
                type="radio"
                name="radio"
                value={action.value}
                checked={currentAction === action.value}
                onChange={(e) => {
                  setCurrentAction(parseInt(e.currentTarget.value))
                  setCollateralAmount("0")
                  setCollateralNumber(0)
                }}
              >
                {action.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <LiquidityCapacity />
        <div className="controls">
          {currentAction === 0 || (currentAction === 1 && claimableAmount === 0n && pendingRedemptionAmount === 0n) ? (
            <>
              <CollateralInput
                control_id="provide-collateral-input-id"
                showBalance={true}
                value={collateralAmount}
                title={t("collateral")}
                onChange={onCollateralChange}
                onSetMaxAmount={onSetMaxAmount}
                maxCaption={currentAction === 0 ? t("balance") : t("max-available")}
                vaultWithdrawal={currentAction !== 0}
                deposited={maxAvailableToWithdraw}
              />
              <RenderErrorMsg />
              <PriceUpdateWarning />
            </>            
          ) : (
            <>
              {RenderClaimAmount()}
            </>
          )}
        </div>
        {currentAction === 0 && usdcAllowance < collateralNumber && (
          <ApproveCollateralButton
            approvalAction={ApproveType.DEPOSIT}
            currentAllowance={usdcAllowance}
            amountToApprove={collateralNumber}
            onSuccess={() => {
              mutateData();
            }}
          />
        )}
        {currentAction === 1 && sharesAllowance < sharesAmount && !isWithdrawingOrClaiming() && (
          <ApproveCollateralButton
            approvalAction={ApproveType.WITHDRAW}
            currentAllowance={0}
            amountToApprove={
              parseFloat(ethers.formatEther(sharesAmount))
            }
            onSuccess={() => {
              mutateData();
            }}
          />
        )}
        {showDepositButton() && (
          <Button
            className="btn btn-primary btn-block deposit-button"
            onClick={currentAction === 0 ? onDepositClick : onWithdrawClick}
            disabled={buttonDisabled() || !isDataValid() || !isSupportedV1Chain(currentChainId)}
          >
            <div className="btn-spinner">
              {writingTran && <Spinner animation="border" variant="secondary" className="small" />}
              {provideActions[currentAction].name}
            </div>
          </Button>
        )}
      </div>
      <div className="body-right provide">
        <div className="values-top">
          {pendingDeposits !== 0n && (
            <div className="value-item">
              <h6>{t("pending-deposits")}:</h6>
              {!loadingUserData ? (
                <NumericFormat
                  className="balance-usd bold"
                  value={parseFloat(ethers.formatEther(pendingDeposits)).toFixed(4)}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
              ) : (
                <Spinner animation="border" variant="secondary" className="xs" />
              )}
            </div>
          )}
          {CurrentBalance()}
          <div className="value-item">
            <h6>{t("total-profit-loss")}:</h6>
            {!loadingUserData ? (
              <NumericFormat
                className={"bold ".concat(pnlClassName(pnl))}
                value={parseFloat(ethers.formatEther(pnl)).toFixed(4)}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
            ) : (
              <Spinner animation="border" variant="secondary" className="xs" />
            )}
          </div>
          {(pendingRedemptionAmount !== 0n || claimableAmount !== 0n) ? (
            <div className="value-item">
              <h6>
                {claimableAmount === 0n ? `${t("pending-withdrawals")}:` : `${t("available-to-claim")}:`}
              </h6>
              {!loadingUserData ? (
                <NumericFormat
                  className="balance-usd bold"
                  value={claimOrRedemptionAmount()}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
              ) : (
                <Spinner animation="border" variant="secondary" className="xs" />    
              )}
            </div>
          ) : (
            <div className="value-item">
              <h6>{t("amount-for-withdrawal")}:</h6>
              {!loadingUserData ? (
                <NumericFormat
                  className="balance-usd bold"
                  value={
                    parseFloat(ethers.formatEther(maxAvailableToWithdraw)).toFixed(4)
                  }
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
              ) : (
                <Spinner animation="border" variant="secondary" className="xs" />
              )}
            </div>
          )}
        </div>
        <div className="values-bottom">
          {TotalApr()}
          <div className="value-item">
            <h6>{t("vault-current-exposure")}:</h6>
            <NumericFormat
              className="balance-usd bold"
              value={(exposure * 100).toFixed(4)}
              displayType="text"
              thousandSeparator=","
              prefix={delta < 0 ? "Short " : "Long "}
              suffix="%"
              decimalScale={4}
            />
          </div>
          <div className="value-group">
            <div className="value-item total">
              <div className="value-item-desc">
                <h6>{t("utilization-rate")}</h6>
                {hasLPRewards && (
                  <Button
                    variant="secondary small underline"
                    onClick={() => setShowURDetail(!showURDetail)}
                  >
                    {showURDetail ? `(${t("show-less")})` : `(${t("detail")})`}
                  </Button>
                )}  
                <h6>:</h6>
              </div>  
              <NumericFormat
                className="balance-usd bold"
                value={utilizationRate.global}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={4}
              />
            </div>
            <div className={"value-group-detail ".concat(showURDetail ? "show" : "hide")}>
              <div className="value-item">
                <h6>Long {t("utilization-rate")}:</h6>
                <NumericFormat
                  className="balance-usd bold"
                  value={utilizationRate.long}
                  displayType="text"
                  thousandSeparator=","
                  suffix="%"
                  decimalScale={4}
                />
              </div>
              <div className="value-item">
                <h6>Short {t("utilization-rate")}:</h6>
                <NumericFormat
                  className="balance-usd bold"
                  value={utilizationRate.short}
                  displayType="text"
                  thousandSeparator=","
                  suffix="%"
                  decimalScale={4}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
