import { useWriteContract } from "wagmi";
import { ContractType } from "../utils/types";
import { useActiveProvider } from ".";
import { getAddress } from "viem";


export const useApproveAllowance = (
  contract: ContractType,
  spenderAddress: string,
  onSettled: (isSuccess: boolean, error: any) => void,  
): {
  onApproveAllowance: (amountToApprove: bigint) => void
} => {
  const { chainId } = useActiveProvider();
  const { writeContract } = useWriteContract();

  return {
    onApproveAllowance: (amountToApprove: bigint) => {
      writeContract(
        {
          address: getAddress(contract[chainId].address),
          abi: contract.abi,
          functionName: "approve",
          args: [spenderAddress, amountToApprove],
        },
        {
          onSuccess() {
            onSettled(true, undefined)
          },
          onError(error) {
            onSettled(true, error)
          }
        }
      )
    }
  }
};
