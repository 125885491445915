import React from "react";
import ProvideLiquidity from "../../components/provide";
import { VaultProvider } from "../../contexts";

const ProvidePage = () => (
  <VaultProvider>
    <ProvideLiquidity />
  </VaultProvider>      
);

export default ProvidePage
