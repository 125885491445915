import { ethers } from "ethers";
import { useWriteContract } from "wagmi";
import { buildInvokerAction, InvokerAction } from "../helpers";
import { multiInvokerContract } from "../utils/contracts";
import { BigMath } from "../utils/utils";
import { getAddress } from "viem";


export const useOpenPosition = (
  chainId: number,
  ownerAddress: string,
  isTaker: boolean,
  onSettled: (isSucces: boolean, error: any, tranHash: string) => void,
) : {
  onOpenPosition: ((productAddress: string, collateralDelta: number, positionDelta: bigint) => void)
} => {
  const { writeContract } = useWriteContract();

  return {
    onOpenPosition: (productAddress: string, collateralDelta: number, positionDelta: bigint) => {
      const collateralRaw = ethers.parseEther(Math.abs(collateralDelta).toString());
      const openInvokerAction = isTaker ? InvokerAction.OPEN_TAKE : InvokerAction.OPEN_MAKE;
      const closeInvokerAction = isTaker ? InvokerAction.CLOSE_TAKE : InvokerAction.CLOSE_MAKE;

      const params = {
        userAddress: ownerAddress,
        productAddress: productAddress,
        position: BigMath.abs(positionDelta),
        collateral: collateralRaw,
      };

      const invokerArgs = [];
      if (collateralRaw !== 0n) {
        const invokerAction = collateralDelta > 0 ? InvokerAction.WRAP_AND_DEPOSIT : InvokerAction.WITHDRAW_AND_UNWRAP;
        invokerArgs.push(buildInvokerAction(invokerAction, params));
      }

      if (positionDelta !== 0n) {
        const invokerAction = positionDelta > 0 ? openInvokerAction : closeInvokerAction;
        invokerArgs.push(buildInvokerAction(invokerAction, params));
      }
        
      writeContract(
        {
          address: getAddress(multiInvokerContract[chainId].address),
          abi: multiInvokerContract.abi,
          functionName: "invoke",
          args: [invokerArgs],
        },
        {
          onSuccess(data){
            onSettled(true, undefined, data)
          },
          onError(error) {
            console.log("Open Position: ", error)
            onSettled(false, error, "")
          },
        }
      )
    }
  }
}
