import React, { useEffect, useMemo, useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Container } from "react-bootstrap/esm"
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"
import { Hour } from "@perennial/sdk"
import { ToastContainer } from "react-toastify"

import { useMediaQuery } from "react-responsive"
import { WagmiProvider } from "wagmi"

import { CookiesProvider } from "react-cookie"
import axios from "axios"
import "./styles/app.scss"
import "react-toastify/dist/ReactToastify.css"
import "./styles/toast.scss"
import { LoadingPage } from "./components/common/"
import { versionContext, useVersionContext, PushProvider } from "./contexts"
import { geoLocationContext, positionStatsContext, productContext } from "./v1/states"
import { useGeolocationContext, usePositionStatsContext, useProductContext } from "./v1/hooks"
import Header from "./components/Header";

// version 2.5
import TradePage from "./pages/trade/"
import ProvidePage from "./pages/provide/"
import RewardsPage from "./pages/rewards/"

// version 2
import Markets from "./v1/pages/Markets"
import PWLiquidity from "./v1/pages/PWLiquidity"
import MobileMarkets from "./v1/pages/mobile/MobileMarkets"
import TermsOfServiceModal from "./v1/components/modals/TermsOfService"
import TermsPage from "./v1/pages/TermsPage"

import { ARBITRUM_GRAPHQL_URL, CHAINS, MARKETS, TOKENS_SYMBOLS } from "./v1/utils/constants";
import { wagmiConfig } from "./constants/network"
import { PerennialSDKProvider } from "./contexts/perennialSdkContext"


const tanstackQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number(1n * Hour) * 1000, // 1 hour in ms
    },
  },
})

const App = () => {
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 850px)" });
  const [isLoading, setLoading] = useState(true);
  // const { chainId } = useUserAccount();
  const hasTOS = (localStorage.getItem("tosAccepted") === "true");
  const [modalValue, setModalValue] = useState<boolean | undefined>(!hasTOS);
  const handleCloseModal = () => {
    setModalValue(false);
  }
  const apolloClient = new ApolloClient({
    uri: ARBITRUM_GRAPHQL_URL,
    cache: new InMemoryCache(),
  });
  const geolocation = useGeolocationContext();
  const positionStats = usePositionStatsContext();

  const { initialMarket, currentVersion } = useMemo(() => {
    let initialMarket = MARKETS[TOKENS_SYMBOLS.tcap];
    let currentVersion = "2.5"

    if (window.location.pathname.includes(TOKENS_SYMBOLS.perpe)) {
      initialMarket = MARKETS[TOKENS_SYMBOLS.perpe];
    } else if (window.location.pathname.includes(TOKENS_SYMBOLS.ether)) {
      initialMarket = MARKETS[TOKENS_SYMBOLS.ether];
    } else if (window.location.pathname.includes(TOKENS_SYMBOLS.arb)) { 
      initialMarket = MARKETS[TOKENS_SYMBOLS.arb];
    }

    if (window.location.pathname.includes("/v2")) { 
      currentVersion = "2";
    }

    return {
      initialMarket,
      currentVersion
    }
  },
    // eslint-disable-next-line
    []
  );

  const currentAppVersion = useVersionContext(currentVersion);
  const product = useProductContext(initialMarket);

  // Call the API to check if the user is allowed to access the beta
  // If the user is allowed, set isAllowed to true
  // If the user is not allowed, set isAllowed to false
  useEffect(() => {
    const loadGeoInfo = () => {
      axios.get("https://ipapi.co/json/").then((response) => {
        let data = response.data;
        geolocation.setCurrentCountryCode(data.country_code);
        setLoading(false);
      }).catch((error) => {
        axios.get("https://a3cxa9tir4.execute-api.us-east-1.amazonaws.com/country").then((response) => {
          geolocation.setCurrentCountryCode(response.data.country_code);
          setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
        setLoading(false);
      });
    };
    
    loadGeoInfo();
  },
    // eslint-disable-next-line
    []
  );

  if (isLoading) {
    return (
      <Container fluid className="wrapper transparent-background">
        <LoadingPage />
      </Container>
    );
  };

  const url_path = window.location.pathname;
  const tos_url = "/terms-of-service";

  return (
    <QueryClientProvider client={tanstackQueryClient}>
      <WagmiProvider config={wagmiConfig}>
        <RainbowKitProvider
          appInfo={{
            appName: "Cryptex Finance",
            learnMoreUrl: "https://docs.cryptex.finance/"
          }}
          initialChain={CHAINS.ARBITRUM}
          theme={darkTheme({
            accentColor: "#A440F2",
            accentColorForeground: "white",
            borderRadius: "small",
            fontStack: "system",
            overlayBlur: "small",
          })}
          modalSize={isMobileOrTablet ? "compact" : "wide"}
          showRecentTransactions
        >
          <ApolloProvider client={apolloClient}>
            <PerennialSDKProvider>
              <PushProvider>
                <geoLocationContext.Provider value={geolocation}>
                  <productContext.Provider value={product}>
                    <positionStatsContext.Provider value={positionStats}>
                      <versionContext.Provider value={currentAppVersion}>
                        <CookiesProvider>
                          <BrowserRouter>
                            <Container fluid className="wrapper transparent-background">
                              <>
                                {(url_path === tos_url) ? <Header showNav={false} /> : <Header showNav={true} />}
                                <ToastContainer />                     
                                <Routes>
                                  <Route path="/:product?" element={<TradePage />} />
                                  <Route path="/liquidity/" element={<ProvidePage />} />  
                                  <Route path="/rewards" element={<RewardsPage />} />
                                  <Route path="v2/:product?" element={!isMobileOrTablet ? <Markets /> : <MobileMarkets />} />
                                  <Route path="/terms-of-service" element={<TermsPage />} />
                                  <Route path="v2/liquidity/:product?/:actionkey?" element={<PWLiquidity />} />
                                  <Route
                                    path="/invite/:uuid"
                                    element={!isMobileOrTablet ? <Markets /> : <MobileMarkets />}
                                  />
                                </Routes>
                              </>
                            </Container>
                            <TermsOfServiceModal showModal={modalValue} onHide={handleCloseModal} />
                          </BrowserRouter>
                        </CookiesProvider>
                      </versionContext.Provider>  
                    </positionStatsContext.Provider>        
                  </productContext.Provider>
                </geoLocationContext.Provider>
              </PushProvider>
            </PerennialSDKProvider>  
          </ApolloProvider>
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>  
  );
}

export default App;
