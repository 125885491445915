import {
  Big6Math,
  calcTradeFee,
  formatBig6Percent,
  PositionStatus,
  TriggerComparison,
  calcNotional,
  isFailedClose,
  MarketSnapshot,
  PositionSide,
  UserMarketSnapshot
} from '@perennial/sdk';

import { FormattedOpenOrder } from "../components/Trade/hooks";
import { SupportedChainId, interfaceFeeBps } from "../constants/network";
import { CurrentSTIPSeason, STIPDropParams, STIPSeasonNumber } from "../constants/stipDrop";


/* export const getStatusDetails = ({
  userMarketSnapshot,
  isMaker,
}: {
  userMarketSnapshot?: UserMarketSnapshot;
  isMaker?: boolean;
}) => {
  if (!userMarketSnapshot || !isActivePosition(userMarketSnapshot))
    return {
      isOpenPosition: false,
      isTransitionPosition: false,
      statusColor: "darkGray",
      status: PositionStatus.resolved,
      directionTextColor: "darkGray",
      isClosing: false,
    };
  const { status } = userMarketSnapshot;
  const isOpenPosition = [
    PositionStatus.open,
    PositionStatus.pricing,
    PositionStatus.closing,
    PositionStatus.opening,
  ].includes(status);
  const isTransitionPosition = [
    PositionStatus.pricing,
    PositionStatus.opening,
    PositionStatus.closing,
  ].includes(status);
  const hasPosition = PositionStatus.resolved !== status;
  const isClosing = status === PositionStatus.closing;
  return {
    isOpenPosition,
    isTransitionPosition,
    status,
    hasPosition,
    isClosing,
  };
}; */

export const calcTradeFeeApr = ({
  fees7Day,
  makerOi,
  collateral,
  notional,
}: {
  fees7Day: bigint;
  makerOi: bigint;
  collateral: bigint;
  notional: bigint;
}) => {
  if (!fees7Day || !makerOi || !collateral || !notional) return 0n;
  const dailyAvgFee = Big6Math.div(fees7Day, Big6Math.fromDecimals(7n, 0));
  const annualFees = Big6Math.mul(dailyAvgFee, Big6Math.fromDecimals(365n, 0));
  const annualFeesPerUser = Big6Math.mul(annualFees, notional);
  const denominator = Big6Math.mul(makerOi, collateral);
  return Big6Math.div(annualFeesPerUser, denominator);
};

export function calcLpUtilization(marketSnapshot?: MarketSnapshot) {
  if (!marketSnapshot) return undefined;
  const {
    majorSide,
    minorSide,
    nextPosition: { long, short, maker },
  } = marketSnapshot;

  const majorPosition = majorSide === PositionSide.long ? long : short;
  const minorPosition = majorSide === PositionSide.long ? short : long;

  const lpUtilization =
    maker > 0n
      ? Big6Math.div(majorPosition - minorPosition, maker)
      : 0n;

  return {
    lpUtilization,
    formattedLpUtilization: formatBig6Percent(lpUtilization, {
      numDecimals: 2,
    }),
    exposureSide: minorSide,
  };
}

export const calcTotalPositionChangeFee = ({
  positionStatus = PositionStatus.resolved,
  chainId,
  marketSnapshot,
  positionDelta,
  direction,
}: {
  chainId: SupportedChainId
  positionDelta: bigint
  marketSnapshot?: MarketSnapshot
  direction: PositionSide
  positionStatus?: PositionStatus
}) => {
  const tradeFee = calcTradeFee({
    positionDelta,
    marketSnapshot,
    isMaker: direction === PositionSide.maker,
    direction,
  })
  const interfaceFee = calcInterfaceFee({
    positionStatus,
    latestPrice: marketSnapshot?.global.latestPrice ?? 0n,
    chainId,
    positionDelta,
    side: direction
  })

  const settlementFee = positionDelta !== 0n && marketSnapshot ? marketSnapshot.parameter.settlementFee : 0n

  return {
    // total: tradeFee.total + interfaceFee.interfaceFee + settlementFee,
    total: tradeFee.tradeFee + interfaceFee.interfaceFee + settlementFee,
    tradeFee,
    interfaceFee,
    settlementFee,
  }
}

export function calcLpExposure(marketSnapshot?: MarketSnapshot) {
  if (!marketSnapshot) return undefined
  const {
    majorSide,
    minorSide,
    nextPosition: { long, short, maker },
  } = marketSnapshot

  const majorPosition = majorSide === PositionSide.long ? long : short
  const minorPosition = majorSide === PositionSide.long ? short : long

  const lpExposure = maker > 0n ? Big6Math.div(majorPosition - minorPosition, maker) : 0n

  return {
    lpExposure: lpExposure,
    formattedLpExposure: formatBig6Percent(lpExposure, { numDecimals: 2 }),
    exposureSide: minorSide,
  }
}

export function calcInterfaceFee({
  positionStatus = PositionStatus.resolved,
  latestPrice,
  chainId,
  positionDelta,
  side,
  referrerInterfaceFeeDiscount = 0n,
  referrerInterfaceFeeShare  = 0n,
}: {
  positionStatus?: PositionStatus
  latestPrice: bigint
  chainId: SupportedChainId
  positionDelta: bigint
  side: PositionSide
  referrerInterfaceFeeDiscount?: bigint
  referrerInterfaceFeeShare?: bigint
}) {
  const feeInfo = interfaceFeeBps[chainId]
  if (!latestPrice || !positionDelta || !feeInfo || positionStatus === PositionStatus.failed) {
    return {
      interfaceFeeBps: feeInfo?.feeAmount[PositionSide.none] ?? 0n,
      interfaceFee: 0n,
      referrerFee: 0n,
      ecosystemFee: 0n,
    }
  }

  const notional = calcNotional(positionDelta, latestPrice)
  const feeAmount = feeInfo.feeAmount[side]
  const discountedFeeAmount = feeAmount - Big6Math.mul(feeAmount, referrerInterfaceFeeDiscount)
  const discountedInterfaceFee = Big6Math.mul(notional, discountedFeeAmount)
  const referrerFee = Big6Math.mul(discountedInterfaceFee, referrerInterfaceFeeShare)
  const ecosystemFee = discountedInterfaceFee - referrerFee

  return {
    interfaceFeeBps: discountedFeeAmount,
    interfaceFee: discountedInterfaceFee,
    referrerFee,
    ecosystemFee,
  }
}

export const getMakerExposure = (lpUtililization?: bigint, leverage?: bigint) => {
  if (!lpUtililization || !leverage) return 0n
  return Big6Math.mul(lpUtililization, leverage)
}

export const calcSTIPFeeRebate = ({
  takerNotional,
  takerFeeBps,
  positionFee,
  season = CurrentSTIPSeason,
}: {
  takerNotional: bigint
  takerFeeBps: bigint
  positionFee: bigint
  season?: STIPSeasonNumber
}) => {
  const seasonData = STIPDropParams[season].taker
  const fee = Big6Math.min(Big6Math.mul(takerFeeBps, takerNotional), positionFee)

  return Big6Math.mul(fee, seasonData.feeRebatePct)
}

export const getOpenOrderLabel = ({
  orderDelta,
  orderDirection,
  comparison,
  isMaker,
}: {
  orderDelta: bigint
  orderDirection: PositionSide
  comparison: TriggerComparison
  isMaker: boolean
  }) => {
  if (isMaker) {
    return orderDelta > 0n ? 'limitOpen' : 'limitClose'
  }
  if (orderDelta > 0n) {
    return 'limitOpen'
  }
  if (orderDirection === PositionSide.long) {
    return comparison === TriggerComparison.lte ? 'stopLoss' : 'takeProfit'
  } else {
    return comparison === TriggerComparison.gte ? 'stopLoss' : 'takeProfit'
  }
}

export const isOpenOrderValid = ({
  order,
  allOrders,
  userMarketSnapshot,
  marketSnapshot,
}: {
  order: FormattedOpenOrder
  allOrders: FormattedOpenOrder[]
  userMarketSnapshot?: UserMarketSnapshot
  marketSnapshot?: MarketSnapshot
}) => {
  if (!userMarketSnapshot || !marketSnapshot)
    return { isValid: true, limitOpens: 0, pendingOrderSize: 0n, hasOpenPosition: false }
  const snapshotPosition = isFailedClose(userMarketSnapshot)
    ? userMarketSnapshot.magnitude
    : userMarketSnapshot.nextMagnitude

  if (order.details.order_side === 3) {
    return { isValid: true, limitOpens: 0, pendingOrderSize: 0n, hasOpenPosition: false }
  }

  const currentSide = isFailedClose(userMarketSnapshot) ? userMarketSnapshot.side : userMarketSnapshot.nextSide

  if (currentSide !== PositionSide.none && order.side !== currentSide) {
    return { isValid: false, limitOpens: 0, pendingOrderSize: 0n, hasOpenPosition: snapshotPosition > 0n }
  }

  if (BigInt(order.details.order_delta) > 0n) {
    const { collateral } = userMarketSnapshot.local
    // Subsitute 1n for collateral if none present to bypass 0 collateral check in calcLeverage
    const {
      riskParameter: { margin, minMargin },
    } = marketSnapshot
    const reqMargin = Big6Math.max(
      minMargin,
      Big6Math.mul(calcNotional(BigInt(order.details.order_delta), BigInt(order.details.order_price)), margin),
    )

    return {
      isValid: collateral >= reqMargin,
      limitOpens: 0,
      pendingOrderSize: 0n,
      hasOpenPosition: snapshotPosition > 0n,
    }
  }

  let limitOpens = 0
  const totalPendingOrderSize = allOrders.reduce((acc, pendingOrder) => {
    if (
      order.details.nonce === pendingOrder.details.nonce ||
      pendingOrder.details.market !== order.details.market ||
      pendingOrder.details.order_side > 2 ||
      pendingOrder.details.order_side !== order.details.order_side ||
      BigInt(pendingOrder.details.order_delta) <= 0n
    ) {
      return acc
    }
    limitOpens += 1
    return acc + BigInt(pendingOrder.details.order_delta)
  }, 0n)

  return {
    isValid: snapshotPosition >= (totalPendingOrderSize + BigInt(order.details.order_delta)) * -1n,
    limitOpens,
    pendingOrderSize: totalPendingOrderSize,
    hasOpenPosition: !Big6Math.isZero(snapshotPosition),
  }
}

