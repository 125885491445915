import React, { useState } from "react";
import { USDC_TOKEN, TOKENS_SYMBOLS } from "../utils/constants";
import { arbVaultContract, tcapVaultContract, perpeVaultContract, ethVaultContract } from "../utils/contracts";
import { ContractType, TokenType, ProductType } from "../utils/types";
import { IProductContext } from "../states";

const getCurrentVaultContract = (tokenSymbol: string): ContractType => {
  if (tokenSymbol === TOKENS_SYMBOLS.tcap) {
    return tcapVaultContract;
  } else if (tokenSymbol === TOKENS_SYMBOLS.perpe) {
    return perpeVaultContract;
  } else if (tokenSymbol === TOKENS_SYMBOLS.ether) { 
    return ethVaultContract;
  }

  return arbVaultContract;
}

export const useProductContext = (currentMarket: ProductType): IProductContext => {
  const [market, setMarket] = useState(currentMarket.market);
  const [collateral, setCollateral] = useState(USDC_TOKEN);
  const [productContract, setProductContract] = useState(currentMarket.longContract);
  const [vaultContract, setVaultContract] = useState(
    getCurrentVaultContract(currentMarket.market.key)
  );
  const [type, setType] = useState("long");

  const setCurrentMarket = React.useCallback((currentMarket: TokenType): void => {
    setMarket(currentMarket);
  }, []);
  const setCurrentCollateral = React.useCallback((currentCollateral: TokenType): void => {
    setCollateral(currentCollateral);
  }, []);
  const setCurrentProductContract = React.useCallback((currentProductContract: ContractType): void => {
    setProductContract(currentProductContract);
  }, []);
  const setCurrentVaultContract = React.useCallback((currentVaultContract: ContractType): void => {
    setVaultContract(currentVaultContract);
  }, []);
  const setCurrentType = React.useCallback((currentType: string): void => {
    setType(currentType);
  }, []);

  const isLonging = (): boolean => {
    return type === "long";
  }
  
  return {
    market,
    setCurrentMarket,
    collateral,
    setCurrentCollateral,
    productContract,
    setCurrentProductContract,
    vaultContract,
    setCurrentVaultContract,
    type,
    setCurrentType,
    isLonging,
  };
};
