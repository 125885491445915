import { useCallback } from 'react'
import { Big6Math, PositionSide } from '@perennial/sdk'

import { isNumbersOnly, isNumbersOnlyWithNegative } from '../../../../../utils/formUtils'

import { FormNames, OrderTypes } from '../../../constants'


interface OnChangeHandlersProps { 
  latestPrice: bigint
  setValue: (
    valueName: string,
    value: string
  ) => void
}

export const useTriggerOnChangeHandlers = ({ latestPrice, setValue }: OnChangeHandlersProps) => {
  const onChangeStopLoss = useCallback(
    (newStopLoss: string) => {
      if (!isNumbersOnly(newStopLoss)) return
      const validatedStopLoss = Big6Math.max6Decimals(newStopLoss)
      setValue(FormNames.stopLoss, validatedStopLoss)
    },
    [setValue],
  )

  const onChangeTakeProfit = useCallback(
    (newTakeProfit: string) => {
      if (!isNumbersOnly(newTakeProfit)) return
      const validatedTakeProfit = Big6Math.max6Decimals(newTakeProfit)
      setValue(FormNames.takeProfit, validatedTakeProfit)
    },
    [setValue],
  )
  
  const onChangeLimitPrice = useCallback(
    (newLimitPrice: string) => {
      if (!isNumbersOnly(newLimitPrice)) return
      const validatedLimitPrice = Big6Math.max6Decimals(newLimitPrice)
      setValue(FormNames.limitPrice, validatedLimitPrice)

      const limitBigInt = Big6Math.fromFloatString(validatedLimitPrice)
      const difference = limitBigInt - latestPrice
      const percentChangeBigInt = Big6Math.div(Big6Math.mul(difference, Big6Math.BASE * 100n), latestPrice)
      const percentChangeString = Big6Math.toFloatString(percentChangeBigInt)
      setValue(FormNames.limitPricePercent, percentChangeString)
    },
    [setValue, latestPrice],
  )
  
  const onChangeLimitPricePercent = useCallback(
    (newLimitPricePercent: string) => {
      if (!isNumbersOnlyWithNegative(newLimitPricePercent)) return

      if (newLimitPricePercent.trim() === "" || newLimitPricePercent.trim() === "-") {
        setValue(FormNames.limitPricePercent, newLimitPricePercent)
        setValue(FormNames.limitPrice, Big6Math.toFloatString(latestPrice))
        return
      } 

      const validatedLimitPricePercent = Big6Math.max6Decimals(newLimitPricePercent)
      setValue(FormNames.limitPricePercent, validatedLimitPricePercent)

      const percentIncrease = Big6Math.toUnsafeFloat(latestPrice) * parseFloat(validatedLimitPricePercent) / 100 
      const newLimit = Big6Math.toUnsafeFloat(latestPrice) + percentIncrease
      const newLimitString = newLimit.toFixed(6)

      setValue(FormNames.limitPrice, newLimitString) 
    },
    [
      setValue,
      latestPrice,      
    ],
  )

  const onChangeTriggerAmount = useCallback(
    (newTriggerAmount: string) => {
      if (!isNumbersOnly(newTriggerAmount)) return
      const validatedTriggerAmount = Big6Math.max6Decimals(newTriggerAmount)
      setValue(FormNames.triggerAmount, validatedTriggerAmount)
    },
    [setValue],
  )

  return {
    onChangeStopLoss,
    onChangeTakeProfit,
    onChangeLimitPrice,
    onChangeLimitPricePercent,
    onChangeTriggerAmount,
  }
}

export const calcTriggerOrderPrice = ({
  positionSize,
  orderDirection,
  orderType,
  percent,
  latestPrice,
  collateral,
}: {
  positionSize: bigint
  orderDirection: PositionSide.long | PositionSide.short
  orderType: OrderTypes
  percent: string
  latestPrice: bigint
  collateral: bigint
}): { triggerPrice: bigint; valueChange: bigint } => {
  const formattedPercent = (BigInt(percent) * Big6Math.BASE) / 100n

  const valueChange = Big6Math.mul(formattedPercent, collateral)
  const priceChangePerUnit = Big6Math.abs(Big6Math.div(valueChange, positionSize))

  let triggerPrice: bigint
  if (orderDirection === PositionSide.long) {
    if (orderType === OrderTypes.stopLoss || orderType === OrderTypes.limit) {
      triggerPrice = latestPrice - priceChangePerUnit
    } else {
      triggerPrice = latestPrice + priceChangePerUnit
    }
  } else {
    if (orderType === OrderTypes.stopLoss || orderType === OrderTypes.limit) {
      triggerPrice = latestPrice + priceChangePerUnit
    } else {
      triggerPrice = latestPrice - priceChangePerUnit
    }
  }

  return { triggerPrice, valueChange }
}
