import React, { useMemo }  from "react";
import { Button, Form, InputGroup } from "react-bootstrap/esm";
import { formatBig6USDPrice } from '@perennial/sdk'
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";
import { NumericFormat } from "react-number-format";
import { useBalances } from '../../../../hooks/wallet'
import { TokenIcon } from "../../../common";
import { isNumber } from "../../../../utils/utils";
import { LifiWidgetModal } from "../../../lifi";


type props = {
  control_id: string;
  showBalance: boolean;
  value: string;
  title: string;
  onChange: (event: string) => void;
  onSetMaxAmount: () => void;
  maxCaption: string;
  vaultWithdrawal?: boolean;
  deposited?: bigint;
  showIcon?: boolean;
  tokenSymbol?: string;
  showUSDValue?: boolean;
}

export const CollateralInput = ({
  control_id,
  showBalance,
  value,
  title,
  onChange,
  onSetMaxAmount,
  maxCaption,
  vaultWithdrawal = false,
  deposited = 0n,
  showIcon = true,
  tokenSymbol = "USDC",
  showUSDValue = true,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { data: balances } = useBalances()

  const { maxValue } = useMemo(() => {
    if (vaultWithdrawal) {
      return {
        maxValue: formatBig6USDPrice(deposited)
      };
    }
    return {
      maxValue: formatBig6USDPrice(balances?.usdc, { fromUsdc: true }) ?? "$0"
    };
  },
    [vaultWithdrawal, balances, deposited]
  );

  const { valueUSD, isValueZero } = useMemo(() => {
    let valueUSD = "0";
    let isValueZero = false;
    if(isNumber(value)) {
      isValueZero = parseFloat(value) === 0;
      valueUSD = value;
    }
    return {
      valueUSD,
      isValueZero,
    };
  },
    [value]
  );

  return (
    <Form.Group className="asset-input-group">
      <div className="asset-input-top">
        <h6>{title}</h6>
        {showUSDValue && (
          <NumericFormat
            className="balance-usd"
            value={valueUSD}
            displayType="text"
            thousandSeparator=","
            decimalScale={2}
            suffix=" USD"
          />
        )}        
      </div>
      <InputGroup className="input-collateral">
        <Form.Control
          id={control_id}
          placeholder="0"
          className="balance-input"
          value={!isValueZero ? value : ""}
          type={isMobile ? "number" : "text"}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
          }
        />
        <div className="asset-info">
          <div className="asset-info2">
            {showIcon && (
              <TokenIcon name="usdc" size="small" />
            )}
            <LifiWidgetModal />
          </div>
          {showBalance && (
            <div className="asset-balance">
              <span className="title">{maxCaption}:</span>
              <span className="balance">{maxValue}</span>
              <Button
                variant="secondary"
                className="small"
                onClick={() => onSetMaxAmount()}
              >
                {t("max")}
              </Button>
            </div>
          )}          
        </div>
      </InputGroup>
    </Form.Group>
  );       
};
