import {
  Big6Math,
  BigOrZero,
  ChainMarkets,
  SupportedAsset,
  calcNotional,
  chainAssetsWithAddress,
  magnitude,
  timeToSeconds,
} from '@perennial/sdk'
import { MarketsAccountCheckpointsQuery } from '@perennial/sdk/dist/types/gql/graphql'

import { useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import { Address, getAddress } from 'viem'
import { gql } from '@apollo/client'

import { calcSTIPFeeRebate } from '../../utils/positionUtils'

import { useAddress, useChainId, useGraphClient } from '../network'
import { useMarketSnapshots2 } from './chain'
import { GraphDefaultPageSize, queryAll } from '../../utils/graphUtils'
import { STIPDropParams, STIPSeasonNumber } from '../../constants/stipDrop'
import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'


export const useActivePositionsMarketPnls = () => {
  const chainId = useChainId()
  const { data: marketSnapshots, isLoading: marketSnapshotsLoading } = useMarketSnapshots2()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  const queryResults = useQueries({
    queries: chainAssetsWithAddress(chainId).map(({ marketAddress, asset }) => ({
      queryKey: [
        'marketPnls2',
        chainId,
        asset,
        address,
        Number(marketSnapshots?.market[asset]?.pre.latestOracleVersion.timestamp),
      ],
      enabled:
        !!address && !!marketSnapshots?.market[asset] && !!marketSnapshots?.user?.[asset] && !marketSnapshotsLoading,
      queryFn: async () => {
        if (!address || !marketSnapshots || !marketSnapshots.user) return
        const marketSnapshot = marketSnapshots.market[asset]
        const userMarketSnapshot = marketSnapshots.user[asset]
        if (!marketSnapshot || !userMarketSnapshot) return

        return sdk.markets.read.activePositionPnl({
          marketSnapshot,
          userMarketSnapshot,
          address,
          includeClosedWithCollateral: true,
        })
      },
    })),
  })

  const pnlData = queryResults.reduce((acc, queryResult) => {
    if (!queryResult.data) return acc
    const { asset } = queryResult.data
    return { ...acc, [asset]: queryResult.data }
  }, {} as Record<SupportedAsset, (typeof queryResults)[number]['data']>)

  return {
    data: pnlData,
    isFetching: queryResults.some((r) => r.isFetching),
    isLoading: queryResults.some((r) => r.isLoading),
  }
}

export type ActiveSubPositionHistory = NonNullable<
  NonNullable<Awaited<ReturnType<typeof useActiveSubPositionHistory>['data']>>['pages'][number]
>['changes']

const ActivePositionHistoryPageSize = 100
export const useActiveSubPositionHistory = (asset: SupportedAsset, enabled: boolean = true) => {
  const chainId = useChainId()
  const { data: marketSnapshots, isLoading: marketSnapshotsLoading } = useMarketSnapshots2()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  return useInfiniteQuery({
    queryKey: ['activeSubPositionHistory', chainId, asset, address],
    initialPageParam: 0,
    enabled: !!address && !marketSnapshotsLoading && !!marketSnapshots?.user?.[asset] && enabled,
    queryFn: async ({ pageParam = 0 }) => {
      if (!address || !marketSnapshots?.user?.[asset]) return
      const market = marketSnapshots.user[asset].market
      return sdk.markets.read.activePositionHistory({
        market,
        address,
        pageParam,
        pageSize: ActivePositionHistoryPageSize,
      })
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? 0,
  })
}


const HistoricalPositionsPageSize = 10
export type HistoricalPosition = NonNullable<
  NonNullable<ReturnType<typeof useHistoricalPositions>['data']>['pages'][number]
>['positions'][number]
export const useHistoricalPositions = (maker: boolean) => {
  const chainId = useChainId()
  const markets = chainAssetsWithAddress(chainId)
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  // @ts-ignore
  return useInfiniteQuery({
    queryKey: ['historicalPositions', chainId, maker ? 'maker' : 'taker', address],
    enabled: !!address && !!markets.length,
    initialPageParam: 0,
    queryFn: async ({ pageParam }: { pageParam?: { page: number; checkpoints?: MarketsAccountCheckpointsQuery } }) => {
      if (!address || !markets.length) return
      const res = sdk.markets.read.historicalPositions({
        address,
        markets,
        pageParam,
        pageSize: HistoricalPositionsPageSize,
        maker,
      })
      return res
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageParam,
  })
}


const HistoricalSubPositionsPageSize = 100
export const useHistoricalSubPositions = ({
  market,
  startVersion,
  endVersion,
  enabled,
}: {
  market: Address
  startVersion: string
  endVersion: string
  enabled?: boolean
}) => {
  const chainId = useChainId()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  return useInfiniteQuery({
    queryKey: ['historicalSubPositions', chainId, market, startVersion, endVersion, address],
    enabled: !!address && enabled,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0 }) => {
      if (!address) return
      const { changes, hasMore } = await sdk.markets.read.subPositions({
        address,
        market: market,
        startVersion: BigInt(startVersion),
        endVersion: BigInt(endVersion),
        first: HistoricalSubPositionsPageSize,
        skip: pageParam * HistoricalSubPositionsPageSize,
      })

      return { changes, nextPageParam: hasMore ? pageParam + 1 : undefined }
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageParam,
  })
}


const OpenOrdersPageSize = 1000
export const useOpenOrders = (isMaker?: boolean) => {
  const chainId = useChainId()
  const { address } = useAddress()
  const markets = chainAssetsWithAddress(chainId)
  const sdk = usePerennialSDKContext()

  return useInfiniteQuery({
    queryKey: ['openOrders', chainId, address, isMaker ? 'maker' : 'taker'],
    enabled: !!address && !!markets.length,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0 }) => {
      if (!address || !markets.length) return

      return sdk.markets.read.openOrders({
        address,
        markets,
        pageParam,
        pageSize: OpenOrdersPageSize,
        isMaker,
      })
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageParam,
  })
}

export const useMarket24hrData = (asset: SupportedAsset) => {
  const chainId = useChainId()
  const market = ChainMarkets[chainId][asset]
  const sdk = usePerennialSDKContext()

  return useQuery({
    queryKey: ['market24hData', chainId, asset],
    enabled: !!market,
    queryFn: async () => {
      if (!market) return
      return sdk.markets.read.market24hrData({ market })
    },
  })
}

export const useMarket7dData = (asset: SupportedAsset) => {
  const chainId = useChainId()
  const market = ChainMarkets[chainId][asset]
  const sdk = usePerennialSDKContext()

  return useQuery({
    queryKey: ['market7dData', chainId, asset],
    enabled: !!market,
    queryFn: async () => {
      if (!market) return
      return sdk.markets.read.market7dData({ market })
    },
  })
}

export const useAccountARBSeasonData = (season: STIPSeasonNumber) => {
  const chainId = useChainId()
  const graphClient = useGraphClient()
  const { address } = useAddress()

  return useQuery({
    queryKey: ['accountARBSeasonData', chainId, address, season],
    enabled: !!address,
    queryFn: async () => {
      if (!address) return

      const markets = chainAssetsWithAddress(chainId)
      const { from, to, fromBlock } = STIPDropParams[season]
      const query = gql(`
        query AccountARBSeasonData($account: Bytes!, $from: BigInt!, $to: BigInt!, $fromBlock: Int!, $first: Int!, $skip: Int!) {
          start: marketAccountPositions(
            where: { account: $account }
            block: { number: $fromBlock }
          ) {
            pendingLong, pendingShort, market
          }

          updates: updateds(
            where: { account: $account, blockTimestamp_gte: $from, blockTimestamp_lt: $to }
            first: $first, skip: $skip, orderBy: blockTimestamp, orderDirection: asc
          ) {
            newLong, newShort, market, price, latestPrice, positionFee, blockNumber, version
          }

          riskParameterUpdateds(orderBy: blockNumber, orderDirection: desc) {
            market, newRiskParameter_takerFee, blockNumber
          }
        }
      `)

      const { start, updates, riskParameterUpdateds } = await queryAll(async (pageNumber: number) =>
        graphClient.request(query, {
          account: address,
          from: timeToSeconds(from).toString(),
          to: timeToSeconds(to).toString(),
          fromBlock: fromBlock,
          first: GraphDefaultPageSize,
          skip: pageNumber * GraphDefaultPageSize,
        }) as any,
      )

      const currentPositions = markets.reduce((acc, { marketAddress }) => {
        const pos = start.find((p: any) => getAddress(p.market) === marketAddress)
        return { ...acc, [marketAddress]: Big6Math.max(BigOrZero(pos?.pendingLong), BigOrZero(pos?.pendingShort)) }
      }, {} as Record<Address, bigint>)

      return updates.reduce(
        (acc: { volume: bigint, fees: bigint }, update: any) => {
          const updateMarket = getAddress(update.market)

          // Delta is the absolute value of the difference between the current position and the new position
          const delta = Big6Math.abs(currentPositions[updateMarket] - magnitude(0n, update.newLong, update.newShort))
          const feeNotional = calcNotional(delta, BigInt(update.latestPrice))
          const volNotional = calcNotional(
            delta,
            BigInt(update.price) === 0n ? BigInt(update.latestPrice) : BigInt(update.price),
          )

          // Find risk parameter for this market that is earlier or on the same block as the update
          const riskParameter = riskParameterUpdateds.find(
            (rp: any) => getAddress(rp.market) === updateMarket && rp.blockNumber <= update.blockNumber,
          )

          // Rebate is min(notional * takerFee, positionFeePaid) * rebatePct
          const feeRebate = calcSTIPFeeRebate({
            takerNotional: feeNotional,
            takerFeeBps: BigOrZero(riskParameter?.newRiskParameter_takerFee),
            positionFee: BigInt(update.positionFee),
            season,
          })
          currentPositions[updateMarket] = magnitude(0n, update.newLong, update.newShort)

          return {
            volume: acc.volume + volNotional,
            fees: acc.fees + feeRebate,
          }
        },
        { volume: 0n, fees: 0n },
      )
    },
  })
}

export const getPriceAtVersion = async ({
  graphClient,
  market,
  version,
}: {
  graphClient: GraphQLClient
  market: Address
  version: bigint
}) => {
  const query = gql(`
    query PriceAtVersion($versionId: ID!) {
      marketVersionPrice(id: $versionId) { price }
    }
  `)

  const res = await graphClient.request(query, {
    versionId: `${market}:${version.toString()}`.toLowerCase(),
  })

  // @ts-ignore
  return res.marketVersionPrice?.price ?? 0n
}
