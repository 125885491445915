import { Address, getAddress, zeroAddress } from "viem";
import {
  arbitrum,
  arbitrumSepolia,
  base,
} from "viem/chains";

import { SupportedChainId } from "./network";

type AddressMapping = { [chain in SupportedChainId]: Address };

export const SeasonalMerkleTreeAddresses: AddressMapping = {
  [arbitrum.id]: getAddress('0x4Fc1cC62E5d0b12f5a9e3213B4177d2edb301F30'),
  [arbitrumSepolia.id]: zeroAddress, // TODO: add address
  [base.id]: zeroAddress,
}


export const ChainalysisContractAddress = '0x40c57923924b5c5c5455c48d93317139addac8fb'
