import React from "react"
import { useParams } from "react-router-dom"
import { MetaHeadEmbed } from "@phntms/react-share"

import Exchange from "../components/Exchange";
import { PriceChartWrapper, TradingViewWidget } from "../components/charts"
import { AcceptReferral } from "../../components/referrals"
import { Positions } from "../components/positions"
import { PriceBox } from "../components/common"
import TabsChart from "../../components/TabsChart"
import {
  useActiveProvider,
  useUserProductSnapshots,
} from "../hooks";
import { OpenPositionType } from "../utils/types";


const Markets = () => {
  const [tabState, setTabState] = React.useState("market");
  const { userAccount } = useActiveProvider();
  const { data: userSnapshots, isLoading } = useUserProductSnapshots(userAccount);
  const queryParams = useParams();
  const referrerUuid = queryParams.uuid;
 
  return(
    <>
      <MetaHeadEmbed
        render={(meta: React.ReactNode) => <head>{meta}</head>}
        siteTitle="Cryptex V2"
        pageTitle="Trade"
        titleTemplate="[pageTitle] | [siteTitle]"
        description="Decentralized platform providing cutting edge perpetuals."
        baseSiteUrl="https://cryptex.finance"
        pagePath=""
        keywords={["Cryptex Finance", "Cryptex V2", "Perpetuals"]}
        imageUrl="https://bit.ly/3wiUOuk"
        imageAlt="Cryptex logo."
        twitter={{
          cardSize: "large",
          siteUsername: "@CryptexFinance",
          imageUrl: "https://bit.ly/3wiUOuk",
          imageAlt: "Cryptex V2"
        }}
      />
      {referrerUuid && <AcceptReferral referrerUuid={referrerUuid} />}
      <TabsChart onTabChange={setTabState} />
      <div className="trade-module">
        <PriceBox showMarketCap={true} />
        <div className="trade-top">
          <div className="trade-left">
            {tabState === "market" ?  <TradingViewWidget/> : <PriceChartWrapper />}
          </div>
          <div className="trade-right markets-v1">
            <Exchange isTaker={true} />
          </div>
        </div>
      </div>
      <div className="trade-bottom">
        <Positions
          userSnapshots={userSnapshots}
          isLoadingPositions={isLoading}
          positionType={OpenPositionType.Taker}
        />
      </div>
    </>
  );
};

export default Markets;
