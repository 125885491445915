import React, { useContext, useMemo }  from "react"
import { Button, Form, InputGroup } from "react-bootstrap/esm"
import { useMediaQuery } from "react-responsive"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"
import { useTokensBalance, useUSDCOraclePrice } from "../hooks"
import { isNonNegativeNumber, isNumber } from "../utils/utils"
import { productContext } from "../states"
import { TokenIcon } from "../../components/common"
import { LifiWidgetModal } from "../../components/lifi"


type props = {
  control_id: string;
  showBalance: boolean;
  value: string;
  title: string;
  onChange: (event: string) => {};
  onSetMaxAmount: () => void;
  maxCaption: string;
  vaultWithdrawal?: boolean;
  deposited?: bigint;
  showIcon?: boolean;
  tokenSymbol?: string;
  showUSDValue?: boolean;
}

export const CollateralInput = ({
  control_id,
  showBalance,
  value,
  title,
  onChange,
  onSetMaxAmount,
  maxCaption,
  vaultWithdrawal = false,
  deposited = 0n,
  showIcon = true,
  tokenSymbol = "USDC",
  showUSDValue = true,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const currentMarket = useContext(productContext)
  const { data: balances } = useTokensBalance()
  const { data: usdcPrice } = useUSDCOraclePrice()

  const maxValue = useMemo(() => {
    if (vaultWithdrawal) {
      return parseFloat(ethers.formatEther(deposited)).toFixed(4);
    }

    let collateralBalance = 0;
    if (balances) {
      collateralBalance = parseFloat(ethers.formatUnits(balances.usdcBalance, "mwei"));
    }

    return collateralBalance.toFixed(4);
  },
    [vaultWithdrawal, balances, deposited]
  );

  const { valueUSD, isValueZero } = useMemo(() => {
    let valueUSD = "0";
    let isValueZero = false;
    if (usdcPrice && isNonNegativeNumber(value)) {
      const p = parseFloat(ethers.formatUnits(usdcPrice, 8));
      valueUSD = (p * parseFloat(value)).toFixed(4);
    }
    if(isNumber(value)) {
      isValueZero = parseFloat(value) === 0;
    }
    return {
      valueUSD,
      isValueZero,
    };
  },
    [usdcPrice, value]
  );

  return (
    <Form.Group className="asset-input-group">
      <div className="asset-input-top">
        <h6>{title}</h6>
        {showUSDValue && (
          <NumericFormat
            className="balance-usd"
            value={valueUSD}
            displayType="text"
            thousandSeparator=","
            decimalScale={2}
            suffix=" USD"
          />
        )}        
      </div>
      <InputGroup className="input-collateral">
        <Form.Control
          id={control_id}
          placeholder="0"
          className="balance-input"
          value={!isValueZero ? value : ""}
          type={isMobile ? "number" : "text"}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
          }
        />
        <div className="asset-info">
          <div className="asset-info2">
            {showIcon && (
              <TokenIcon name={currentMarket.collateral.symbol} size="small" />
            )}
            <LifiWidgetModal />
          </div>
          {showBalance && (
            <div className="asset-balance">
              <span className="title">{maxCaption}:</span>
              <NumericFormat
                className="balance"
                value={maxValue}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
              />
              <Button
                variant="secondary"
                className="small"
                onClick={() =>  onSetMaxAmount()}
              >
                {t("max")}
              </Button>
            </div>
          )}          
        </div>
      </InputGroup>
    </Form.Group>
  );       
};
