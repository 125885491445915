import React from 'react'
import { Address, getAddress } from 'viem'
import {
  Big6Math,
  BigOrZero,
  formatBig6,
  calcNotional,
  MarketSnapshots,
  PerennialVaultType,
  sum as sumBI,
  SupportedAsset,
  VaultSnapshots,
} from '@perennial/sdk'

import { NumericFormat } from 'react-number-format'


export type STIPSeasonNumber = keyof typeof STIPDropParams

const noOpEstimate = (_: MarketSnapshots, __?: bigint) => 0n

export const STIPDropParams = {
  // 2023-11-06T12:00:00Z - 2023-11-13T12:00:00Z
  1: {
    from: new Date(1699272000 * 1000),
    to: new Date(1699876800 * 1000),
    fromBlock: 147687900,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: "23000",
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: "23000",
          suffix: " ARB",
          detail: []
        }
      },
      leaderboardPnlRewardBreakdown: undefined,
      leaderboardVolumeRewardBreakdown: undefined,
      totalOIRewards: Big6Math.fromFloatString('23500'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      totalFeeRewards: Big6Math.fromFloatString('23500'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
        return sumBI([
          notionalDelta ?? 0n,
          ...Object.values(snapshots.market).map(
            (s) =>
              calcNotional(s.nextPosition.long, s.latestOracleVersion.price) +
              calcNotional(s.nextPosition.short, s.latestOracleVersion.price),
          ),
        ])
      },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = ((this.eth?.totalOIRewards ?? 0n) + (this.btc?.totalOIRewards ?? 0n)) / 2n
        const solMaticMaker = ((this.sol?.totalOIRewards ?? 0n) + (this.matic?.totalOIRewards ?? 0n)) / 2n
        const otherMaker = (this.tia?.totalOIRewards ?? 0n) / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + solMaticMaker + otherMaker),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (60%)",
            },
            {
              title: "SOL/MATIC:",
              value: formatBig6(solMaticMaker),
              suffix: " ARB (30%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (10%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        return sumBI([
          ((this.eth?.totalOIRewards ?? 0n) + (this.btc?.totalOIRewards ?? 0n)) / 2n,
          ((this.sol?.totalOIRewards ?? 0n) + (this.matic?.totalOIRewards ?? 0n)) / 2n,
          (this.tia?.totalOIRewards ?? 0n) / 2n,
        ])
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('2343.8'), // Split between ETH and BTC
      },
      [SupportedAsset.btc]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('2343.8'), // Split between ETH and BTC
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.sol
              ? calcNotional(snapshots.market.sol.nextPosition.maker, snapshots.market.sol.global.latestPrice)
              : 0n,
            snapshots.market.matic
              ? calcNotional(snapshots.market.matic.nextPosition.maker, snapshots.market.matic.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('1171.9'), // Split between SOL and MATIC
      },
      [SupportedAsset.matic]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.sol
              ? calcNotional(snapshots.market.sol.nextPosition.maker, snapshots.market.sol.global.latestPrice)
              : 0n,
            snapshots.market.matic
              ? calcNotional(snapshots.market.matic.nextPosition.maker, snapshots.market.matic.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('1171.9'), // Split between SOL and MATIC
      },
      [SupportedAsset.tia]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.tia
              ? calcNotional(snapshots.market.tia.nextPosition.maker, snapshots.market.tia.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('390.6'),
      },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: formatBig6(this.alpha.totalOIRewards + this.bravo.totalOIRewards),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "Blue Chip Vault:",
              value: formatBig6(this.alpha.totalOIRewards),
              suffix: " ARB (75%)",
            },
            {
              title: "Large Cap Vault:",
              value: formatBig6(this.bravo.totalOIRewards),
              suffix: " ARB (25%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards, this.bravo.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshot: VaultSnapshots, notionalDelta?: bigint) => {
          return BigOrZero(snapshot.vault.alpha?.totalAssets) + (notionalDelta ?? 0n)
        },
        totalOIRewards: Big6Math.fromFloatString('8789'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshot: VaultSnapshots, notionalDelta?: bigint) => {
          return BigOrZero(snapshot.vault.bravo?.totalAssets) + (notionalDelta ?? 0n)
        },
        totalOIRewards: Big6Math.fromFloatString('2929.7'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: "https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-1-rewards-final-8sQqr856tgKseXA1NFEhdNPOJxpLhV.json",
    sybils: [
      '0x235cf972e3c6d1df059b645f4b0ed20be4326c61',
      '0x43938b4f7ee6b85b6f38eec06addfa85404093ba',
      '0x385ffed8031891044f87bdd10a308080417181fe',
      '0xf36ca34a56c57464a7d58d268d96b23d07f408e0',
      '0x23c518823e80e2cca8ad16e8e9cb92e5d578c594',
      '0x4e661bb7022c2f1703621bb5965c3c924eb5842c',
      '0xcecfd0d30cb5362af3c7378401ad9437c4c15815',
      '0xd6707b918ae2d1b802e6aa8b05e2d04a3ed2f53f',
      '0x56cce05526af66f69bddaa54a60dc9c9ca5411ad',
      '0x647220b5e407cfb58f0db4170410914ba87ee17f',
      '0x98289029b0d4bb73ead9eb1a3300ed724fcf6779',
      '0xb0db0a18ba8309b66aa54ea5bb55f4f457ca3ea8',
      '0x3c7d0889937dbbd80a674bab7124be2712cb6ec0',
      '0x7455898a8f461ceb5510c3f264b7290499501b5b',
      '0x04001c5359ec93b3368af1c8f0d5510506ece41b',
      '0x9b4c436f40e7fbae7bad8b7547a2e8652c71b6c5',
      '0x42e3da8e7c1dbff7f9fe544fff5c4531ecd6f8a4',
      '0xa73ce2d80a75773ea90367015b60a0a3624818d7',
      '0x28b539234c12cb8df634c4311cf2abceb662f6d3',
      '0xbeb439195367d87184733badb1f4f26a7df9c576',
    ].map((s) => getAddress(s)) as Address[],
  },
  // 2023-11-13T12:00:00Z - 2023-11-20T12:00:00Z
  2: {
    from: new Date(1699876800 * 1000),
    to: new Date(1700481600 * 1000),
    fromBlock: 149992899,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      feeRebatePct: Big6Math.fromFloatString('1'),
      totalFeeRewards: Big6Math.fromFloatString('18750'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      get totalOIRewards() {
        const ethBtcTaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherTaker =
          sumBI([
            this.sol?.totalOIRewards ?? 0n,
            this.matic?.totalOIRewards ?? 0n,
            this.tia?.totalOIRewards ?? 0n,
          ]) / 4n
        return ethBtcTaker + otherTaker
      },
      get rewardBreakdown() {
        const ethBtcTaker = this.totalOIRewards / 2n
        const otherTaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(ethBtcTaker + otherTaker),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcTaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherTaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get feeRewardBreakdown() {
        const ethBtcTakerFees = this.totalFeeRewards / 2n
        const otherTakerFees = this.totalFeeRewards / 2n

        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(ethBtcTakerFees + otherTakerFees),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcTakerFees),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherTakerFees),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      leaderboardPnlRewardBreakdown: undefined,
      leaderboardVolumeRewardBreakdown: undefined,
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(
                  snapshots.market.eth.nextPosition.long + snapshots.market.eth.nextPosition.short,
                  snapshots.market.eth.global.latestPrice,
                )
              : 0n,
            snapshots.market.btc
              ? calcNotional(
                  snapshots.market.btc.nextPosition.long + snapshots.market.btc.nextPosition.short,
                  snapshots.market.btc.global.latestPrice,
                )
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('9375'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) =>
                ss ? calcNotional(ss.nextPosition.long + ss.nextPosition.short, ss.global.latestPrice) : 0n,
              ),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('9375'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker =
          sumBI([
            this.sol?.totalOIRewards ?? 0n,
            this.matic?.totalOIRewards ?? 0n,
            this.tia?.totalOIRewards ?? 0n,
          ]) / 4n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3125'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => (ss ? calcNotional(ss.nextPosition.maker, ss.global.latestPrice) : 0n)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3125'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: formatBig6(this.alpha.totalOIRewards + this.bravo.totalOIRewards),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "Blue Chip Vault:",
              value: formatBig6(this.alpha.totalOIRewards),
              suffix: " ARB (50%)",
            },
            {
              title: "Large Cap Vault:",
              value: formatBig6(this.bravo.totalOIRewards),
              suffix: " ARB (50%)"
            }
          ]
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards, this.bravo.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshot: VaultSnapshots, notionalDelta?: bigint) => {
          return BigOrZero(snapshot.vault.alpha?.totalAssets) + (notionalDelta ?? 0n)
        },
        totalOIRewards: Big6Math.fromFloatString('9375'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshot: VaultSnapshots, notionalDelta?: bigint) => {
          return BigOrZero(snapshot.vault.bravo?.totalAssets) + (notionalDelta ?? 0n)
        },
        totalOIRewards: Big6Math.fromFloatString('9375'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: "https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-2-rewards-final-su6NwBPNVThBrFdJYUNUruZKrtzVMU.json",
    sybils: [] as Address[],
  },
  // 2023-11-20T12:00:00Z - 2023-12-04T12:00:00Z
  3: {
    from: new Date(1700481600 * 1000),
    to: new Date(1701691200 * 1000),
    fromBlock: 152277893, // TODO: estimate, update after start
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      feeRebatePct: Big6Math.fromFloatString('1'),
      totalFeeRewards: Big6Math.fromFloatString('32000'),
      totalPnlRewards: Big6Math.fromFloatString('20000'),
      totalVolumeRewards: Big6Math.fromFloatString('20000'),
      get totalOIRewards() {
        const ethBtcTaker = this.eth?.totalOIRewards ?? 0n
        const otherTaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcTaker + otherTaker
      },
      get leaderboardPnlTooltip() {
        return (
          <div className="total-value-group">
            <div className="value-item total">
              <div className="value-item-desc">
                <h6>Season Top PNL:</h6>
              </div>
              <span className="number bold">
                <NumericFormat
                  className="number bold"
                  value={formatBig6(this.totalPnlRewards)}
                  displayType="text"
                  thousandSeparator=","
                  suffix=" ARB"
                  decimalScale={4}
                />
              </span>
            </div>
          </div>
        )
      },
      get leaderboardVolumeTooltip() {
        return (
          <div className="total-value-group">
            <div className="value-item total">
              <div className="value-item-desc">
                <h6>Season Top Volume:</h6>
              </div>
              <span className="number bold">
                <NumericFormat
                  className="number bold"
                  value={formatBig6(this.totalVolumeRewards)}
                  displayType="text"
                  thousandSeparator=","
                  suffix=" ARB"
                  decimalScale={4}
                />
              </span>
            </div>
          </div>
        )
      },
      get rewardBreakdown() {
        const ethBtcTaker = this.totalOIRewards / 2n
        const otherTaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(ethBtcTaker + otherTaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcTaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherTaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get feeRewardBreakdown() {
        const ethBtcTakerFees = this.totalFeeRewards / 2n
        const otherTakerFees = this.totalFeeRewards / 2n

        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(ethBtcTakerFees + otherTakerFees),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcTakerFees),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherTakerFees),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return {
          title: "Season Top Volume:",
          value: formatBig6(this.totalPnlRewards),
          suffix: " ARB",
          detail: []
        }
      }, 
      get leaderboardVolumeRewardBreakdown() {
        return {
          title: "Season Top PNL:",
          value: formatBig6(this.totalVolumeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(
                  snapshots.market.eth.nextPosition.long + snapshots.market.eth.nextPosition.short,
                  snapshots.market.eth.global.latestPrice,
                )
              : 0n,
            snapshots.market.btc
              ? calcNotional(
                  snapshots.market.btc.nextPosition.long + snapshots.market.btc.nextPosition.short,
                  snapshots.market.btc.global.latestPrice,
                )
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5300'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) =>
                ss ? calcNotional(ss.nextPosition.long + ss.nextPosition.short, ss.global.latestPrice) : 0n,
              ),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5300'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5312.5'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => (ss ? calcNotional(ss.nextPosition.maker, ss.global.latestPrice) : 0n)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5312.5'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: formatBig6(this.alpha.totalOIRewards + this.bravo.totalOIRewards),
          suffix: " ARB (50%)",
          detail: [
            {
              title: "Blue Chip Vault:",
              value: formatBig6(this.alpha.totalOIRewards),
              suffix: " ARB (50%)",
            },
            {
              title: "Large Cap Vault:",
              value: formatBig6(this.bravo.totalOIRewards),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards, this.bravo.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshot: VaultSnapshots, notionalDelta?: bigint) => {
          return BigOrZero(snapshot.vault.alpha?.totalAssets) + (notionalDelta ?? 0n)
        },
        totalOIRewards: Big6Math.fromFloatString('15937.5'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshot: VaultSnapshots, notionalDelta?: bigint) => {
          return BigOrZero(snapshot.vault.bravo?.totalAssets) + (notionalDelta ?? 0n)
        },
        totalOIRewards: Big6Math.fromFloatString('15937.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: "https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-3-rewards-final-Tk37Y1k3XvuMnleSlk2B7xtqCeP2nz.json",
    sybils: [] as Address[],
  },
  // 2023-12-04T12:00:00Z - 2023-12-18T12:00:00Z
  4: {
    from: new Date(1701691200 * 1000),
    to: new Date(1702900800 * 1000),
    fromBlock: 156788707,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('72000'),
      totalPnlRewards: Big6Math.fromFloatString('7000'),
      totalVolumeRewards: Big6Math.fromFloatString('7000'),
      get totalOIRewards() {
        return 0n
      },
      rewardBreakdown: undefined,
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return {
          title: "Season Top Volume:",
          value: formatBig6(this.totalPnlRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardVolumeRewardBreakdown() {
        return {
          title: "Season Top PNL:",
          value: formatBig6(this.totalVolumeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(
                  snapshots.market.eth.nextPosition.long + snapshots.market.eth.nextPosition.short,
                  snapshots.market.eth.global.latestPrice,
                )
              : 0n,
            snapshots.market.btc
              ? calcNotional(
                  snapshots.market.btc.nextPosition.long + snapshots.market.btc.nextPosition.short,
                  snapshots.market.btc.global.latestPrice,
                )
              : 0n,
          ])
        },
        totalOIRewards: 0n,
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) =>
                ss ? calcNotional(ss.nextPosition.long + ss.nextPosition.short, ss.global.latestPrice) : 0n,
              ),
          ])
        },
        totalOIRewards: 0n,
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('4856.25'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => (ss ? calcNotional(ss.nextPosition.maker, ss.global.latestPrice) : 0n)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('4856.25'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "29000",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('29137.5'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('29137.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: 'https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-4-rewards-final-MldopMRSGk1hxOqqqW179UgnXkdCLT.json',
    sybils: [] as Address[],
  },
  // 2023-12-18T12:00:00Z - 2024-01-01T12:00:00Z
  5: {
    from: new Date(1702900800 * 1000),
    to: new Date(1704110400 * 1000),
    fromBlock: 161308661,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('7200'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('65000'),
      totalPnlRewards: Big6Math.fromFloatString('7000'),
      totalVolumeRewards: Big6Math.fromFloatString('7000'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return {
          title: "Season Top Volume:",
          value: formatBig6(this.totalPnlRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardVolumeRewardBreakdown() {
        return {
          title: "Season Top PNL:",
          value: formatBig6(this.totalVolumeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.xrp
              ? calcNotional(
                  snapshots.market.xrp.nextPosition.long + snapshots.market.xrp.nextPosition.short,
                  snapshots.market.xrp.global.latestPrice,
                )
              : 0n,
            // TODO: additional markets
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3066.375'),
      },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.arb
              ? calcNotional(
                  snapshots.market.arb.nextPosition.long + snapshots.market.arb.nextPosition.short,
                  snapshots.market.arb.global.latestPrice,
                )
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3066.375'),
      },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('4856.25'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => (ss ? calcNotional(ss.nextPosition.maker, ss.global.latestPrice) : 0n)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('4856.25'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "29000",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('29137.5'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('29137.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: 'https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-5-rewards-final-iZZFvJwzPvZRtCUmT5ys69iTnVnweh.json',
    sybils: [] as Address[],
  },
  // 2024-01-01T12:00:00Z - 2024-01-15T12:00:00Z
  6: {
    from: new Date(1704110400 * 1000),
    to: new Date(1705320000 * 1000),
    fromBlock: 165961170,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('10000'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('57950'),
      totalPnlRewards: Big6Math.fromFloatString('7000'),
      totalVolumeRewards: Big6Math.fromFloatString('7000'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return {
          title: "Season Top Volume:",
          value: formatBig6(this.totalPnlRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardVolumeRewardBreakdown() {
        return {
          title: "Season Top PNL:",
          value: formatBig6(this.totalVolumeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('10500'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => (ss ? calcNotional(ss.nextPosition.maker, ss.global.latestPrice) : 0n)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('10500'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "62000",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: 'https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-6-rewards-final-cuwKsvvDDKxtyfLY7bU5XT3jhlCJ1f.json',
    sybils: [] as Address[],
  },
  // 2024-01-1512:00:00Z - 2024-01-29T12:00:00Z
  7: {
    from: new Date(1705320000 * 1000),
    to: new Date(1706529600 * 1000),
    fromBlock: 170689061,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('0'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('34000'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return undefined
      },
      get leaderboardVolumeRewardBreakdown() {
        return undefined
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5200'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => calcNotional(ss.nextPosition.maker, ss.latestOracleVersion.price)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5200'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "31000",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('31000'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: 'https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-7-rewards-final-6P1YyEVz0sZdpw1YwgH5QKBGv4hl9B.json',
    sybils: [] as Address[],
  },
  // 2024-01-2912:00:00Z - 2024-02-12T12:00:00Z
  8: {
    from: new Date(1706529600 * 1000),
    to: new Date(1707739200 * 1000),
    fromBlock: 170522453,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('2000'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('34000'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return undefined
      },
      get leaderboardVolumeRewardBreakdown() {
        return undefined
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5000'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => calcNotional(ss.nextPosition.maker, ss.latestOracleVersion.price)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('5000'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "31000",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('31000'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: "https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-8-rewards-final-mzoF8O7JDP17hUzii7yB2ZxM9ScrAW.json",
    sybils: [] as Address[],
  },
  // 2024-02-12T12:00:00Z - 2024-02-26T12:00:00Z
  9: {
    from: new Date(1707739200 * 1000),
    to: new Date(1708948800 * 1000),
    fromBlock: 179816694,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('0'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('22500'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return undefined
      },
      get leaderboardVolumeRewardBreakdown() {
        return undefined
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3437.5'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => calcNotional(ss.nextPosition.maker, ss.latestOracleVersion.price)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3437.5'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "20625",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('20625'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: "https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-9-rewards-final-VLArJ8nxDAi07bldBI2ZxZBKW5Bv5d.json",
    sybils: [] as Address[],
  },
  // 2024-02-12T12:00:00Z - 2024-03-11T12:00:00Z
  10: {
    from: new Date(1708948800 * 1000),
    to: new Date(1710158400 * 1000),
    fromBlock: 184485537,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('0'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('22500'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return undefined
      },
      get leaderboardVolumeRewardBreakdown() {
        return undefined
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3437.5'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => calcNotional(ss.nextPosition.maker, ss.latestOracleVersion.price)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3437.5'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "20625",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('20625'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: "https://yrj8qjpysmcjwwy4.public.blob.vercel-storage.com/season-10-rewards-final-9zR0zFRwaorppD8MMSFthXJ2rWGLwb.json",
    sybils: [] as Address[],
  },
  // 2024-02-12T12:00:00Z - 2024-03-11T12:00:00Z
  11: {
    from: new Date(1710158400 * 1000),
    to: new Date(1711368000 * 1000),
    fromBlock: 189135161,
    get over() {
      return Date.now() > this.to.getTime()
    },
    taker: {
      totalOIRewards: Big6Math.fromFloatString('0'),
      feeRebatePct: Big6Math.fromFloatString('1'),
      feeMultiplier: Big6Math.fromFloatString('2'),
      totalFeeRewards: Big6Math.fromFloatString('22500'),
      totalPnlRewards: Big6Math.fromFloatString('0'),
      totalVolumeRewards: Big6Math.fromFloatString('0'),
      get rewardBreakdown() {
        return {
          title: "earn-rewards.tw-open-interest",
          value: formatBig6(this.totalOIRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get feeRewardBreakdown() {
        return {
          title: "earn-rewards.fee-rebates",
          value: formatBig6(this.totalFeeRewards),
          suffix: " ARB",
          detail: []
        }
      },
      get leaderboardPnlRewardBreakdown() {
        return undefined
      },
      get leaderboardVolumeRewardBreakdown() {
        return undefined
      },
      get feeTooltip() {
        return null
      },
      [SupportedAsset.xrp]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.btc]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.eth]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.sol]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.matic]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.tia]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.link]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.bnb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
      [SupportedAsset.arb]: { estimatedOI: noOpEstimate, totalOIRewards: 0n },
    },
    maker: {
      get rewardBreakdown() {
        const ethBtcMaker = this.totalOIRewards / 2n
        const otherMaker = this.totalOIRewards / 2n

        return {
          title: "earn-rewards.individual-makers",
          value: formatBig6(ethBtcMaker + otherMaker),
          suffix: " ARB",
          detail: [
            {
              title: "ETH/BTC:",
              value: formatBig6(ethBtcMaker),
              suffix: " ARB (50%)",
            },
            {
              title: "earn-rewards.other",
              value: formatBig6(otherMaker),
              suffix: " ARB (50%)",
            }
          ]
        }
      },
      get totalOIRewards() {
        const ethBtcMaker = sumBI([this.eth?.totalOIRewards ?? 0n, this.btc?.totalOIRewards ?? 0n]) / 2n
        const otherMaker = this.sol?.totalOIRewards ?? 0n
        return ethBtcMaker + otherMaker
      },
      [SupportedAsset.eth]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            snapshots.market.eth
              ? calcNotional(snapshots.market.eth.nextPosition.maker, snapshots.market.eth.global.latestPrice)
              : 0n,
            snapshots.market.btc
              ? calcNotional(snapshots.market.btc.nextPosition.maker, snapshots.market.btc.global.latestPrice)
              : 0n,
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3437.5'), // Split between ETH and BTC
      },
      get [SupportedAsset.btc]() {
        return this.eth
      },
      [SupportedAsset.sol]: {
        estimatedOI: (snapshots: MarketSnapshots, notionalDelta?: bigint) => {
          return sumBI([
            notionalDelta ?? 0n,
            ...Object.entries(snapshots.market)
              .filter(([asset]) => asset !== SupportedAsset.eth && asset !== SupportedAsset.btc)
              .map(([, ss]) => calcNotional(ss.nextPosition.maker, ss.latestOracleVersion.price)),
          ])
        },
        totalOIRewards: Big6Math.fromFloatString('3437.5'), // Split between OTHER
      },
      get [SupportedAsset.matic]() {
        return this.sol
      },
      get [SupportedAsset.tia]() {
        return this.sol
      },
      get [SupportedAsset.link]() {
        return this.sol
      },
      get [SupportedAsset.bnb]() {
        return this.sol
      },
      get [SupportedAsset.arb]() {
        return this.sol
      },
      get [SupportedAsset.xrp]() {
        return this.sol
      },
    },
    vault: {
      get rewardBreakdown() {
        return {
          title: "earn-rewards.vault-lp",
          value: "20625",
          suffix: " ARB",
          detail: []
        }
      },
      get totalOIRewards() {
        return sumBI([this.alpha.totalOIRewards])
      },
      [PerennialVaultType.alpha]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('20625'),
      },
      [PerennialVaultType.bravo]: {
        estimatedOI: (snapshots: VaultSnapshots, notionalDelta?: bigint) => {
          return (
            BigOrZero(snapshots.vault.bravo?.totalAssets) +
            BigOrZero(snapshots.vault.alpha?.totalAssets) +
            (notionalDelta ?? 0n)
          )
        },
        totalOIRewards: Big6Math.fromFloatString('62287.5'),
      },
    },
    get totalRewards() {
      return sumBI([
        this.taker.totalOIRewards,
        this.taker.totalFeeRewards,
        this.taker.totalPnlRewards,
        this.taker.totalVolumeRewards,
        this.maker.totalOIRewards,
        this.vault.totalOIRewards,
      ])
    },
    blobUrl: undefined,
    sybils: [] as Address[],
  },
} as const

export const CurrentSTIPSeason = (() => {
  if (typeof window !== 'undefined' && window.location) {
    const urlParams = new URLSearchParams(window.location.search)
    const seasonFromParam = urlParams.get('season')
    if (seasonFromParam && Number(seasonFromParam)) return Number(seasonFromParam) as STIPSeasonNumber
  }
  return Number(
    Object.entries(STIPDropParams)
      .find(([, v]) => !v.over)
      ?.at(0) ?? 2,
  ) as STIPSeasonNumber
})()

export const FinishedSTIPSeasons = Object.entries(STIPDropParams)
  .filter(([, v]) => v.over)
  .map(([k]) => Number(k) as STIPSeasonNumber)
export const AllSTIPSeasons = Object.keys(STIPDropParams).map((k) => Number(k) as STIPSeasonNumber)
