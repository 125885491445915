import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { formatBig6, formatBig18, SupportedAsset } from "@perennial/sdk"
import { useMediaQuery } from "react-responsive"
import { STIPDropParams, STIPSeasonNumber } from "../../../constants/stipDrop"
import { Button, Image, Stack } from "react-bootstrap"
import { NumericFormat } from "react-number-format"
import { AssetMetadata } from "../../../constants/markets"


export const RewardsCard = ({
  title,
  description,
  stats,
  rewards,
  iconText,
  rewardBreakdown,
  season,
}: {
  title: string
  description: string
  stats: { label: string; value: string }[]
  rewards: bigint
  iconText: string
  rewardBreakdown: { label: string; value: bigint }[]
  season: STIPSeasonNumber
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)'
  })
  const seasonEnd = STIPDropParams[season].to
  const isPending = seasonEnd.getTime() > Date.now()
  const isTaker = title.toLowerCase() === "taker"

  return(
    <div className="rewards-card">
      <Stack className="left" direction="vertical">
        <div className="reward-box">
          <h6 className="">{description}</h6>
        </div>
        <Stack className="reward-box" direction="vertical" gap={1}>
          <span className="text-purple">{t("earn-rewards.your-stats")}</span>
          <Stack direction={!isMobile ? "horizontal" : "vertical"} gap={!isMobile ? 3 : 1}>
            {stats.map((stat, index) => (
              <div key={index.toString()} className="reward-stat-box">
                <NumericFormat 
                  className="number"
                  displayType="text"
                  value={stat.value}
                  thousandSeparator=","
                  decimalScale={4}
                  prefix="$"
                />
                <span className="text-muted">{stat.label}</span>
              </div>
            ))}
          </Stack>   
        </Stack>
        <Stack className="reward-box" direction="vertical" gap={1}>
          <span className="text-purple">{t("earn-rewards.your-rewards")}</span>
          <Stack direction={!isMobile ? "horizontal" : "vertical"} gap={!isMobile ? 3 : 1}>
            {rewardBreakdown.map((stat, index: number) => (
              <div key={index.toString()} className="reward-stat-box">
                <div className="row-inner">
                  <NumericFormat 
                    className="number text-green"
                    displayType="text"
                    value={!isPending ?formatBig18(stat.value) : "-"}
                    thousandSeparator=","
                    decimalScale={4}
                    prefix="$"
                  />
                  {!isPending && <Image src={AssetMetadata[SupportedAsset.arb].icon} width={18} height={18} />}
                </div>  
                <span>{stat.label}</span>
              </div>
            ))}
            <div className="reward-stat-box">
              <div className="row-inner">
                {!isPending ? (
                  <NumericFormat
                    className="number text-green total"
                    displayType="text"
                    value={formatBig18(rewards)}
                    thousandSeparator=","
                    decimalScale={4}
                    prefix="$"
                  />
                ) : (
                  <span className="number text-green">{t("pending")}</span>
                )}
                {!isPending && <Image src={AssetMetadata[SupportedAsset.arb].icon} width={20} height={20} />}
              </div>  
              <span>{t("earn-rewards.total-reward")}</span>
            </div>
          </Stack>  
        </Stack>
      </Stack>
      <Stack className="reward-box right bordered right" direction="vertical">
        {isTaker ? (
          <Stack direction="vertical">
            <TotalsGroups totalGroup={STIPDropParams[season].taker.rewardBreakdown} />
            <TotalsGroups totalGroup={STIPDropParams[season].taker.feeRewardBreakdown} />
            <TotalsGroups totalGroup={STIPDropParams[season].taker.leaderboardPnlRewardBreakdown} />
            <TotalsGroups totalGroup={STIPDropParams[season].taker.leaderboardVolumeRewardBreakdown} />
          </Stack>
        ) : (
          <Stack direction="vertical">
            <TotalsGroups totalGroup={STIPDropParams[season].vault.rewardBreakdown} />
            <TotalsGroups totalGroup={STIPDropParams[season].maker.rewardBreakdown} />
          </Stack>
        )}
        <div className="total-rewards-box">
          <h5>
            {title === "taker" ? t("earn-rewards.total-taker-rewards") : t("earn-rewards.total-maker-rewards")}
          </h5>
          <div className="total-rewards-inner">
            <NumericFormat
              className="number bold"
              displayType="text"
              value={formatBig6(
                isTaker
                  ? STIPDropParams[season].taker.totalFeeRewards +
                    STIPDropParams[season].taker.totalOIRewards + 
                    STIPDropParams[season].taker.totalPnlRewards +
                    STIPDropParams[season].taker.totalVolumeRewards
                  : STIPDropParams[season].maker.totalOIRewards + STIPDropParams[season].vault.totalOIRewards 
              )}
              thousandSeparator=","
              decimalScale={0}
            />
            <Image  src={AssetMetadata[SupportedAsset.arb].icon} width={20} height={20} />
          </div>  
        </div>
      </Stack>
    </div>
  )    
}

type tgType = {
  title: string,
  value: string,
  suffix: string,
  detail: Array <{ title: string, value: string, suffix: string }>,
}

const TotalsGroups = ({ totalGroup } : { totalGroup: tgType | undefined } ) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const isTableOrMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)'
  })
 
  if (!totalGroup) {
    return <></>
  }

  const formatTitle = (title: string) => {
    if (isTableOrMobile && title.toLowerCase().includes("time-weighted")) {
      return "Time-weighted OI"
    }
    if (isMobile && title.toLowerCase().includes("advanced")) {
      return t("individual")
    }
    return t(title)
  }

  return (
    <div className="total-value-group">
      <div className="value-item total">
        <div className="value-item-desc">
          <h6>
            {formatTitle(totalGroup.title)}
          </h6>
          {totalGroup.detail.length > 0 && (
            <>
              <Button
                variant="secondary small underline"
                onClick={() => setShow(!show)}
              >
                {show ? `(${t("hide")})` : `(${t("detail")})`}
              </Button> 
              <h6>:</h6>
            </>  
          )}  
        </div>
        <span className="number bold">
          <NumericFormat
            className="number bold"
            value={totalGroup.value}
            displayType="text"
            thousandSeparator=","
            suffix={totalGroup.suffix}
            decimalScale={0}
          />
        </span>
      </div>
      <div className={"total-value-group-detail ".concat(show ? "show" : "hide")}>
        {totalGroup.detail.map((item, index: number) => (
          <div key={index.toString()} className="value-item">
            <h6>{item.title.includes("earn-rewards") ? t(item.title) : item.title}</h6>
            <NumericFormat
              className="number bold"
              value={item.value}
              displayType="text"
              thousandSeparator=","
              suffix={item.suffix}
              decimalScale={0}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
