import React, { useState } from "react"
import { Button, Spinner } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"

import { useActiveProvider, useOpenPosition } from "../../hooks"
import { errorNotification, notifyUser } from "../../../components/common/notifications"
import { ERROR_USER_REJECTED_TRAN } from "../../utils/constants"
import { getMarketByProduct, isSupportedV1Chain } from "../../utils/utils"
import { saveTransactionToDB } from "../../db/transactions"
import { InvokerAction } from "../../helpers"
import { PriceUpdateWarning } from "../common"

type props = {
  isTaker: boolean;
  productAddress: string;
  collateralAmount: number;
  collateralBalance: number;
  position: bigint;
  exposure: number;
  writeEnable: boolean;
  onSuccess: () => void;
}

export const OpenPositionButton = ({
  isTaker,
  productAddress,
  collateralAmount,
  collateralBalance,
  position,
  exposure,
  writeEnable,
  onSuccess
}: props) => {
  const { t } = useTranslation()
  const defaultText = isTaker ? t("open-position") : t("provide")
  const { chainId, currentChainId, userAccount: ownerAddress } = useActiveProvider();
  const [isWriting, setIsWriting] = useState(false);

  const onTransactionSettled = (isSuccess: boolean, error: any, tranHash: string) => {
    if (isSuccess) {
      const market = getMarketByProduct(chainId, productAddress);
      saveTransactionToDB(
        chainId,
        tranHash,
        ownerAddress,
        market.key,
        productAddress,
        collateralAmount,
        parseFloat(ethers.formatEther(position)),
        exposure,
        isTaker ? InvokerAction.OPEN_TAKE : InvokerAction.OPEN_MAKE,
      );
      notifyUser(t("notification.position-opened-2"));
      onSuccess();
    } else {
      if (error.name !== ERROR_USER_REJECTED_TRAN) {
        errorNotification(t("error.transaction"));
      }
    }
    setIsWriting(false);
  };

  const { onOpenPosition } = useOpenPosition(chainId, ownerAddress, isTaker, onTransactionSettled)

  const handleClick = () => {
    if (writeEnable && collateralAmount <= collateralBalance && ownerAddress !== ethers.ZeroAddress) {
      setIsWriting(true);
      onOpenPosition(productAddress, collateralAmount, position)
    }
  };

  return (
    <>
      <PriceUpdateWarning />
      <Button
        variant="primary"
        className="btn-position"
        disabled={isWriting || !writeEnable || collateralAmount > collateralBalance || !isSupportedV1Chain(currentChainId)} 
        onClick={handleClick}
      >
        <div className="btn-spinner">
          {isWriting && <Spinner animation="border" variant="secondary" className="small" />}
          {isWriting ? t("opening") : defaultText}
        </div>
      </Button>
    </>
  )
}