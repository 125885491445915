import { useEffect, useState } from 'react'
import { HermesClient, PriceUpdate } from '@perennial/sdk'
import EventEmitter from 'events'
import { GraphQLClient } from 'graphql-request'
import { PublicClient, createPublicClient, webSocket } from 'viem'
// eslint-disable-next-line no-restricted-imports
import { useAccount, useAccount as useWagmiAccount } from 'wagmi'

import {
  PythMainnetUrl,
  PythTestnetUrl,
  SupportedChainId,
  isSupportedChain,
  isTestnet,
  DefaultChain,
  GraphUrls,
  rpcUrls
} from '../constants/network'


export const useAddress = () => {
  const { address: wagmiAddress } = useWagmiAccount()

  const [addressInfo, setAddressInfo] = useState<{ address: `0x${string}` | undefined; overriding: boolean }>({
    address: undefined,
    overriding: false,
  })

  useEffect(() => {
    setAddressInfo({ address: wagmiAddress, overriding: false })
  }, [wagmiAddress])

  return addressInfo
}


export const useChainId = () => {
  let { chain } = useAccount();
  chain = chain ?? DefaultChain

  if (chain === undefined || !isSupportedChain(chain.id)) return DefaultChain.id

  return chain.id as SupportedChainId
}

export const useOnSupportedChain = () => {
  let { chain } = useAccount();
  if (chain) {
    return { isUsingSupportedChain: isSupportedChain(chain.id) }
  }
  return { isUsingSupportedChain: true }
}

const viemWsClients = new Map<SupportedChainId, PublicClient>()
// We need to create a WS public client directly instead of using Wagmi's hooks because the wagmi hook
// returns a Fallback provider which does not support eth_subscribe
export const useViemWsClient = () => {
  const chainId = useChainId()
  const providerUrl = useRPCProviderUrl()

  if (!viemWsClients.has(chainId)) {
    viemWsClients.set(chainId, createPublicClient({ transport: webSocket(providerUrl.replace('https://', 'wss://')) }))
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return viemWsClients.get(chainId)!
}

export const useRPCProviderUrl = (): string => {
  const chainId = useChainId()
  return rpcUrls[chainId]
}

const graphClients = new Map<SupportedChainId, GraphQLClient>()
export const useGraphClient = () => {
  const chainId = useChainId()

  if (!graphClients.has(chainId)) graphClients.set(chainId, new GraphQLClient(GraphUrls[chainId]))

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return graphClients.get(chainId)!
}

const pythClients = {
  mainnet: new HermesClient(PythMainnetUrl, { timeout: 30000 }),
  testnet: new HermesClient(PythTestnetUrl, { timeout: 30000 }),
}

export const usePyth = () => {
  const chainId = useChainId()
  return isTestnet(chainId) ? pythClients.testnet : pythClients.mainnet
}

const pythSubscriptions = new Map<string, EventEmitter>()
export const usePythSubscription = (feedIds: string[]) => {
  const pyth = usePyth()
  const key = feedIds.sort().join(',')
  if (!pythSubscriptions.has(key)) {
    const emitter = new EventEmitter()
    pythSubscriptions.set(key, emitter)

    const stream = pyth.getPriceUpdatesStream(feedIds, { parsed: true })
    stream.then((eventSource) => {
      eventSource.onmessage = ({ data }: { data: string }) => {
        emitter.emit('updates', JSON.parse(data) as PriceUpdate)
      }
      eventSource.onerror = () => {
        eventSource.close()
        pythSubscriptions.delete(key)
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return pythSubscriptions.get(key)!
}
