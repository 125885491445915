import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"

import TradeWrapper from "../Trade"
import { Provide } from "./components"
import PLInterfaceToggle from "../common/PLInterfaceToggle"
import { CustomTooltip } from "../common"


const ProvideLiquidity = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const [currentInterface, setCurrentInterface] = useState(0)

  const VersionChooserToggle = () => (
    <div className="version-chooser">
      <CustomTooltip id="tl-pl-interface" msg={t("tooltip.individual")}>
        <PLInterfaceToggle currentInterface={currentInterface} setCurrentInterface={setCurrentInterface} />
      </CustomTooltip>  
    </div>
  );

  return (
    <div className={"liquidity ".concat(currentInterface === 1 ? "pro" : "")}>
      {currentInterface === 0 ? (
        <div className="liquidity-card">  
          <div className="liquidity-card-header">
            {!isMobile && VersionChooserToggle()}  
            <h3>{t("provide-liquidity")}</h3>
            <h6>{t("info-msg.provide")}</h6>
            {isMobile && VersionChooserToggle()}
          </div>
          <div className="liquidity-card-body">
            <Provide />  
          </div>
        </div>
      ) : (
        <TradeWrapper isProInterface={true} currentInterface={currentInterface} setCurrentInterface={setCurrentInterface} />
      )}
    </div>
  )
}

export default ProvideLiquidity
